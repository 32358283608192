<template>
    <!-- Modal Views - Start -->
    <el-dialog v-model="licenseExpiredVisible" title="License Expired" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width="30%">
        <span>Your license has expired. Please contact IOSEA support team.</span>
        <template #footer>
        <span class="dialog-footer">
            <el-button type="primary" @click="onLicenseExpired()">Ok</el-button
            >
        </span>
        </template>
    </el-dialog>

    <el-drawer v-model="displayOptionsDrawer" title="Display Options" direction="rtl" size="35%">
        <div class="row">
            <div class="row justify-content-md-left col-md-12">
                <div class="form-group col-md-4 d-flex justify-content-left" style="font-size: small">
                    PM Plots
                </div>
                <div class="form-group col-md-8 d-flex justify-content-left">
                    <el-switch v-model="showPlots" size="small" active-text="Show" inactive-text="Hide" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="row justify-content-md-left col-md-12">
                <div class="form-group col-md-4 d-flex justify-content-left" style="font-size: small">
                    PM Time Series
                </div>
                <div class="form-group col-md-8 d-flex justify-content-left">
                    <el-switch v-model="showPMTimeSeries" size="small" active-text="Show" inactive-text="Hide" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="row justify-content-md-left col-md-12">
                <div class="form-group col-md-4 d-flex justify-content-left" style="font-size: small">
                    RTLS
                </div>
                <div class="form-group col-md-8 d-flex justify-content-left">
                    <el-switch v-model="showRTLS" size="small" active-text="Show" inactive-text="Hide" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="row justify-content-md-left col-md-12">
                <div class="form-group col-md-4 d-flex justify-content-left" style="font-size: small">
                    KALMAN
                </div>
                <div class="form-group col-md-8 d-flex justify-content-left">
                    <el-switch v-model="showKALMAN" size="small" active-text="Show" inactive-text="Hide" />
                </div>
            </div>
        </div>
    </el-drawer>
  <!-- Modal Views - End -->

    <div style="min-height: 90vh;">
        <el-row style="border-bottom: 2px solid #ccc;" align="top">
            <el-col :span="1">
                <el-tooltip content="Network Details" placement="right" effect="light">
                    <el-button type="primary" :disabled='isStarted' style="margin: 10px;" @click="toggleDebugging" :icon="ArrowLeftBold" circle></el-button>
                </el-tooltip>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="21" class="centered-col">
                Networks &emsp;>&emsp;<b>{{ network.name }}</b>&emsp;>&emsp;
                <el-select v-model="selectedLocator" class="m-2" placeholder="Select Locator" filterable style="width: 200px">
                    <el-option
                        v-for="locator in locators"
                        :key="locator.name"
                        :label="locator.name"
                        :value="locator.name"
                    />
                </el-select>
                <div v-show="selectedLocator">&emsp;>&emsp;</div>
                <el-select v-show="selectedLocator" v-model="selectedTag" class="m-2" placeholder="Select Tag" filterable style="width: 200px">
                    <el-option
                        v-for="tag in tags"
                        :key="tag.name"
                        :label="tag.name"
                        :value="tag.name"
                    />
                </el-select>
                <el-tooltip content="Start" placement="bottom" effect="light">
                    <el-button v-show="selectedTag" type="primary" :disabled='isStarted' style="margin: 10px;" @click="onStart" :icon="CaretRight" circle></el-button>
                </el-tooltip>
                <el-tooltip content="Stop" placement="bottom" effect="light">
                    <el-button v-show="selectedTag" :disabled='!isStarted' type="primary" style="margin: 10px;" @click="onStop" :icon="CloseBold" circle></el-button>
                </el-tooltip>
            </el-col>
            <el-col :span="1">
                <el-tooltip content="Display Options" placement="left" effect="light">
                    <el-button type="primary" style="margin: 10px;" @click="onOpenDisplayOptions" :icon="Operation" circle></el-button>
                </el-tooltip>
            </el-col>
        </el-row>
        <el-row align="top">
            <el-col :span="6">
                <div v-show="showPlots" ref="plot0"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showPlots" ref="plot1"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showPlots" ref="plot2"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showPlots" ref="plot3"></div>
            </el-col>
        </el-row>
        <el-row align="top">
            <el-col :span="6">
                <el-row v-if="plot0Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>theta: </b>{{ plot0Data.theta }} &emsp; <b>phi: </b>{{ plot0Data.phi }} &emsp; <b>rssi: </b>{{ plot0Data.rssi }} &emsp; <b>fom: </b>{{ plot0Data.fom }} &emsp; <b>freq: </b>{{ plot0Data.freq }} &emsp;
                    </div>
                </el-row>
                <el-row v-if="plot0Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>thetalocal: </b>{{ plot0Data.thetalocal }} &emsp; <b>philocal: </b>{{ plot0Data.philocal }} &emsp; <b>passed: </b>{{ plot0Data.passed }} &emsp; <span :class="{'gray-circle': (plot0Data.status === 0), 'green-circle': (plot0Data.status === 1), 'red-circle': (plot0Data.status === 2)}">&emsp;</span>
                    </div>
                </el-row>
            </el-col>
            <el-col :span="6">
                <el-row v-if="plot1Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>theta: </b>{{ plot1Data.theta }} &emsp; <b>phi: </b>{{ plot1Data.phi }} &emsp; <b>rssi: </b>{{ plot1Data.rssi }} &emsp; <b>fom: </b>{{ plot1Data.fom }} &emsp; <b>freq: </b>{{ plot1Data.freq }} &emsp; 
                    </div>
                </el-row>
                <el-row v-if="plot1Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>thetalocal: </b>{{ plot1Data.thetalocal }} &emsp; <b>philocal: </b>{{ plot1Data.philocal }} &emsp; <b>passed: </b>{{ plot1Data.passed }} &emsp; <span :class="{'gray-circle': (plot1Data.status === 0), 'green-circle': (plot1Data.status === 1), 'red-circle': (plot1Data.status === 2)}">&emsp;</span>
                    </div>
                </el-row>
            </el-col>
            <el-col :span="6">
                <el-row v-if="plot2Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>theta: </b>{{ plot2Data.theta }} &emsp; <b>phi: </b>{{ plot2Data.phi }} &emsp; <b>rssi: </b>{{ plot2Data.rssi }} &emsp; <b>fom: </b>{{ plot2Data.fom }} &emsp; <b>freq: </b>{{ plot2Data.freq }} &emsp; 
                    </div>
                </el-row>
                <el-row v-if="plot2Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>thetalocal: </b>{{ plot2Data.thetalocal }} &emsp; <b>philocal: </b>{{ plot2Data.philocal }} &emsp; <b>passed: </b>{{ plot2Data.passed }} &emsp; <span :class="{'gray-circle': (plot2Data.status === 0), 'green-circle': (plot2Data.status === 1), 'red-circle': (plot2Data.status === 2)}">&emsp;</span>
                    </div>
                </el-row>
            </el-col>
            <el-col :span="6">
                <el-row v-if="plot3Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>theta: </b>{{ plot3Data.theta }} &emsp; <b>phi: </b>{{ plot3Data.phi }} &emsp; <b>rssi: </b>{{ plot3Data.rssi }} &emsp; <b>fom: </b>{{ plot3Data.fom }} &emsp; <b>freq: </b>{{ plot3Data.freq }} &emsp; 
                    </div>
                </el-row>
                <el-row v-if="plot3Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>thetalocal: </b>{{ plot3Data.thetalocal }} &emsp; <b>philocal: </b>{{ plot3Data.philocal }} &emsp; <b>passed: </b>{{ plot3Data.passed }}  &emsp; <span :class="{'gray-circle': (plot3Data.status === 0), 'green-circle': (plot3Data.status === 1), 'red-circle': (plot3Data.status === 2)}">&emsp;</span>
                    </div>
                </el-row>
            </el-col>
        </el-row>
        <el-row align="top">
            <el-col :span="6">
                <div v-show="showPlots" ref="plot4"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showPlots" ref="plot5"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showPlots" ref="plot6"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showPlots" ref="plot7"></div>
            </el-col>
        </el-row>
        <el-row align="top">
            <el-col :span="6">
                <el-row v-if="plot4Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>theta: </b>{{ plot4Data.theta }} &emsp; <b>phi: </b>{{ plot4Data.phi }} &emsp; <b>rssi: </b>{{ plot4Data.rssi }} &emsp; <b>fom: </b>{{ plot4Data.fom }} &emsp; <b>freq: </b>{{ plot4Data.freq }} &emsp; 
                    </div>
                </el-row>
                <el-row v-if="plot4Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>thetalocal: </b>{{ plot4Data.thetalocal }} &emsp; <b>philocal: </b>{{ plot4Data.philocal }} &emsp; <b>passed: </b>{{ plot4Data.passed }}  &emsp; <span :class="{'gray-circle': (plot4Data.status === 0), 'green-circle': (plot4Data.status === 1), 'red-circle': (plot4Data.status === 2)}">&emsp;</span>
                    </div>
                </el-row>
            </el-col>
            <el-col :span="6">
                <el-row v-if="plot5Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>theta: </b>{{ plot5Data.theta }} &emsp; <b>phi: </b>{{ plot5Data.phi }} &emsp; <b>rssi: </b>{{ plot5Data.rssi }} &emsp; <b>fom: </b>{{ plot5Data.fom }} &emsp; <b>freq: </b>{{ plot5Data.freq }} &emsp; 
                    </div>
                </el-row>
                <el-row v-if="plot5Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>thetalocal: </b>{{ plot5Data.thetalocal }} &emsp; <b>philocal: </b>{{ plot5Data.philocal }} &emsp; <b>passed: </b>{{ plot5Data.passed }}  &emsp; <span :class="{'gray-circle': (plot5Data.status === 0), 'green-circle': (plot5Data.status === 1), 'red-circle': (plot5Data.status === 2)}">&emsp;</span>
                    </div>
                </el-row>
            </el-col>
            <el-col :span="6">
                <el-row v-if="plot6Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>theta: </b>{{ plot6Data.theta }} &emsp; <b>phi: </b>{{ plot6Data.phi }} &emsp; <b>rssi: </b>{{ plot6Data.rssi }} &emsp; <b>fom: </b>{{ plot6Data.fom }} &emsp; <b>freq: </b>{{ plot6Data.freq }} &emsp; 
                    </div>
                </el-row>
                <el-row v-if="plot6Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>thetalocal: </b>{{ plot6Data.thetalocal }} &emsp; <b>philocal: </b>{{ plot6Data.philocal }} &emsp; <b>passed: </b>{{ plot6Data.passed }}  &emsp; <span :class="{'gray-circle': (plot6Data.status === 0), 'green-circle': (plot6Data.status === 1), 'red-circle': (plot6Data.status === 2)}">&emsp;</span>
                    </div>
                </el-row>
            </el-col>
            <el-col :span="6">
                <el-row v-if="plot7Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>theta: </b>{{ plot7Data.theta }} &emsp; <b>phi: </b>{{ plot7Data.phi }} &emsp; <b>rssi: </b>{{ plot7Data.rssi }} &emsp; <b>fom: </b>{{ plot7Data.fom }} &emsp; <b>freq: </b>{{ plot7Data.freq }} &emsp; 
                    </div>
                </el-row>
                <el-row v-if="plot7Data" align="top" class="centered-col">
                    <div style="font-size: 13px;">
                        <b>thetalocal: </b>{{ plot7Data.thetalocal }} &emsp; <b>philocal: </b>{{ plot7Data.philocal }} &emsp; <b>passed: </b>{{ plot7Data.passed }}  &emsp; <span :class="{'gray-circle': (plot7Data.status === 0), 'green-circle': (plot7Data.status === 1), 'red-circle': (plot7Data.status === 2)}">&emsp;</span>
                    </div>
                </el-row>
            </el-col>
        </el-row>
        <el-row align="top">
            <el-col :span="6">
                <div v-show="showPMTimeSeries" ref="ts1"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showPMTimeSeries" ref="ts2"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showPMTimeSeries" ref="ts3"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showPMTimeSeries" ref="ts4"></div>
            </el-col>
        </el-row>
        <el-row align="top">
            <el-col :span="6">
                <div v-show="showPMTimeSeries" ref="ts5"></div>
            </el-col>
            <el-col :span="6">
            </el-col>
            <el-col :span="6">
            </el-col>
            <el-col :span="6">
            </el-col>
        </el-row>
        <el-row align="top">
            <el-col :span="12">
                <div v-show="showRTLS" ref="sc1"></div>
            </el-col>
            <el-col :span="12">
                <div v-show="showRTLS" ref="sc2"></div>
            </el-col>
        </el-row>
        <el-row align="top">
            <el-col :span="12">
                <div v-show="showRTLS" ref="sc3"></div>
            </el-col>
            <el-col :span="12">
                <div v-show="showRTLS" ref="sc4"></div>
            </el-col>
        </el-row>
        <el-row align="top">
            <el-col :span="6">
                <div v-show="showKALMAN" ref="kal1"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showKALMAN" ref="kal2"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showKALMAN" ref="kal3"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showKALMAN" ref="kal4"></div>
            </el-col>
        </el-row>
        <el-row align="top">
            <el-col :span="6">
                <div v-show="showKALMAN" ref="kal5"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showKALMAN" ref="kal6"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showKALMAN" ref="kal7"></div>
            </el-col>
            <el-col :span="6">
                <div v-show="showKALMAN" ref="kal8"></div>
            </el-col>
        </el-row>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import getUser from "../composables/getUser";
import useLogout from "../composables/useLogout";
import Plotly from 'plotly.js-dist-min';
import Navbar from "../components/Navbar.vue";
import { ArrowLeftBold, Operation, CaretRight, CloseBold } from '@element-plus/icons-vue';
import axios from "axios";
import { ElNotification } from 'element-plus';

export default {
    props: {
        network: {
            type: Object, // Adjust the type according to your data structure
            required: true, // or false, depending on your use case
        },
        locators: {
            type: Array, // Adjust the type according to your data structure
            required: true, // or false, depending on your use case
        },
        tags: {
            type: Array, // Adjust the type according to your data structure
            required: true, // or false, depending on your use case
        },
        isFullScreen: {
            type: Boolean,
            required: false, // or true, depending on your use case
            default: false, // Provide a default value if needed
        },
    },
    emits: [
        'toggleDebugging',
        'update:isFullScreen'
    ],
    components: { Navbar },
    setup(props, { emit }) {
        const { user } = getUser();
        const { logout, logoutError } = useLogout();
        const protocol = ref(window.localStorage.getItem("protocol"));
        const wsprotocol = ref(window.localStorage.getItem("wsprotocol"));
        const ep = ref(window.localStorage.getItem("endpoint"));
        const clientstreamendpoint = ref(window.localStorage.getItem("clientstreamendpoint"));
        const port = ref(window.localStorage.getItem("port"));
        const wsport = ref(window.localStorage.getItem("wsport"));
        const companyId = ref(window.localStorage.getItem("companyId"));
        const key = ref(window.localStorage.getItem("key"));
        const headers = { 'Authorization': 'Bearer ' + key.value };
        const licenseExpiredVisible = ref(false);

        const plot0 = ref(null);
        const plot1 = ref(null);
        const plot2 = ref(null);
        const plot3 = ref(null);
        const plot4 = ref(null);
        const plot5 = ref(null);
        const plot6 = ref(null);
        const plot7 = ref(null);
        const ts1 = ref(null);
        const ts2 = ref(null);
        const ts3 = ref(null);
        const ts4 = ref(null);
        const ts5 = ref(null);
        const sc1 = ref(null);
        const sc2 = ref(null);
        const sc3 = ref(null);
        const sc4 = ref(null);
        const kal1 = ref(null);
        const kal2 = ref(null);
        const kal3 = ref(null);
        const kal4 = ref(null);
        const kal5 = ref(null);
        const kal6 = ref(null);
        const kal7 = ref(null);
        const kal8 = ref(null);
        const displayOptionsDrawer = ref(false);
        const showPlots = ref(true);
        const showPMTimeSeries = ref(true);
        const showRTLS = ref(true);
        const showKALMAN = ref(true);
        const selectedLocator = ref(null);
        const selectedTag = ref(null);
        const isStarted = ref(false);
        const isDataInitialzed = ref(false);
        let pmSocket = ref(null);
        let rtlsSocket = ref(null);
        let kalmanSocket = ref(null);

        const timestampData = ref([]);
        const rssiData = ref([]);
        const fomData = ref([]);
        const freqData = ref([]);
        const thetalocalData = ref([]);
        const philocalData = ref([]);

        const velocityData = ref([]);
        const accelerationData = ref([])
        const lastStationaryConfidenceData = ref([])
        const lastAccurateLocationXData = ref([])
        const lastAccurateLocationYData = ref([])
        const lastAccurateLocationZData = ref([])
        const lastAccurateLocationKalmanXData = ref([])
        const lastAccurateLocationKalmanYData = ref([])
        const lastAccurateLocationKalmanZData = ref([])
        const lastAccurateLocationDisplayXData = ref([])
        const lastAccurateLocationDisplayYData = ref([])
        const lastAccurateLocationDisplayZData = ref([])
        const counterHoldonLockingData = ref([])
        const counterHoldonUnlockingData = ref([])
        const statusData = ref([])

        const plot0Data = ref(null);
        const plot1Data = ref(null);
        const plot2Data = ref(null);
        const plot3Data = ref(null);
        const plot4Data = ref(null);
        const plot5Data = ref(null);
        const plot6Data = ref(null);
        const plot7Data = ref(null);

        if (process.env.VUE_APP_API_PORT) {
            port.value = process.env.VUE_APP_API_PORT
        }

        if (process.env.VUE_APP_WS_PORT) {
            wsport.value = process.env.VUE_APP_WS_PORT
        }

        var timeSeriesData = [
            {
                x: [],
                y: [],
                type: 'scatter'
            }
        ];

        var surfaceLayout = {
            margin: {
                l: 0,
                r: 0,
                t: 0,
                b: 0
            },
            scene: {
                camera: {
                    projection: {
                        type: 'orthographic'
                    },
                    eye: { x: 0, y: 0, z: Infinity }
                },
                xaxis: { title: 'phi' },
                yaxis: { title: 'theta' },
                zaxis: { title: '' }
            },
            autosize: false,
            width: 350,
            height: 350,
            yaxis: {
                ticktext: ['long label','Very long label','3','label'],
                tickvals: [1, 2, 3, 4],
                tickmode: 'array',
                automargin: true,
                titlefont: { size: 30 },
            }
        };

        var ts1Layout = {
            title: {
                text:'rssi',
                font: {
                    size: 20
                },
                xref: 'paper',
                x: 0.05,
            },
            autosize: false,
            width: 315,
            height: 315
        };

        var ts2Layout = {
            title: {
                text:'fom',
                font: {
                    size: 20
                },
                xref: 'paper',
                x: 0.05,
            },
            autosize: false,
            width: 315,
            height: 315
        };

        var ts3Layout = {
            title: {
                text:'freq',
                font: {
                    size: 20
                },
                xref: 'paper',
                x: 0.05,
            },
            autosize: false,
            width: 315,
            height: 315
        };

        var ts4Layout = {
            title: {
                text:'theta local',
                font: {
                    size: 20
                },
                xref: 'paper',
                x: 0.05,
            },
            autosize: false,
            width: 315,
            height: 315
        };

        var ts5Layout = {
            title: {
                text:'phi local',
                font: {
                    size: 20
                },
                xref: 'paper',
                x: 0.05,
            },
            autosize: false,
            width: 315,
            height: 315
        };

        var kal1Layout = {
            title: {
                text:'Velocity',
                font: {
                    size: 20
                },
                xref: 'paper',
                x: 0.05,
            },
            autosize: false,
            width: 315,
            height: 315
        };

        var kal2Layout = {
            title: {
                text:'Acceleration',
                font: {
                    size: 20
                },
                xref: 'paper',
                x: 0.05,
            },
            autosize: false,
            width: 315,
            height: 315
        };

        var kal3Layout = {
            title: {
                text:'Stationary Confidence (%)',
                font: {
                    size: 20
                },
                xref: 'paper',
                x: 0.05,
            },
            autosize: false,
            width: 315,
            height: 315
        };

        var kal4Layout = {
            title: {
                text:'X',
                font: {
                    size: 20
                },
                xref: 'paper',
                x: 0.05,
            },
            autosize: false,
            width: 315,
            height: 315
        };

        var kal5Layout = {
            title: {
                text:'Y',
                font: {
                    size: 20
                },
                xref: 'paper',
                x: 0.05,
            },
            autosize: false,
            width: 315,
            height: 315
        };

        var kal6Layout = {
            title: {
                text:'Z',
                font: {
                    size: 20
                },
                xref: 'paper',
                x: 0.05,
            },
            autosize: false,
            width: 315,
            height: 315
        };

        var kal7Layout = {
            title: {
                text:'Holdon',
                font: {
                    size: 20
                },
                xref: 'paper',
                x: 0.05,
            },
            autosize: false,
            width: 315,
            height: 315
        };

        var kal8Layout = {
            title: {
                text:'Status',
                font: {
                    size: 20
                },
                xref: 'paper',
                x: 0.05,
            },
            autosize: false,
            width: 315,
            height: 315
        };

        var sc1layout = {
            title: 'Cluster1: All Mode0', 
            aspectratio: {x: 1, y: 1},
            xaxis: {title: 'X-axis', scaleratio: 1},
            yaxis: {title: 'Y-axis', scaleanchor: 'x', scaleratio: 1}
        };

        var sc2layout = {
            title: 'Cluster1: Inside Cluster',
            aspectratio: {x: 1, y: 1},
            xaxis: {title: 'X-axis', scaleratio: 1},
            yaxis: {title: 'Y-axis', scaleanchor: 'x', scaleratio: 1}
        };

        var sc3layout = {
            title: 'Cluster2: Mode0 + Mode2',
            aspectratio: {x: 1, y: 1},
            xaxis: {title: 'X-axis', scaleratio: 1},
            yaxis: {title: 'Y-axis', scaleanchor: 'x', scaleratio: 1}
        };

        var sc4layout = {
            title: 'Cluster2: Inside Cluster',
            aspectratio: {x: 1, y: 1},
            xaxis: {title: 'X-axis', scaleratio: 1},
            yaxis: {title: 'Y-axis', scaleanchor: 'x', scaleratio: 1}
        };
        
        const network = computed({
            get: () => props.network,
            set: (value) => emit("update:network", value),
        });

        const locators = computed({
            get: () => props.locators,
            set: (value) => emit("update:locators", value),
        });

        const tags = computed({
            get: () => props.tags,
            set: (value) => emit("update:tags", value),
        });

        const isFullScreen = computed({
            get: () => props.isFullScreen,
            set: (value) => emit("update:isFullScreen", value),
        });

        const toggleDebugging = () => {
            emit('toggleDebugging')
        }

        const onOpenDisplayOptions = () => {
            displayOptionsDrawer.value = true
        }

        const onStart = () => {
            // Initialize debugger data
            axios.post(protocol.value + ep.value + port.value + '/pmdebuggerlb-start', { network: network.value.id, locator: selectedLocator.value, beacon: selectedTag.value, user: user.value.uid }, { headers })
                .then((res) => {
                if (res.status == 200) {
                    axios.post(protocol.value + ep.value + port.value + '/rtlsdebuggerb-start', { network: network.value.id, beacon: selectedTag.value, user: user.value.uid }, { headers })
                        .then((res) => {
                        if (res.status == 200) {
                            axios.post(protocol.value + ep.value + port.value + '/kalmandebuggerb-start', { network: network.value.id, beacon: selectedTag.value, user: user.value.uid }, { headers })
                                .then((res) => {
                                if (res.status == 200) {
                                    isDataInitialzed.value = true
                                }
                                })
                                .catch((error) => {
                                    isStarted.value = false
                                    if (error.response.status == 444) {
                                        licenseExpiredVisible.value = true
                                    }
                                    onError('Failed to initialize debugger data - kalmandebuggerb.')
                                    console.log(error)
                                })
                        }
                        })
                        .catch((error) => {
                            isStarted.value = false
                            onError('Failed to initialize debugger data - rtlsdebuggerb.')
                            console.log(error)
                            if (error.response.status == 444) {
                                licenseExpiredVisible.value = true
                            }
                        })
                }
                })
                .catch((error) => {
                    isStarted.value = false
                    onError('Failed to initialize debugger data - pmdebuggerlb.')
                    console.log(error)
                    if (error.response.status == 444) {
                        licenseExpiredVisible.value = true
                    }
                })
        }

        const onStartDebuggerWebsockets = () => {
            pmSocket = new WebSocket(wsprotocol.value + clientstreamendpoint.value + wsport.value + "/pmdebuggertracking?token=" + key.value);
            pmSocket.onmessage = event => {
              if (event.data instanceof Blob) {
                const reader = new FileReader();
                reader.onload = () => {
                    var receivedPM = JSON.parse(reader.result);
                    console.log(receivedPM)
                  
                    // Update surface plot data
                    var update = {
                        x: [receivedPM.phiarray],
                        y: [receivedPM.thetaarray],
                        z: [receivedPM.outputpmdebugger2d]
                    };
                    switch(receivedPM.plotindex) {
                        case 0:
                            plot0Data.value = receivedPM
                            Plotly.update(plot0.value, update);
                            break;
                        case 1:
                            plot1Data.value = receivedPM
                            Plotly.update(plot1.value, update);
                            break;
                        case 2:
                            plot2Data.value = receivedPM
                            Plotly.update(plot2.value, update);
                            break;
                        case 3:
                            plot3Data.value = receivedPM
                            Plotly.update(plot3.value, update);
                            break;
                        case 4:
                            plot4Data.value = receivedPM
                            Plotly.update(plot4.value, update);
                            break;
                        case 5:
                            plot5Data.value = receivedPM
                            Plotly.update(plot5.value, update);
                            break;
                        case 6:
                            plot6Data.value = receivedPM
                            Plotly.update(plot6.value, update);
                            break;
                        case 7:
                            plot7Data.value = receivedPM
                            Plotly.update(plot7.value, update);
                            break;
                        default:
                            Plotly.update(plot0.value, update);
                    }

                    // Update pm time series
                    var maxLength = 100;

                    timestampData.value.push(receivedPM.timestamp)
                    if (timestampData.value.length > maxLength) {
                        timestampData.value = timestampData.value.slice(-maxLength);
                    }

                    rssiData.value.push(receivedPM.rssi)
                    if (rssiData.value.length > maxLength) {
                        rssiData.value = rssiData.value.slice(-maxLength);
                    }
                    var updatedrssi = {
                        x: [timestampData.value], 
                        y: [rssiData.value]
                    };
                    Plotly.update(ts1.value, updatedrssi);
                    
                    fomData.value.push(receivedPM.fom)
                    if (fomData.value.length > maxLength) {
                        fomData.value = fomData.value.slice(-maxLength);
                    }
                    var updatedfom = {
                        x: [timestampData.value], 
                        y: [fomData.value]
                    };
                    Plotly.update(ts2.value, updatedfom);

                    freqData.value.push(receivedPM.freq)
                    if (freqData.value.length > maxLength) {
                        freqData.value = freqData.value.slice(-maxLength);
                    }
                    var updatedfreq = {
                        x: [timestampData.value], 
                        y: [freqData.value]
                    };
                    Plotly.update(ts3.value, updatedfreq);

                    thetalocalData.value.push(receivedPM.thetalocal)
                    if (thetalocalData.value.length > maxLength) {
                        thetalocalData.value = thetalocalData.value.slice(-maxLength);
                    }
                    var updatedthetalocal = {
                        x: [timestampData.value], 
                        y: [thetalocalData.value]
                    };
                    Plotly.update(ts4.value, updatedthetalocal);

                    philocalData.value.push(receivedPM.philocal)
                    if (philocalData.value.length > maxLength) {
                        philocalData.value = philocalData.value.slice(-maxLength);
                    }
                    var updatedphilocal = {
                        x: [timestampData.value], 
                        y: [philocalData.value]
                    };
                    Plotly.update(ts5.value, updatedphilocal);
                    
                };
                reader.readAsText(event.data);
              } else {
                console.log("event.data is not an instance of Blob!");
              }
            };
            pmSocket.onopen = function(event) {
              console.log(event);
              console.log("Successfully connected to the pmdebugger websocket server...");
              pmSocket.send("startpmdebuggertracking-" + network.value.id + "-" + user.value.uid + "-" + companyId.value);
              isStarted.value = true
            };
            pmSocket.onclose = function(event) {
              console.log(event);
              console.log("pmdebugger websocket is closed...");
              setTimeout(function() {
                isStarted.value = false
                isDataInitialzed.value = false
              }, 1500);
            };
            pmSocket.onerror = function(event) {
              console.log(event);
              console.log("pmdebugger websocket error");
              setTimeout(function() {
                isStarted.value = false
                isDataInitialzed.value = false
              }, 1500);
            };

            rtlsSocket = new WebSocket(wsprotocol.value + clientstreamendpoint.value + wsport.value + "/rtlsdebuggertracking?token=" + key.value);
            rtlsSocket.onmessage = event => {
              if (event.data instanceof Blob) {
                const reader = new FileReader();
                reader.onload = () => {
                  var receivedRTLS = JSON.parse(reader.result);
                  console.log(receivedRTLS)
                
                    // Plot 1 start

                    var clusteroneray = receivedRTLS.clusteroneray
        
                    var colourArray = ['red', 'blue', 'green', 'yellow', 'brown', 'purple', 'grey', 'cyan', 'orange', 'lime', 'pink']
                    var locatorColour = {}
                    var locatorArray = []
                    var locator = {}
                    var tagx = {}
                    var tagy = {}

                    for (var i = clusteroneray.length - 1; i >= 0; i--) {
                        if (locator.hasOwnProperty(clusteroneray[i].locator)) {
                            // locator key exists
                            var ray = clusteroneray[i].ray
                            tagx[clusteroneray[i].locator].push(ray[3])
                            tagy[clusteroneray[i].locator].push(ray[4])
                        } else {
                            // locator key doesn't exist
                            locatorArray.push(clusteroneray[i].locator)
                            var ray = clusteroneray[i].ray
                            locator[clusteroneray[i].locator] = [ray[0], ray[1]]
                            tagx[clusteroneray[i].locator] = [ray[3]]
                            tagy[clusteroneray[i].locator] = [ray[4]] 
                        }
                    }

                    locatorArray.sort(function(a, b) {
                        var nameA = a.toUpperCase();
                        var nameB = b.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });

                    for (var i = locatorArray.length - 1; i >= 0; i--) {
                        var length = Object.keys(locatorColour).length;
                        locatorColour[locatorArray[i]] = colourArray[length]
                    }

                    var updatedPlot = []
                    for (var key in locator) {
                        var locatorPosition = locator[key]
                        updatedPlot.push({
                            x: [locatorPosition[0]],
                            y: [locatorPosition[1]],
                            mode: 'markers',
                            type: 'scatter',
                            marker: {
                                color: locatorColour[key],
                                size: 16
                            },
                            name: key,
                            showlegend: false
                        })
                    }

                    for (var key in tagx) {
                        updatedPlot.push({
                            x: tagx[key],
                            y: tagy[key],
                            mode: 'markers',
                            type: 'scatter',
                            marker: {
                                color: locatorColour[key],
                                symbol: 'cross'
                            },
                            name: key + " Tag",
                            showlegend: false
                        })
                    }
                  
                    // below code: updates layout but not data
                    // Plotly.update(sc1.value, update1);
                    Plotly.purge(sc1.value);
                    Plotly.newPlot(sc1.value, updatedPlot , sc1layout);

                    // Plot 1 end

                    // Plot 2 start

                    var clusteroneid = receivedRTLS.clusteroneid
                    var clusteronex = receivedRTLS.clusteronex
                    var clusteroney = receivedRTLS.clusteroney
                    var clusteronetimestamp = receivedRTLS.clusteronetimestamp
                    var clusteronecenter = receivedRTLS.clusteronecenter
                    var clusteroneradiusnominal = receivedRTLS.clusteroneradiusnominal
                    var clusteroneradiuscomputed = receivedRTLS.clusteroneradiuscomputed

                    var updatedPlot2 = []
                    for (var i = clusteroneid.length - 1; i >= 0; i--) {
                        var colour = 'black'

                        switch(clusteroneid[i]) {
                            case 0:
                                colour = colourArray[0]
                                break;
                            case 1:
                                colour = colourArray[1]
                                break;
                            case 2:
                                colour = colourArray[2]
                                break;
                            case 3:
                                colour = colourArray[3]
                                break;
                            case 4:
                                colour = colourArray[4]
                                break;
                            case 5:
                                colour = colourArray[5]
                                break;
                            case 6:
                                colour = colourArray[6]
                                break;
                            case 7:
                                colour = colourArray[7]
                                break;
                            default:
                                colour = 'magenta';
                        }

                        updatedPlot2.push({
                            x: [clusteronex[i]],
                            y: [clusteroney[i]],
                            mode: 'markers',
                            type: 'scatter',
                            marker: {
                                color: colour,
                                symbol: 'cross'
                            },
                            name: clusteronetimestamp[i],
                            showlegend: false
                        })
                    }

                    var center = {
                        x: clusteronecenter[0],
                        y: clusteronecenter[1]
                    };

                    var radius1 = clusteroneradiusnominal; 

                    var theta = [...Array(360).keys()];  // Array of angles from 0 to 359 degrees
                    var x1 = theta.map(angle => center.x + radius1 * Math.cos(angle * Math.PI / 180));
                    var y1 = theta.map(angle => center.y + radius1 * Math.sin(angle * Math.PI / 180));

                    updatedPlot2.push({
                        x: x1,
                        y: y1,
                        mode: 'lines',
                        line: {
                            color: 'red' 
                        },
                        showlegend: false 
                    })

                    var radius2 = clusteroneradiuscomputed; 
                    var x2 = theta.map(angle => center.x + radius2 * Math.cos(angle * Math.PI / 180));
                    var y2 = theta.map(angle => center.y + radius2 * Math.sin(angle * Math.PI / 180));

                    updatedPlot2.push({
                        x: x2,
                        y: y2,
                        mode: 'lines',
                        line: {
                            color: 'blue' 
                        },
                        showlegend: false  
                    })

                    Plotly.purge(sc2.value);
                    Plotly.newPlot(sc2.value, updatedPlot2 , sc2layout);

                    // Plot 2 end

                    // Plot 3 start

                    var clustertworay = receivedRTLS.clustertworay
        
                    var locatorColour3 = {}
                    var locatorArray3 = []
                    var locator3 = {}
                    var tagx3 = {}
                    var tagy3 = {}

                    for (var i = clustertworay.length - 1; i >= 0; i--) {
                        if (locator3.hasOwnProperty(clustertworay[i].locator)) {
                            // locator key exists
                            var ray = clustertworay[i].ray
                            tagx3[clustertworay[i].locator].push(ray[3])
                            tagy3[clustertworay[i].locator].push(ray[4])
                        } else {
                            // locator key doesn't exist
                            locatorArray3.push(clustertworay[i].locator)
                            
                            var ray = clustertworay[i].ray

                            locator3[clustertworay[i].locator] = [ray[0], ray[1]]

                            tagx3[clustertworay[i].locator] = [ray[3]]
                            tagy3[clustertworay[i].locator] = [ray[4]] 
                        }
                    }

                    locatorArray3.sort(function(a, b) {
                        var nameA = a.toUpperCase();
                        var nameB = b.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });

                    for (var i = locatorArray3.length - 1; i >= 0; i--) {
                        var length = Object.keys(locatorColour3).length;
                        var mode2dnum = 0
                        if (locatorArray3[i] == "mode2d") {
                            locatorColour3[locatorArray3[i]] = 'black'
                            mode2dnum = 1
                        }
                        else {
                            locatorColour3[locatorArray3[i]] = colourArray[(length-mode2dnum-1)]
                        }
                    }
                    
                    var updatedPlot3 = []
                    for (var key in locator3) {
                        var locatorPosition = locator3[key]
                        if (key != "mode2d") {
                            updatedPlot3.push({
                            x: [locatorPosition[0]],
                            y: [locatorPosition[1]],
                            mode: 'markers',
                            type: 'scatter',
                            marker: {
                                color: locatorColour3[key],
                                size: 16
                            },
                            name: key,
                            showlegend: false
                            })
                        }

                    }

                    for (var key in tagx3) {
                        var symbol = 'cross'
                        if (key == "mode2d") {
                            symbol = 'star'
                        }

                        updatedPlot3.push({
                            x: tagx3[key],
                            y: tagy3[key],
                            mode: 'markers',
                            type: 'scatter',
                            marker: {
                                color: locatorColour3[key],
                                symbol: symbol
                            },
                            name: key + " Tag",
                            showlegend: false
                        })
                    }
                  
                    // below code: updates layout but not data
                    // Plotly.update(sc1.value, update1);
                    Plotly.purge(sc3.value);
                    Plotly.newPlot(sc3.value, updatedPlot3 , sc3layout);

                    // Plot 3 end

                    // Plot 4 start

                    var clustertwoid = receivedRTLS.clustertwoid
                    var clustertwox = receivedRTLS.clustertwox
                    var clustertwoy = receivedRTLS.clustertwoy
                    var clustertwomode = receivedRTLS.clustertwomode
                    var clustertwotimestamp = receivedRTLS.clustertwotimestamp
                    var clustertwocenter = receivedRTLS.clustertwocenter
                    var clustertworadiusnominal = receivedRTLS.clustertworadiusnominal
                    var clustertworadiuscomputed = receivedRTLS.clustertworadiuscomputed
                    var beaconlocation = receivedRTLS.beaconlocation

                    var updatedPlot4 = []
                    for (var i = clustertwoid.length - 1; i >= 0; i--) {
                        var colour = 'black'
                        switch(clustertwoid[i]) {
                            case 0:
                                colour = colourArray[0]
                                break;
                            case 1:
                                colour = colourArray[1]
                                break;
                            case 2:
                                colour = colourArray[2]
                                break;
                            case 3:
                                colour = colourArray[3]
                                break;
                            case 4:
                                colour = colourArray[4]
                                break;
                            case 5:
                                colour = colourArray[5]
                                break;
                            case 6:
                                colour = colourArray[6]
                                break;
                            case 7:
                                colour = colourArray[7]
                                break;
                            default:
                                colour = 'magenta';
                        }

                        var symbol = 'cross'
                        switch(clustertwomode[i]) {
                            case 0:
                                symbol = 'cross'
                                break;
                            case 2:
                                symbol = 'star'
                                break;
                            default:
                                symbol = 'diamond';
                        }

                        updatedPlot4.push({
                            x: [clustertwox[i]],
                            y: [clustertwoy[i]],
                            mode: 'markers',
                            type: 'scatter',
                            marker: {
                                color: colour,
                                symbol: symbol,
                                size: 10
                            },
                            name: clustertwotimestamp[i],
                            showlegend: false
                        })
                    }

                    updatedPlot4.push({
                        x: [beaconlocation[0]],
                        y: [beaconlocation[1]],
                        mode: 'markers',
                        type: 'scatter',
                        marker: {
                            color: 'black',
                            symbol: 'circle',
                            size: 16
                        },
                        name: 'beaconlocation',
                        showlegend: false
                    })

                    var plot4center = {
                        x: clustertwocenter[0],
                        y: clustertwocenter[1]
                    };

                    var plot4Radius1 = clustertworadiusnominal; 

                    var theta = [...Array(360).keys()];  // Array of angles from 0 to 359 degrees
                    var plot4x1 = theta.map(angle => plot4center.x + plot4Radius1 * Math.cos(angle * Math.PI / 180));
                    var plot4y1 = theta.map(angle => plot4center.y + plot4Radius1 * Math.sin(angle * Math.PI / 180));

                    updatedPlot4.push({
                        x: plot4x1,
                        y: plot4y1,
                        mode: 'lines',
                        line: {
                            color: 'red' 
                        },
                        showlegend: false 
                    })

                    var plot4Radius2 = clustertworadiuscomputed; 
                    var plot4x2 = theta.map(angle => plot4center.x + plot4Radius2 * Math.cos(angle * Math.PI / 180));
                    var plot4y2 = theta.map(angle => plot4center.y + plot4Radius2 * Math.sin(angle * Math.PI / 180));

                    updatedPlot4.push({
                        x: plot4x2,
                        y: plot4y2,
                        mode: 'lines',
                        line: {
                            color: 'blue' 
                        },
                        showlegend: false  
                    })

                    


                    Plotly.purge(sc4.value);
                    Plotly.newPlot(sc4.value, updatedPlot4 , sc4layout);

                    // Plot 4 end
                  
                };
                reader.readAsText(event.data);
              } else {
                console.log("event.data is not an instance of Blob!");
              }
            };
            rtlsSocket.onopen = function(event) {
              console.log(event);
              console.log("Successfully connected to the rtlsdebugger websocket server...");
              rtlsSocket.send("startrtlsdebuggertracking-" + network.value.id + "-" + user.value.uid + "-" + companyId.value);
              isStarted.value = true
            };
            rtlsSocket.onclose = function(event) {
              console.log(event);
              console.log("rtlsdebugger websocket is closed...");
              setTimeout(function() {
                isStarted.value = false
                isDataInitialzed.value = false
              }, 1500);
            };
            rtlsSocket.onerror = function(event) {
              console.log(event);
              console.log("rtlsdebugger websocket error");
              setTimeout(function() {
                isStarted.value = false
                isDataInitialzed.value = false
              }, 1500);
            };

            kalmanSocket = new WebSocket(wsprotocol.value + clientstreamendpoint.value + wsport.value + "/kalmandebuggertracking?token=" + key.value);
            kalmanSocket.onmessage = event => {
              if (event.data instanceof Blob) {
                const reader = new FileReader();
                reader.onload = () => {
                  var receivedKalman = JSON.parse(reader.result);
                  console.log(receivedKalman)

                    var maxLength = 100;

                    timestampData.value.push(receivedKalman.timestamp)
                    if (timestampData.value.length > maxLength) {
                        timestampData.value = timestampData.value.slice(-maxLength);
                    }

                    // Velocity
                    velocityData.value.push(receivedKalman.velocity)
                    if (velocityData.value.length > maxLength) {
                        velocityData.value = velocityData.value.slice(-maxLength);
                    }
                    var updatedVelocity = {
                        x: [timestampData.value], 
                        y: [velocityData.value]
                    };
                    Plotly.update(kal1.value, updatedVelocity);

                    // Acceleration
                    accelerationData.value.push(receivedKalman.acceleration)
                    if (accelerationData.value.length > maxLength) {
                        accelerationData.value = accelerationData.value.slice(-maxLength);
                    }
                    var updatedAcceleration = {
                        x: [timestampData.value], 
                        y: [accelerationData.value]
                    };
                    Plotly.update(kal2.value, updatedAcceleration);

                    // Laststationaryconfidencepercentage
                    lastStationaryConfidenceData.value.push(receivedKalman.laststationaryconfidencepercentage)
                    if (lastStationaryConfidenceData.value.length > maxLength) {
                        lastStationaryConfidenceData.value = lastStationaryConfidenceData.value.slice(-maxLength);
                    }
                    var updatedLastStationary = {
                        x: [timestampData.value], 
                        y: [lastStationaryConfidenceData.value]
                    };
                    Plotly.update(kal3.value, updatedLastStationary);

                    // lastaccuratelocation-x & lastaccuratelocationkalman-x & lastaccuratelocationdisplay-x
                    lastAccurateLocationXData.value.push(receivedKalman.lastaccuratelocation[0])
                    if (lastAccurateLocationXData.value.length > maxLength) {
                        lastAccurateLocationXData.value = lastAccurateLocationXData.value.slice(-maxLength);
                    }
                    var updatedLastAccurateLocationX = {
                        x: timestampData.value, 
                        y: lastAccurateLocationXData.value,
                        mode: 'lines',
                        type: 'scatter',
                        name: 'rtls'
                    };

                    

                    lastAccurateLocationKalmanXData.value.push(receivedKalman.lastaccuratelocationkalman[0])
                    if (lastAccurateLocationKalmanXData.value.length > maxLength) {
                        lastAccurateLocationKalmanXData.value = lastAccurateLocationKalmanXData.value.slice(-maxLength);
                    }
                    var updatedLastAccurateLocationKalmanX = {
                        x: timestampData.value, 
                        y: lastAccurateLocationKalmanXData.value,
                        mode: 'lines',
                        type: 'scatter',
                        name: 'kalman'
                    };

                    lastAccurateLocationDisplayXData.value.push(receivedKalman.lastaccuratelocationdisplay[0])
                    if (lastAccurateLocationDisplayXData.value.length > maxLength) {
                        lastAccurateLocationDisplayXData.value = lastAccurateLocationDisplayXData.value.slice(-maxLength);
                    }
                    var updatedLastAccurateLocationDisplayX = {
                        x: timestampData.value, 
                        y: lastAccurateLocationDisplayXData.value,
                        mode: 'lines',
                        type: 'scatter',
                        name: 'display'
                    };

                    Plotly.purge(kal4.value);
                    Plotly.newPlot(kal4.value, [updatedLastAccurateLocationX, updatedLastAccurateLocationKalmanX, updatedLastAccurateLocationDisplayX], kal4Layout);

                    // lastaccuratelocation-y & lastaccuratelocationkalman-y & lastaccuratelocationdisplay-y
                    lastAccurateLocationYData.value.push(receivedKalman.lastaccuratelocation[1])
                    if (lastAccurateLocationYData.value.length > maxLength) {
                        lastAccurateLocationYData.value = lastAccurateLocationYData.value.slice(-maxLength);
                    }
                    var updatedLastAccurateLocationY = {
                        x: timestampData.value, 
                        y: lastAccurateLocationYData.value,
                        mode: 'lines',
                        type: 'scatter',
                        name: 'rtls'
                    };

                    lastAccurateLocationKalmanYData.value.push(receivedKalman.lastaccuratelocationkalman[1])
                    if (lastAccurateLocationKalmanYData.value.length > maxLength) {
                        lastAccurateLocationKalmanYData.value = lastAccurateLocationKalmanYData.value.slice(-maxLength);
                    }
                    var updatedLastAccurateLocationKalmanY = {
                        x: timestampData.value, 
                        y: lastAccurateLocationKalmanYData.value,
                        mode: 'lines',
                        type: 'scatter',
                        name: 'kalman'
                    };

                    lastAccurateLocationDisplayYData.value.push(receivedKalman.lastaccuratelocationdisplay[1])
                    if (lastAccurateLocationDisplayYData.value.length > maxLength) {
                        lastAccurateLocationDisplayYData.value = lastAccurateLocationDisplayYData.value.slice(-maxLength);
                    }
                    var updatedLastAccurateLocationDisplayY = {
                        x: timestampData.value, 
                        y: lastAccurateLocationDisplayYData.value,
                        mode: 'lines',
                        type: 'scatter',
                        name: 'display'
                    };

                    Plotly.purge(kal5.value);
                    Plotly.newPlot(kal5.value, [updatedLastAccurateLocationY, updatedLastAccurateLocationKalmanY, updatedLastAccurateLocationDisplayY], kal5Layout);

                    // lastaccuratelocation-z & lastaccuratelocationkalman-z & lastaccuratelocationdisplay-z
                    lastAccurateLocationZData.value.push(receivedKalman.lastaccuratelocation[2])
                    if (lastAccurateLocationZData.value.length > maxLength) {
                        lastAccurateLocationZData.value = lastAccurateLocationZData.value.slice(-maxLength);
                    }
                    var updatedLastAccurateLocationZ = {
                        x: timestampData.value, 
                        y: lastAccurateLocationZData.value,
                        mode: 'lines',
                        type: 'scatter',
                        name: 'rtls'
                    };

                    lastAccurateLocationKalmanZData.value.push(receivedKalman.lastaccuratelocationkalman[2])
                    if (lastAccurateLocationKalmanZData.value.length > maxLength) {
                        lastAccurateLocationKalmanZData.value = lastAccurateLocationKalmanZData.value.slice(-maxLength);
                    }
                    var updatedLastAccurateLocationKalmanZ = {
                        x: timestampData.value, 
                        y: lastAccurateLocationKalmanZData.value,
                        mode: 'lines',
                        type: 'scatter',
                        name: 'kalman'
                    };

                    lastAccurateLocationDisplayZData.value.push(receivedKalman.lastaccuratelocationdisplay[2])
                    if (lastAccurateLocationDisplayZData.value.length > maxLength) {
                        lastAccurateLocationDisplayZData.value = lastAccurateLocationDisplayZData.value.slice(-maxLength);
                    }
                    var updatedLastAccurateLocationDisplayZ = {
                        type: "scatter",
                        mode: "lines",
                        x: timestampData.value, 
                        y: lastAccurateLocationDisplayZData.value,
                        mode: 'lines',
                        type: 'scatter',
                        name: 'display'
                    };

                    Plotly.purge(kal6.value);
                    Plotly.newPlot(kal6.value, [updatedLastAccurateLocationZ, updatedLastAccurateLocationKalmanZ, updatedLastAccurateLocationDisplayZ], kal6Layout);
                    
                    // counterholdonlocking & counterholdonunlocking
                    counterHoldonLockingData.value.push(receivedKalman.counterholdonlocking)
                    if (counterHoldonLockingData.value.length > maxLength) {
                        counterHoldonLockingData.value = counterHoldonLockingData.value.slice(-maxLength);
                    }
                    var updatedCounterHoldonLocking = {
                        x: timestampData.value, 
                        y: counterHoldonLockingData.value,
                        mode: 'lines',
                        type: 'scatter',
                        name: 'lock'
                    };

                    counterHoldonUnlockingData.value.push(receivedKalman.counterholdonunlocking)
                    if (counterHoldonUnlockingData.value.length > maxLength) {
                        counterHoldonUnlockingData.value = counterHoldonUnlockingData.value.slice(-maxLength);
                    }
                    var updatedCounterHoldonUnlocking = {
                        x: timestampData.value, 
                        y: counterHoldonUnlockingData.value,
                        mode: 'lines',
                        type: 'scatter',
                        name: 'unlock'
                    };

                    Plotly.purge(kal7.value);
                    Plotly.newPlot(kal7.value, [updatedCounterHoldonLocking, updatedCounterHoldonUnlocking], kal7Layout);

                    // status
                    statusData.value.push(receivedKalman.status)
                    if (statusData.value.length > maxLength) {
                        statusData.value = statusData.value.slice(-maxLength);
                    }
                    var updatedStatus = {
                        x: [timestampData.value], 
                        y: [statusData.value]
                    };
                    Plotly.update(kal8.value, updatedStatus);
                  
                };
                reader.readAsText(event.data);
              } else {
                console.log("event.data is not an instance of Blob!");
              }
            };
            kalmanSocket.onopen = function(event) {
              console.log(event);
              console.log("Successfully connected to the kalmandebugger websocket server...");
              kalmanSocket.send("startkalmandebuggertracking-" + network.value.id + "-" + user.value.uid + "-" + companyId.value);
              isStarted.value = true
            };
            kalmanSocket.onclose = function(event) {
              console.log(event);
              console.log("kalmandebugger websocket is closed...");
              setTimeout(function() {
                isStarted.value = false
                isDataInitialzed.value = false
              }, 1500);
            };
            kalmanSocket.onerror = function(event) {
              console.log(event);
              console.log("kalmandebugger websocket error");
              setTimeout(function() {
                isStarted.value = false
                isDataInitialzed.value = false
              }, 1500);
            };
        }

        const onStop = () => {
            axios.post(protocol.value + ep.value + port.value + '/pmdebuggerlb-stop', { network: network.value.id, locator: selectedLocator.value, beacon: selectedTag.value, user: user.value.uid }, { headers })
                .then((res) => {
                if (res.status == 200) {
                    // Successfully removed from pmdebuggerlb relative key in redis
                }
                })
                .catch((error) => {
                    isStarted.value = false
                    onError('Failed to removed from pmdebuggerlb relative key.')
                    console.log(error)
                    if (error.response.status == 444) {
                        licenseExpiredVisible.value = true
                    }
                })

            axios.post(protocol.value + ep.value + port.value + '/rtlsdebuggerb-stop', { network: network.value.id, beacon: selectedTag.value, user: username }, { headers })
                .then((res) => {
                if (res.status == 200) {
                    // Successfully removed from rtlsdebuggerb relative key in redis
                }
                })
                .catch((error) => {
                    isStarted.value = false
                    onError('Failed to removed from rtlsdebuggerb relative key.')
                    console.log(error)
                    if (error.response.status == 444) {
                        licenseExpiredVisible.value = true
                    }
                })
            axios.post(protocol.value + ep.value + port.value + '/kalmandebuggerb-stop', { network: network.value.id, beacon: selectedTag.value, user: username }, { headers })
                .then((res) => {
                if (res.status == 200) {
                    // Successfully removed from kalmandebuggerb relative key in redis
                }
                })
                .catch((error) => {
                    isStarted.value = false
                    onError('Failed to removed from kalmandebuggerb relative key.')
                    console.log(error)
                    if (error.response.status == 444) {
                        licenseExpiredVisible.value = true
                    }
                })

            pmSocket.send("stoppmdebuggertracking-" + network.value.id + "-" + user.value.uid + "-" + companyId.value);
            pmSocket.close();
            pmSocket = null; // prevent memory leak

            rtlsSocket.send("stoprtlsdebuggertracking-" + network.value.id + "-" + user.value.uid + "-" + companyId.value);
            rtlsSocket.close();
            rtlsSocket = null; // prevent memory leak

            kalmanSocket.send("stopkalmandebuggertracking-" + network.value.id + "-" + user.value.uid + "-" + companyId.value);
            kalmanSocket.close();
            kalmanSocket = null; // prevent memory leak

            // 1.5 delay to make sure all the new packets have been added to the scene
            setTimeout(function() {
                isStarted.value = false
                isDataInitialzed.value = false

            }, 1500);
        }

        const onLicenseExpired = async () => {
            await logout();
        }

        const onError = (description) => {
            ElNotification({
                title: 'Error',
                message: description,
                type: 'error',
                duration: 10000
            })
        }

        watch(showPlots, () => {
            console.log(showPlots.value)
        });

        watch(isDataInitialzed, () => {
            if (isDataInitialzed.value == true) {
                onStartDebuggerWebsockets()
            }
        });
            
        onMounted(() => {
            Plotly.newPlot(plot0.value, [{
                type: 'surface',
                z: [[0, 0]],
                colorscale: 'Jet',
                colorbar: {
                    thickness: 10, 
                    len: 0.5
                }
            }], surfaceLayout, 
            { responsive: true, displaylogo: false });

            Plotly.newPlot(plot1.value, [{
                type: 'surface',
                z: [[0, 0]],
                colorscale: 'Jet',
                colorbar: {
                    thickness: 10, 
                    len: 0.5
                }
            }], surfaceLayout, 
            { responsive: true, displaylogo: false });

            Plotly.newPlot(plot2.value, [{
                type: 'surface',
                z: [[0, 0]],
                colorscale: 'Jet',
                colorbar: {
                    thickness: 10, 
                    len: 0.5
                }
            }], surfaceLayout, 
            { responsive: true, displaylogo: false });

            Plotly.newPlot(plot3.value, [{
                type: 'surface',
                z: [[0, 0]],
                colorscale: 'Jet',
                colorbar: {
                    thickness: 10, 
                    len: 0.5
                }
            }], surfaceLayout, 
            { responsive: true, displaylogo: false });

            Plotly.newPlot(plot4.value, [{
                type: 'surface',
                z: [[0, 0]],
                colorscale: 'Jet',
                colorbar: {
                    thickness: 10, 
                    len: 0.5
                }
            }], surfaceLayout, 
            { responsive: true, displaylogo: false });

            Plotly.newPlot(plot5.value, [{
                type: 'surface',
                z: [[0, 0]],
                colorscale: 'Jet',
                colorbar: {
                    thickness: 10, 
                    len: 0.5
                }
            }], surfaceLayout, 
            { responsive: true, displaylogo: false });

            Plotly.newPlot(plot6.value, [{
                type: 'surface',
                z: [[0, 0]],
                colorscale: 'Jet',
                colorbar: {
                    thickness: 10, 
                    len: 0.5
                }
            }], surfaceLayout, 
            { responsive: true, displaylogo: false });

            Plotly.newPlot(plot7.value, [{
                type: 'surface',
                z: [[0, 0]],
                colorscale: 'Jet',
                colorbar: {
                    thickness: 10, 
                    len: 0.5
                }
            }], surfaceLayout, 
            { responsive: true, displaylogo: false });

            Plotly.newPlot(ts1.value, timeSeriesData, ts1Layout, { responsive: true, displaylogo: false });
            Plotly.newPlot(ts2.value, timeSeriesData, ts2Layout, { responsive: true, displaylogo: false });
            Plotly.newPlot(ts3.value, timeSeriesData, ts3Layout, { responsive: true, displaylogo: false });
            Plotly.newPlot(ts4.value, timeSeriesData, ts4Layout, { responsive: true, displaylogo: false });
            Plotly.newPlot(ts5.value, timeSeriesData, ts5Layout, { responsive: true, displaylogo: false });

            Plotly.newPlot(sc1.value, [], sc1layout);
            Plotly.newPlot(sc2.value, [], sc2layout);
            Plotly.newPlot(sc3.value, [], sc3layout);
            Plotly.newPlot(sc4.value, [], sc4layout);

            Plotly.newPlot(kal1.value, timeSeriesData, kal1Layout, { responsive: true, displaylogo: false });
            Plotly.newPlot(kal2.value, timeSeriesData, kal2Layout, { responsive: true, displaylogo: false });
            Plotly.newPlot(kal3.value, timeSeriesData, kal3Layout, { responsive: true, displaylogo: false });
            Plotly.newPlot(kal4.value, timeSeriesData, kal4Layout, { responsive: true, displaylogo: false });
            Plotly.newPlot(kal5.value, timeSeriesData, kal5Layout, { responsive: true, displaylogo: false });
            Plotly.newPlot(kal6.value, timeSeriesData, kal6Layout, { responsive: true, displaylogo: false });
            Plotly.newPlot(kal7.value, timeSeriesData, kal7Layout, { responsive: true, displaylogo: false });
            Plotly.newPlot(kal8.value, timeSeriesData, kal8Layout, { responsive: true, displaylogo: false });
        });

        return {
            network,
            plot0,
            plot1,
            plot2,
            plot3,
            plot4,
            plot5,
            plot6,
            plot7,
            ts1,
            ts2,
            ts3,
            ts4,
            ts5,
            sc1,
            sc2,
            sc3,
            sc4,
            kal1,
            kal2,
            kal3,
            kal4,
            kal5,
            kal6,
            kal7,
            kal8,
            ArrowLeftBold,
            Operation,
            CaretRight, 
            CloseBold,
            toggleDebugging,
            onOpenDisplayOptions,
            displayOptionsDrawer,
            showPlots,
            showPMTimeSeries,
            showRTLS,
            showKALMAN,
            selectedLocator,
            selectedTag,
            isStarted,
            onStart,
            onStop,
            plot0Data,
            plot1Data,
            plot2Data,
            plot3Data,
            plot4Data,
            plot5Data,
            plot6Data,
            plot7Data,
            licenseExpiredVisible,
            onLicenseExpired
        };  
    }   
};
</script>
<style>
.centered-col {
    display: flex;
    align-items: center;
    justify-content: center;
}

.red-circle {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #FF0000;
    border-radius: 50%;
}

.green-circle {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #00FF00;
    border-radius: 50%;
}

.gray-circle {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #808080;
    border-radius: 50%;
}
</style>