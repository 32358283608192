<template>
  <!-- Modal Views - Start -->
  <el-dialog v-model="forgotPasswordVisible" title="Forgot Password" width="30%">
    <el-form label-position="left" label-width="120px" style="max-width: 460px">
      <el-form-item label="Email">
        <el-input v-model="forgotPasswordEmail" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="forgotPasswordVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onForgotPassword">Request Reset Link</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="licenseExpiredVisible" title="License Expired" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width="30%">
    <span>Your license has expired. Please contact IOSEA support team.</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onLicenseExpired()">Ok</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- Modal Views - End -->

  <div class="row align-items-center" style="container-fluid; min-height: 88vh;">
    <div class="signin customContainer">
      <div class="demo-image">
        <el-image style="width: 100px; height: 100px" src="logo-signin.png" fit="scale-down"/>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-sm-4">
          <input type="email" required placeholder="Email" v-model="email" style="height: 40px; font-size: 14px;">
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-sm-4">
          <input type="password" required placeholder="Password" v-model="password" style="height: 40px; font-size: 14px;">
        </div>
      </div>
      <el-button type="primary" size="small" link @click="forgotPasswordVisible = true" style="height: 10px;">Forgot password?</el-button>
      <div class="row align-items-center justify-content-center">
        <div class="col-sm-4">
          <button class="btn btn-primary" style="width: 100%; height: 40px; border-radius: 20px;" @click="onClickSignIn">
            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Sign In
          </button>
          <el-divider>or</el-divider>
        </div>
      </div>
      <div class="row align-items-center justify-content-center" style="height: 50px; font-size: 14px;">
        <div class="col-sm-4">
          <button class="btn btn-primary" style="width: 100%; height: 40px; border-radius: 20px;" @click="onClickCreateAccount">Create Account</button>
        </div>
      </div>
      <div class="row align-items-center justify-content-center mt-3" style="height: 50px; font-size: 13px;">
        <div class="col-sm-4">
          <span style="margin-left: 10px;">Questions? Please email us at </span>
          <a href="mailto:support@iosea.net" style="color: #00d1fd;">support@iosea.net</a>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer mt-auto py-4">
    <div style="font-size: 14px;">Copyright © 2024 IOSEA Inc. All rights reserved.</div>
    <div>
      <p style="font-size: 13px;">Version 2.4.3</p>
    </div>
  </footer>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import useLogin from "../composables/useLogin";
import axios from "axios";
import { projectAuth, projectFirestore } from '../firebase/config';
import { ElNotification } from 'element-plus';
import useLogout from "../composables/useLogout";

export default {
  setup() {
    const router = useRouter();
    const key = ref(window.localStorage.getItem("key"));
    const headers = { 'Authorization': 'Bearer ' + key.value };
    const email = ref("");
    const password = ref("");
    const loading = ref(false);
    const { logout, logoutError } = useLogout();
    const { error, login } = useLogin();
    const forgotPasswordVisible = ref(false);
    const forgotPasswordEmail = ref("");
    const licenseExpiredVisible = ref(false);

    const onClickSignIn = async () => {
      loading.value = true;
      await login(email.value, password.value);
      loading.value = false;
      if (error.value) {
          onError(error.value)
      }
      else {
        let user = projectAuth.currentUser
        projectFirestore.collection('users').doc(user.uid).get()
        .then((doc) => {
            if (doc.exists && doc.data().company_id && doc.data().name && doc.data().email && doc.data().role) {
                var companyId = doc.data().company_id
                var name = doc.data().name
                var email = doc.data().email
                var contactNumber = doc.data().contact_number
                var businessUnit = doc.data().business_unit
                var role = doc.data().role

                // Fetch company details
                projectFirestore.collection("companies").doc(companyId).get()
                .then((doc) => {
                  if (
                    doc.exists &&
                    doc.data().api_ep && 
                    doc.data().clientstream_ep && 
                    doc.data().gatewaystream_ep && 
                    doc.data().key && 
                    doc.data().expiry) {

                      // Check the account expiry date
                      var expiryDate = new Date(doc.data().expiry.seconds * 1000);
                      var now = new Date()
                      if (now > expiryDate) {
                        onError('Your account has expired. Please contact IOSEA team for assistance')
                        onLogout()
                        return
                      }

                      var apiendpoint = doc.data().api_ep
                      var clientstreamendpoint = doc.data().clientstream_ep
                      var gatewayendpoint = doc.data().gatewaystream_ep
                      var key = doc.data().key
                      var expiry = doc.data().expiry
                      var secure = doc.data().secure || false
                      var companyName = doc.data().name
                      var settingsFullAccess = doc.data().settings_full_access || false
                      var debuggingAccess = doc.data().debugging_access || false
                      var presetSettings = doc.data().preset_settings !== undefined ? doc.data().preset_settings : true

                      var protocol = "http://"
                      var wsprotocol = "ws://"
                      var port = ":8080"
                      var wsPort = ":8080"
                      
                      if (secure) {
                        protocol = "https://"
                        wsprotocol = "wss://"
                        port = ":443"
                        wsPort = ":443"
                      }

                      var displaySettings = false
                      if (settingsFullAccess) {
                        displaySettings = true
                      }

                      var generalSetting = false
                      var aoaSetting = false
                      var rtlsSetting = false
                      var clientStreamSetting = false

                      var settingsItems = doc.data().settings_accessible_items || []
                      console.log(settingsItems)
                      window.localStorage.setItem("settingsAccessibleItems", JSON.stringify(settingsItems))
                      for (var i=0; i<settingsItems.length; i++) {
                          if (settingsItems[i] == "general") {
                            displaySettings = true
                            generalSetting = true
                          }
                          if (settingsItems[i] == "aoa") {
                            displaySettings = true
                            aoaSetting = true
                          }
                          if (settingsItems[i] == "rtls") {
                            displaySettings = true
                            rtlsSetting = true
                          }
                          if (settingsItems[i] == "clientstream") {
                            displaySettings = true
                            clientStreamSetting = true
                          }
                      }
                      
                      window.localStorage.setItem('protocol', protocol)
                      window.localStorage.setItem('wsprotocol', wsprotocol)
                      window.localStorage.setItem('endpoint', apiendpoint)
                      window.localStorage.setItem('clientstreamendpoint', clientstreamendpoint)
                      window.localStorage.setItem('gatewayendpoint', gatewayendpoint)
                      window.localStorage.setItem('port', port)
                      window.localStorage.setItem('wsport', wsPort)
                      window.localStorage.setItem('key', key)
                      window.localStorage.setItem('companyId', companyId)
                      window.localStorage.setItem('companyDisplayName', companyName)
                      window.localStorage.setItem('debuggingAccess', debuggingAccess)
                      window.localStorage.setItem('displaySettings', displaySettings)
                      window.localStorage.setItem('settingsFullAccess', settingsFullAccess)
                      window.localStorage.setItem('generalSetting', generalSetting)
                      window.localStorage.setItem('aoaSetting', aoaSetting)
                      window.localStorage.setItem('rtlsSetting', rtlsSetting)
                      window.localStorage.setItem('clientStreamSetting', clientStreamSetting)
                      window.localStorage.setItem('name', name)
                      window.localStorage.setItem('email', email)
                      window.localStorage.setItem('contactNumber', contactNumber)
                      window.localStorage.setItem('businessUnit', businessUnit)
                      window.localStorage.setItem('role', role)
                      window.localStorage.setItem('expiry', expiryDate)
                      window.localStorage.setItem('presetSettings', presetSettings)

                      const headers = {
                        'Authorization': 'Bearer ' + key
                      };
                      
                      // Add User
                      axios.post(protocol + apiendpoint + port + '/user', { id: user.uid }, { headers })
                      .then((res) => {
                      if (res.status == 200) {
                          // onSuccess('User added')
                          router.push({ name: "Networks" });
                      }
                      })
                      .catch((error) => {
                        onError('Failed to add user. Please logout and login again')
                          console.log(error)
                        if (error.response.status == 444) {
                          licenseExpiredVisible.value = true
                        }
                        else {
                          onLogout()
                        }
                      })

                  }
                  else {
                    onError("Couldn't find company details")
                    onLogout()
                  }

                }).catch((error) => {
                  onError("Couldn't get company details")
                  onLogout()
                  console.log("Error getting company details:", error);
                });
            } 
            else {
              onError("Couldn't find user details")
              onLogout()
            }
        }).catch((error) => {
          onError("Couldn't get user details")
          onLogout()
          console.log("Error getting user details:", error);
        });
      }
    };

    const onLogout = async () => {
      await logout();
      if (!error.value) {
        console.log("user logged out");
      }
    };

    const onForgotPassword = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(forgotPasswordEmail.value)) {
        onError("Invalid email address")
        return
      }

      projectAuth.sendPasswordResetEmail(forgotPasswordEmail.value)
      .then(() => {
        onSuccess("Email sent successfully")
      })
      .catch((error) => {
        onError('Failed sending email to user. Please try again later')
        console.error("Error sending password reset email:", error);
      });

      forgotPasswordVisible.value = false
    }

    const onClickCreateAccount = () => {
      router.push({ name: "SignUp" });
    }

    const onLicenseExpired = async () => {
      await logout();
    }

    const onSuccess = (description) => {
      ElNotification({
        title: 'Success',
        message: description,
        type: 'success',
        duration: 5000
      })
    }

    const onError = (description) => {
      ElNotification({
        title: 'Error',
        message: description,
        type: 'error',
        duration: 10000
      })
    }

    return {
      email,
      password,
      loading, 
      onClickSignIn,
      onForgotPassword,
      onClickCreateAccount,
      forgotPasswordVisible,
      forgotPasswordEmail,
      licenseExpiredVisible,
      onLicenseExpired
    };
  }
};
</script>
