<template>
<!-- Modal Views - Start -->
  <el-dialog v-model="licenseExpiredVisible" title="License Expired" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width="30%">
    <span>Your license has expired. Please contact IOSEA support team.</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onLicenseExpired()">Ok</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- Modal Views - End -->

  <el-tooltip v-if="networkPage == 'setup'" content="Reset Camera" placement="left" effect="light"> 
    <el-button type="primary" :disabled='loading' style="margin-top: 10px; margin-bottom: 10px" @click="onResetCamera" :icon="Refresh"> Reset Camera</el-button>
  </el-tooltip>
  <div v-loading="loading" ref="canvas" id="canvas"></div>
  <el-tooltip v-if="networkPage == 'tracking'" content="Display Options" placement="left" effect="light">
    <el-button type="primary" class="controlBtn" :disabled='loading' :style="{ right: rightOffset + 'px', top: '10px' }" @click="onOpenDisplayOptions" :icon="Operation" circle></el-button>
  </el-tooltip>
  <el-tooltip v-if="networkPage == 'tracking'" content="Reset Camera" placement="left" effect="light">
    <el-button type="primary" class="controlBtn" :disabled='loading' :style="{ right: rightOffset + 'px', top: getSceneTopPosition + 'px' }" @click="onResetCamera" :icon="Refresh" circle></el-button>
  </el-tooltip>
  <el-tooltip v-if="networkPage == 'tracking'" content="Network Details" placement="right" effect="light">
    <el-button type="primary" class="controlBtn" :disabled='loading' :style="{ left: rightOffset + 'px', top: '10px' }" @click="onClickNetworkDetails" :icon="ArrowLeftBold" circle></el-button>
  </el-tooltip>
  <el-tooltip v-if="networkPage == 'tracking'" content="Start Tracking" placement="right" effect="light">
    <el-button type="success" class="controlBtn custom-button" :disabled='isStarted' :style="{ left: rightOffset + 'px', top: '60px' }" @click="onStartTracking" :icon="CaretRight" circle></el-button>
  </el-tooltip>
  <el-tooltip v-if="networkPage == 'tracking'" content="Stop Tracking" placement="right" effect="light">
    <el-button type="danger" class="controlBtn" :disabled='!isStarted' :style="{ left: rightOffset + 'px', top: '110px' }" @click="onStopTracking" :icon="CloseBold" circle></el-button>
  </el-tooltip>
</template>

<script>
/* eslint-disable no-undef */
import { computed, ref, onMounted, onUnmounted, watch } from "vue";
import useLogout from "../composables/useLogout";
import getUser from "../composables/getUser";
import { useRouter } from "vue-router";
import useWindowResize from '../use/useWindowResize.js';
import { 
  WebGLRenderer, 
  PerspectiveCamera, 
  OrthographicCamera, 
  Scene, 
  Mesh, 
  PlaneBufferGeometry, 
  BoxBufferGeometry, 
  SphereBufferGeometry, 
  MeshBasicMaterial, 
  MeshStandardMaterial, 
  Color, 
  DirectionalLight, 
  AmbientLight, 
  HemisphereLight,
  NumberKeyframeTrack,
  VectorKeyframeTrack,
  AnimationClip,
  AnimationMixer,
  Clock,
  Vector3,
  Vector2,
  Raycaster,
  LineBasicMaterial,
  BufferGeometry,
  Line,
  Object3D,
  TextureLoader,
  GridHelper
  } from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { SphereGeometryIOSEA } from '../composables/SphereGeometryIOSEA';
import { Refresh, Operation, CloseBold, ArrowLeftBold, CaretRight } from '@element-plus/icons-vue';
import axios from "axios";
import { ElNotification } from 'element-plus';

export default {
  components: { },
  props: [
    'startWatch', 
    'network', 
    'locators', 
    'tags', 
    'anchor', 
    'readyToSetAnchor', 
    'tempLocator',
    'allowSetPosition', 
    'isStarted', 
    'isFullScreen',
    'networkPage', 
    'isRayActivated',
    'showFOV',
    'showUncertaintyRadius',
    'locatorsDrawer',
    'tagsDrawer',
    'displayOptionsDrawer',
    'locatorIndexInDrawer',
    'trackingIndexInDrawer',
    'temperatureIndexInDrawer',
    'accelerationIndexInDrawer',
    'liveTagsTracking',
    'liveTagsThermometer',
    'liveTagsAccelerometer',
    'displayGrid',
    'tagLabel',
    'tagRadio',
    'lastKnown',
    'consoleLogs'
    ],
    emits: [
      'onClickNetworkDetails', 
      'update:startWatch',
      'update:network', 
      'update:locators', 
      'update:tags', 
      'update:anchor',
      'update:readyToSetAnchor', 
      'update:tempLocator',
      'update:allowSetPosition', 
      'update:isStarted', 
      'update:isFullScreen',
      'update:networkPage',
      'update:isRayActivated',
      'update:showFOV',
      'update:showUncertaintyRadius',
      'update:locatorsDrawer',
      'update:tagsDrawer',
      'update:displayOptionsDrawer',
      'update:locatorIndexInDrawer',
      'update:trackingIndexInDrawer',
      'update:temperatureIndexInDrawer',
      'update:accelerationIndexInDrawer',
      'update:liveTagsTracking',
      'update:liveTagsThermometer',
      'update:liveTagsAccelerometer',
      'update:displayGrid',
      'update:tagLabel',
      'update:tagRadio',
      'update:lastKnown',
      'update:consoleLogs'
    ],
  setup(props, { emit }) {
    const startWatch = computed({
      get: () => props.startWatch,
      set: (value) => emit("update:startWatch", value),
    });

    const network = computed({
      get: () => props.network,
      set: (value) => emit("update:network", value),
    }); 

    const locators = computed({
      get: () => props.locators,
      set: (value) => emit("update:locators", value),
    }); 

    const tags = computed({
      get: () => props.tags,
      set: (value) => emit("update:tags", value),
    });

    const anchor = computed({
      get: () => props.anchor,
      set: (value) => emit("update:anchor", value),
    }); 

    const tempLocator = computed({
      get: () => props.tempLocator,
      set: (value) => emit("update:tempLocator", value),
    }); 

    const allowSetPosition = computed({
      get: () => props.allowSetPosition,
      set: (value) => emit("update:allowSetPosition", value),
    }); 

    const isStarted = computed({
      get: () => props.isStarted,
      set: (value) => emit("update:isStarted", value),
    }); 

    const isFullScreen = computed({
      get: () => props.isFullScreen,
      set: (value) => emit("update:isFullScreen", value),
    });

    const networkPage = computed({
      get: () => props.networkPage,
      set: (value) => emit("update:networkPage", value),
    });
    
    const isRayActivated = computed({
      get: () => props.isRayActivated,
      set: (value) => emit("update:isRayActivated", value),
    });

    const showFOV = computed({
      get: () => props.showFOV,
      set: (value) => emit("update:showFOV", value),
    });

    const showUncertaintyRadius = computed({
       get: () => props.showUncertaintyRadius,
       set: (value) => emit("update:showUncertaintyRadius", value),
     });

    const locatorsDrawer = computed({
      get: () => props.locatorsDrawer,
      set: (value) => emit("update:locatorsDrawer", value),
    });

    const tagsDrawer = computed({
      get: () => props.tagsDrawer,
      set: (value) => emit("update:tagsDrawer", value),
    });

    const displayOptionsDrawer = computed({
      get: () => props.displayOptionsDrawer,
      set: (value) => emit("update:displayOptionsDrawer", value),
    });

    const locatorIndexInDrawer = computed({
      get: () => props.locatorIndexInDrawer,
      set: (value) => emit("update:locatorIndexInDrawer", value),
    });

    const trackingIndexInDrawer = computed({
      get: () => props.trackingIndexInDrawer,
      set: (value) => emit("update:trackingIndexInDrawer", value),
    });
    const temperatureIndexInDrawer = computed({
      get: () => props.temperatureIndexInDrawer,
      set: (value) => emit("update:temperatureIndexInDrawer", value),
    });
    const accelerationIndexInDrawer = computed({
      get: () => props.accelerationIndexInDrawer,
      set: (value) => emit("update:accelerationIndexInDrawer", value),
    });

    const liveTagsTracking = computed({
      get: () => props.liveTagsTracking,
      set: (value) => emit("update:liveTagsTracking", value),
    });

    const liveTagsThermometer = computed({
      get: () => props.liveTagsThermometer,
      set: (value) => emit("update:liveTagsThermometer", value),
    });

    const liveTagsAccelerometer = computed({
      get: () => props.liveTagsAccelerometer,
      set: (value) => emit("update:liveTagsAccelerometer", value),
    });

    const displayGrid = computed({
       get: () => props.displayGrid,
       set: (value) => emit("update:displayGrid", value),
     });

    const tagLabel = computed({
      get: () => props.tagLabel,
      set: (value) => emit("update:tagLabel", value),
    });

    const tagRadio = computed({
      get: () => props.tagRadio,
      set: (value) => emit("update:tagRadio", value),
    });

    const lastKnown = computed({
      get: () => props.lastKnown,
      set: (value) => emit("update:lastKnown", value),
    });

    const consoleLogs = computed({
      get: () => props.consoleLogs,
      set: (value) => emit("update:consoleLogs", value),
    });

    const loading = ref(false)
    const readyToSetAnchor = ref(props.readyToSetAnchor)
    const { user } = getUser();
    const router = useRouter();
    const {width, height} = useWindowResize()
    const colourArray = ref([
      "#0080FF",
      "#C92519",
      "#FAE500",
      "#0EA7A5",
      "#FA7A35",
      "#50C878",
      "#FF007F",
      "#2142AB",
      "#367588",
      "#6C3082",
      "#708090",
      "#D1E231",
      "#FFDDF4",
      "#4D4141",
      "#FFFDD0",
      "#FF9B49",
      "#A020F0",
      "#93C572",
      "#367588",
      "#8E7F6F",
      "#54622E",
      "#99AEBB",
      "#A67C25",
      "#C5DAFF",
      "#4DD21D"
    ])

    const { logout, logoutError } = useLogout();
    const canvas = ref(null);
    const scene = new Scene();
    const controls = ref(null);
    const raycaster = new Raycaster();
    const mouse = new Vector2();
    var group = new Object3D();
    const vector = ref(null);
    const locatorObj = ref(null);
    const timer = ref();
    let trackingSocket = ref(null);
    let thermometerSocket = ref(null);
    let accelerometerSocket = ref(null);
    const protocol = ref(window.localStorage.getItem("protocol"));
    const ep = ref(window.localStorage.getItem("endpoint"));
    const clientstreamendpoint = ref(window.localStorage.getItem("clientstreamendpoint"));
    const wsprotocol = ref(window.localStorage.getItem("wsprotocol"));
    const port = ref(window.localStorage.getItem("port"));
    const wsport = ref(window.localStorage.getItem("wsport"));
    const companyId = ref(window.localStorage.getItem("companyId"));
    const key = ref(window.localStorage.getItem("key"));
    const headers = { 'Authorization': 'Bearer ' + key.value };
    const screenRatio = ref(0.5);
    const rightOffset = ref(30);
    const licenseExpiredVisible = ref(false);

    if (process.env.VUE_APP_API_PORT) {
        port.value = process.env.VUE_APP_API_PORT
    }

    if (process.env.VUE_APP_WS_PORT) {
        wsport.value = process.env.VUE_APP_WS_PORT
    }

    scene.background = new Color('#F2F2F2');

    // const camera = new PerspectiveCamera(75, width.value*screenRatio.value / height.value*screenRatio.value, 0.1, 1000);
    const camera = new OrthographicCamera( width.value*screenRatio.value / - 2, width.value*screenRatio.value / 2, height.value*screenRatio.value / 2, height.value*screenRatio.value / - 2, 0.1, network.value.dimensions[1] + 1000 );
    camera.position.set(network.value.dimensions[0]/2, network.value.dimensions[1]/2, network.value.dimensions[1] + 100);

    const renderer = new WebGLRenderer({ antialias: true })
     // turn on the physically correct lighting model
     renderer.physicallyCorrectLights = true;

     // Set the camera's aspect ratio
     camera.aspect = width.value*screenRatio.value / height.value*screenRatio.value;

     // update the camera's frustum
     camera.updateProjectionMatrix();

     // update the size of the renderer AND the canvas
     renderer.setSize(width.value*screenRatio.value, height.value*screenRatio.value);
     // Set the device pixel ratio
     renderer.setPixelRatio(window.devicePixelRatio);

     // Move camera reset button
     rightOffset.value = (width.value - width.value/2)/2 + 5

     const labelRenderer = new CSS2DRenderer();
     labelRenderer.setSize(width.value*screenRatio.value, height.value*screenRatio.value);
     labelRenderer.domElement.style.position = 'absolute';
     labelRenderer.domElement.style.top = '0px';

    // var planeTexture = new TextureLoader().load(
    //   "https://firebasestorage.googleapis.com/v0/b/ioseaplethora-floorplans/o/shop.png?alt=media&token=83b74520-0858-4329-a1bd-0af7fb143198",
    //     function () {
    //         renderer.render(scene, camera);
    //         console.log("*******")
    //     }
    // );

    // const planeMaterial = new MeshBasicMaterial({
    //     map: planeTexture, 
    //     side: 2
    // });

    const planeTexture = new TextureLoader().load(
      network.value.image,
        function () {
            renderer.render(scene, camera);
        }
    );
    const planeMaterialWithTexture = new MeshBasicMaterial({
        map: planeTexture, 
        side: 2
    });

    const planeMaterialWithoutTexture = new MeshBasicMaterial({
        color: '#777777', 
        side: 2
    });

    const planeMaterial = network.value.image ? planeMaterialWithTexture : planeMaterialWithoutTexture
    const planeGeo = new PlaneBufferGeometry(network.value.dimensions[0], network.value.dimensions[1], network.value.dimensions[2], 1)
    const plane = new Mesh(planeGeo, planeMaterial);
    // const planeMaterial = new MeshStandardMaterial({ color: '#777777', side: 2 });
    // const planeGeo = new PlaneBufferGeometry(network.value.dimensions[0], network.value.dimensions[1], network.value.dimensions[2], 1);
    // const plane = new Mesh(planeGeo, planeMaterial);
    plane.position.set(network.value.dimensions[0]/2, network.value.dimensions[1]/2, 0)
    plane.callback = function(name, position, point) { 
      // Set Anchor
      if (readyToSetAnchor.value && allowSetPosition.value) {
        vector.value.position.set(point.x, point.y, 0.2)
        anchor.value[0] = Number((point.x).toFixed(2))
        anchor.value[1] = Number((point.y).toFixed(2))
        if (anchor.value.length == 2) {
          anchor.value[2] = 0
        }
        // setGlobalPosition()
        renderer.render(scene, camera)
      }
    }

    // Field of View
    const fovGeometry = new SphereGeometryIOSEA(1, 30, 30, 0, Math.PI, 0, Math.PI*2);
    const fovMaterial = new MeshBasicMaterial({ color: '#ADD8E6', opacity: 0.6, transparent: true, side: 2  });
    var fov = new Mesh(fovGeometry, fovMaterial);

    // dosen't need if using MeshBasicMaterial
    const light = new DirectionalLight('white', 0.5);
    light.position.set(0, 0, 100);

    // const ambientLight = new AmbientLight('white', 2);
    const ambientLight = new HemisphereLight(
      'white', // bright sky color
      'black', // dim ground color
      3, // intensity
    );

    scene.add(plane, ambientLight, light);

    const loader = new GLTFLoader();

    // Add Grid Helper
    var girdSize = 100;
    if (network.value.dimensions[0] > girdSize/2) {
      girdSize = network.value.dimensions[0]*2 + 5
    }
    if (network.value.dimensions[1] > girdSize/2) {
      girdSize = network.value.dimensions[1]*2 + 5
    }
    const divisions = 200;
    const gridHelper = new GridHelper( girdSize, divisions );
    gridHelper.rotation.x = Math.PI/2
    gridHelper.callback = function(name, position, point) { }
    gridHelper.visible = displayGrid.value
    scene.add(gridHelper);

    const onResize = () => {
      camera.aspect = (width.value*screenRatio.value) / (height.value*screenRatio.value);
      camera.updateProjectionMatrix();
      renderer.setSize(width.value*screenRatio.value, height.value*screenRatio.value);
      if (networkPage.value == "tracking") {
        labelRenderer.setSize(width.value*screenRatio.value, height.value*screenRatio.value);
      }
      renderer.setPixelRatio(window.devicePixelRatio);
      // Move camera reset button
      rightOffset.value = (width.value - renderer.domElement.clientWidth)/2 + 5
      renderer.render(scene, camera);
      if (networkPage.value == "tracking") {
        labelRenderer.render(scene, camera);
      }
    }

    const onChangeControls = () => {
      renderer.render(scene, camera);
      if (networkPage.value == "tracking") {
        labelRenderer.render(scene, camera);
      }
    }

    function onStartTracking() {
      // Make sure there is no existing liveTags
      liveTagsTracking.value = []
      liveTagsThermometer.value = []
      liveTagsAccelerometer.value = []
      // Add loading view
       loading.value = true

      // Add user to the backend to create websocket channels
      axios.post(protocol.value + ep.value + port.value + '/user', { id: user.value.uid }, { headers })
        .then((res) => {
        if (res.status == 200) {
          // 1.5 delay to make sure user is added to the backend and then start streaming
          setTimeout(function() {
            // Remove loading view
            loading.value = false
            // Start timer to hide idle tags
            timer.value = setInterval(() => {
                checkTags()
              }, 5 * 60 * 1000) // 5 minutes in miliseconds
            trackingSocket = new WebSocket(wsprotocol.value + clientstreamendpoint.value + wsport.value + "/tracking?token=" + key.value);
            trackingSocket.onmessage = event => {
              if (event.data instanceof Blob) {
                const reader = new FileReader();
                reader.onload = () => {
                  // console.log("Result: " + reader.result);
                  var receivedTag = JSON.parse(reader.result);
                  var currentDate = new Date()
                  var receivedTagObject = "#0077B6"
                  const tagIndex = tags.value.findIndex((tag, index) => {
                    if (tag.name === receivedTag.beacon) {
                      receivedTagObject = tag.asset
                      return true
                    }
                  })
                  if ((tagIndex != -1 || !tagRadio.value) && receivedTag.beacon != "c50000b00007" && receivedTag.beacon != "0700b00000c5") {
                    // recieved tag exists in registerd tag list
                    const index = liveTagsTracking.value.findIndex((tag, index) => {
                    if (tag.beacon === receivedTag.beacon) {
                        return true
                      }
                    })
                    if (index == -1) {
                      // Tag doesn't exist in live tags
                      if (consoleLogs.value) {
                        console.log(
                          "Tag: " + receivedTag.beacon + ", X: " + receivedTag.x + ", Y: " + receivedTag.y + ", Z: " + receivedTag.z + 
                          ", Time: " + currentDate.getTime() + ", StationaryConfidence: " + receivedTag.stationaryconfidence +
                          ", UncertaintyRadius: " + receivedTag.uncertaintyradius + ", Holdon: " + receivedTag.holdon +
                          ", Status: " + receivedTag.status
                        )
                      }
                      
                      liveTagsTracking.value.push({
                        beacon: receivedTag.beacon, 
                        x: receivedTag.x, 
                        y: receivedTag.y, 
                        z: receivedTag.z, 
                        date: currentDate, 
                        rays: receivedTag.rays, 
                        stationaryconfidence: receivedTag.stationaryconfidence,
                        uncertaintyradius: receivedTag.uncertaintyradius,
                        holdon: receivedTag.holdon,
                        status: receivedTag.status
                        })
                      if (receivedTagObject.includes("#")) {
                        const sphereGeometry = new SphereBufferGeometry(0.125, 30, 30);
                        const sphereMaterial = new MeshStandardMaterial({ color: receivedTagObject });
                        var sphere = new Mesh(sphereGeometry, sphereMaterial)
                        sphere.name = receivedTag.beacon
                        sphere.callback = function(name, position, point) {
                          // Find the tracking, thermometer and accelerometer tag index
                          const index = liveTagsTracking.value.findIndex((tag, index) => {
                            if (tag.beacon === name) {
                                return true
                              }
                            })
                          if (index != -1) {
                            trackingIndexInDrawer.value = index
                          }
                          const thermometerIndex = liveTagsThermometer.value.findIndex((tag, thermometerIndex) => {
                            if (tag.beacon === name) {
                                return true
                              }
                            })
                          if (thermometerIndex != -1) {
                            temperatureIndexInDrawer.value = thermometerIndex
                          }
                          const accelerometerIndex = liveTagsAccelerometer.value.findIndex((tag, accelerometerIndex) => {
                            if (tag.beacon === name) {
                                return true
                              }
                            })
                          if (accelerometerIndex != -1) {
                            accelerationIndexInDrawer.value = accelerometerIndex
                          }
                          // Open the tags drawer
                          tagsDrawer.value = true
                        }

                        // Adding Tag Label
                        sphere.layers.enableAll();
                        const newDiv = document.createElement('div');
                        // newDiv.className = 'label';
                        newDiv.textContent = getTagLabel(receivedTag.beacon)
                        newDiv.style.marginTop = '-0.3em';
                        newDiv.style.color = "black";
                        newDiv.style.background = "#ffff88";
                        newDiv.style.fontSize = "small";
                        // newDiv.style.width = "50px"
                        const tag2DLabel = new CSS2DObject( newDiv );
                        tag2DLabel.position.set(0, 0.2, 0);
                        sphere.add(tag2DLabel);
                        tag2DLabel.layers.set(0);

                        if (lastKnown.value == false && receivedTag.status != 0) {
                          sphere.visible = false;
                          if (canvas.value.contains(labelRenderer.domElement)) {
                            canvas.value.removeChild(labelRenderer.domElement);
                            labelRenderer.render(scene, camera);
                          }
                        }

                        scene.add(sphere);
                        sphere.position.set(receivedTag.x, receivedTag.y, receivedTag.z)
                        renderer.render(scene, camera);
                      }
                      else {
                        requestAnimationFrame(function() {
                          var objectFile = "/" + receivedTagObject + ".glb"
                          loader.load(objectFile, gltf => {
                            gltf.scene.scale.set(1, 1, 1)
                            var obj = gltf.scene
                            obj.name = receivedTag.beacon
                            gltf.scene.traverse( function ( child ) {
                              if ( child.isMesh ) {
                                child.callback = function(name, position, point) { 
                                  // Find the tracking, thermometer and accelerometer tag index
                                  const index = liveTagsTracking.value.findIndex((tag, index) => {
                                    if (tag.beacon === name) {
                                        return true
                                      }
                                    })
                                  if (index != -1) {
                                    trackingIndexInDrawer.value = index
                                  }
                                  const thermometerIndex = liveTagsThermometer.value.findIndex((tag, thermometerIndex) => {
                                    if (tag.beacon === name) {
                                        return true
                                      }
                                    })
                                  if (thermometerIndex != -1) {
                                    temperatureIndexInDrawer.value = thermometerIndex
                                  }
                                  const accelerometerIndex = liveTagsAccelerometer.value.findIndex((tag, accelerometerIndex) => {
                                    if (tag.beacon === name) {
                                        return true
                                      }
                                    })
                                  if (accelerometerIndex != -1) {
                                    accelerationIndexInDrawer.value = accelerometerIndex
                                  }
                                  // Open the tags drawer
                                  tagsDrawer.value = true
                                }
                              }
                            });

                            // Adding Tag Label
                            obj.layers.enableAll();
                            const newDiv = document.createElement('div');
                            // newDiv.className = 'label';
                            newDiv.textContent = getTagLabel(receivedTag.beacon)
                            newDiv.style.marginTop = '-0.3em';
                            newDiv.style.color = "black";
                            newDiv.style.background = "#ffff88";
                            newDiv.style.fontSize = "small";
                            // newDiv.style.width = "50px"
                            const tag2DLabel = new CSS2DObject( newDiv );
                            tag2DLabel.position.set(0, 0.4, 0);
                            obj.add(tag2DLabel);
                            tag2DLabel.layers.set(0);

                            if (lastKnown.value == false && receivedTag.status != 0) {
                              gltf.scene.visible = false;
                              if (canvas.value.contains(labelRenderer.domElement)) {
                                canvas.value.removeChild(labelRenderer.domElement);
                                labelRenderer.render(scene, camera);
                              }
                            }

                            scene.add(gltf.scene);
                            obj.position.set(receivedTag.x, receivedTag.y, receivedTag.z)
                            renderer.render(scene, camera);
                          });
                        });
                      }
                      drawRay(receivedTag.rays)
                      // Adding uncertainty radius
                      const urGeometry = new SphereBufferGeometry(1, 30, 30);
                      const urMaterial = new MeshStandardMaterial({ color: '#2142AB', opacity: 0.2, transparent: true, side: 2 });
                      const urSphere = new Mesh(urGeometry, urMaterial)
                      urSphere.name = receivedTag.beacon + "ur"
                      urSphere.visible = showUncertaintyRadius.value
                      urSphere.callback = function(name, position, point) {
                        // Find the tracking, thermometer and accelerometer tag index
                        const index = liveTagsTracking.value.findIndex((tag, index) => {
                          if (tag.beacon + "ur" === name) {
                              return true
                            }
                          })
                        if (index != -1) {
                          trackingIndexInDrawer.value = index
                        }
                        const thermometerIndex = liveTagsThermometer.value.findIndex((tag, thermometerIndex) => {
                          if (tag.beacon === name) {
                              return true
                            }
                          })
                        if (thermometerIndex != -1) {
                          temperatureIndexInDrawer.value = thermometerIndex
                        }
                        const accelerometerIndex = liveTagsAccelerometer.value.findIndex((tag, accelerometerIndex) => {
                          if (tag.beacon === name) {
                              return true
                            }
                          })
                        if (accelerometerIndex != -1) {
                          accelerationIndexInDrawer.value = accelerometerIndex
                        }
                        // Open the tags drawer
                        tagsDrawer.value = true
                      }

                      if (lastKnown.value == false && receivedTag.status != 0) {
                        urSphere.visible = false;
                      }

                      urSphere.position.set(receivedTag.x, receivedTag.y, receivedTag.z)
                      urSphere.scale.setScalar(receivedTag.uncertaintyradius)
                      scene.add(urSphere);
                    }
                    else {
                      // Tag exists
                      if (consoleLogs.value) {
                        console.log(
                          "Tag: " + receivedTag.beacon + ", X: " + receivedTag.x + ", Y: " + receivedTag.y + ", Z: " + receivedTag.z + 
                          ", Time: " + currentDate.getTime() + ", StationaryConfidence: " + receivedTag.stationaryconfidence +
                          ", UncertaintyRadius: " + receivedTag.uncertaintyradius + ", Holdon: " + receivedTag.holdon +
                          ", Status: " + receivedTag.status
                        )
                      }
                      
                      liveTagsTracking.value[index] = {
                        beacon: receivedTag.beacon, 
                        x: receivedTag.x, 
                        y: receivedTag.y, 
                        z: receivedTag.z, 
                        date: currentDate, 
                        rays: receivedTag.rays, 
                        stationaryconfidence: receivedTag.stationaryconfidence,
                        uncertaintyradius: receivedTag.uncertaintyradius,
                        holdon: receivedTag.holdon,
                        status: receivedTag.status
                      }

                      var existingTag = scene.getObjectByName(receivedTag.beacon);
                      var existingTagUR = scene.getObjectByName(receivedTag.beacon + "ur");
                      requestAnimationFrame(function() {
                        existingTag.position.set(receivedTag.x, receivedTag.y, receivedTag.z);
                        existingTagUR.visible = showUncertaintyRadius.value;
                        existingTagUR.position.set(receivedTag.x, receivedTag.y, receivedTag.z);
                        existingTagUR.scale.setScalar(receivedTag.uncertaintyradius);

                        if (lastKnown.value == false && receivedTag.status != 0) {
                          existingTag.visible = false;
                          existingTagUR.visible = false;
                          if (canvas.value.contains(labelRenderer.domElement)) {
                            canvas.value.removeChild(labelRenderer.domElement);
                            labelRenderer.render(scene, camera);
                          }
                        }
                        else {
                          existingTag.visible = true;
                          existingTagUR.visible = showUncertaintyRadius.value;
                          if (tagLabel.value) {
                            canvas.value.appendChild(labelRenderer.domElement);
                            labelRenderer.render(scene, camera);
                          }
                        }

                        drawRay(receivedTag.rays)
                        renderer.render(scene, camera)
                        if (networkPage.value == "tracking") {
                          labelRenderer.render(scene, camera);
                        }
                      });
                    }
                  }
                };
                reader.readAsText(event.data);
              } 
              else {
                if (event.data.includes("expired")) {
                  licenseExpiredVisible.value = true
                }
                // console.log("event.data is not an instance of Blob!");
              }
            };

            trackingSocket.onopen = function(event) {
              console.log(event);
              console.log("Successfully connected to the websocket server...");
              trackingSocket.send("starttracking-" + network.value.id + "-" + user.value.uid + "-" + companyId.value);
              isStarted.value = true
            };

            trackingSocket.onclose = function(event) {
              console.log(event);
              console.log("websocket is closed...");
              setTimeout(function() {
                isStarted.value = false
              }, 1500);
            };

            trackingSocket.onerror = function(event) {
              console.log(event);
              console.log("websocket error");
              setTimeout(function() {
                isStarted.value = false
              }, 1500);
            };

            thermometerSocket = new WebSocket(wsprotocol.value + clientstreamendpoint.value + wsport.value + "/thermometer?token=" + key.value);
            thermometerSocket.onmessage = event => {
              if (event.data instanceof Blob) {
                const reader = new FileReader();
                reader.onload = () => {
                  // console.log("Result: " + reader.result);
                  var receivedThermometer = JSON.parse(reader.result);
                  const tagIndex = tags.value.findIndex((tag, index) => {
                  if (tag.name === receivedThermometer.beacon) {
                      return true
                    }
                  })
                  if ((tagIndex != -1) || (!tagRadio.value)) {
                    // recieved tag exists in registerd tag list
                    const index = liveTagsThermometer.value.findIndex((tag, index) => {
                    if (tag.beacon === receivedThermometer.beacon) {
                        return true
                      }
                    })
                    if (index == -1) {
                      // Tag doesn't exist in live tags thermometer
                      if (consoleLogs.value) {
                        console.log("Tag: " + receivedThermometer.beacon + ", Value: " + receivedThermometer.value)
                      }
                      
                      liveTagsThermometer.value.push({ beacon: receivedThermometer.beacon, value: receivedThermometer.value })
                    }
                    else {
                      // Tag Exists in live tags thermometer
                      if (consoleLogs.value) {
                        console.log("Tag: " + receivedThermometer.beacon + ", Value: " + receivedThermometer.value)
                      }
                      
                      liveTagsThermometer.value[index] = { beacon: receivedThermometer.beacon, value: receivedThermometer.value }
                    }
                  }
                };
                reader.readAsText(event.data);
              } 
              else {
                if (event.data.includes("expired")) {
                  licenseExpiredVisible.value = true
                }
                // console.log("event.data is not an instance of Blob!");
              }
            };

            thermometerSocket.onopen = function(event) {
              console.log(event);
              console.log("Successfully connected to the thermometer websocket server...");
              thermometerSocket.send("startthermometer-" + network.value.id + "-" + user.value.uid + "-" + companyId.value);
              isStarted.value = true
            };

            thermometerSocket.onclose = function(event) {
              console.log(event);
              console.log("thermometer websocket is closed...");
              setTimeout(function() {
                isStarted.value = false
              }, 1500);
            };

            thermometerSocket.onerror = function(event) {
              console.log(event);
              console.log("thermometer websocket error");
              setTimeout(function() {
                isStarted.value = false
              }, 1500);
            };

            accelerometerSocket = new WebSocket(wsprotocol.value + clientstreamendpoint.value + wsport.value + "/accelerometer?token=" + key.value);
            accelerometerSocket.onmessage = event => {
              if (event.data instanceof Blob) {
                const reader = new FileReader();

                reader.onload = () => {
                  // console.log("Result: " + reader.result);
                  var receivedAccelerometer = JSON.parse(reader.result);
                  const tagIndex = tags.value.findIndex((tag, index) => {
                  if (tag.name === receivedAccelerometer.beacon) {
                      return true
                    }
                  })
                  if ((tagIndex != -1) || (!tagRadio.value)) {
                    // recieved tag exists in registerd tag list
                    const index = liveTagsAccelerometer.value.findIndex((tag, index) => {
                    if (tag.beacon === receivedAccelerometer.beacon) {
                        return true
                      }
                    })
                    if (index == -1) {
                      // Tag doesn't exist in live tags accelerometer
                      if (consoleLogs.value) {
                        console.log("Tag: " + receivedAccelerometer.beacon + ", X: " + receivedAccelerometer.xyz[0] + ", Y: " + receivedAccelerometer.xyz[1] + ", Z: " + receivedAccelerometer.xyz[2])
                      }
                      
                      liveTagsAccelerometer.value.push({ beacon: receivedAccelerometer.beacon, x: receivedAccelerometer.xyz[0], y: receivedAccelerometer.xyz[1], z: receivedAccelerometer.xyz[2]})
                    }
                    else {
                      // Tag Exists in live tags accelerometer
                      if (consoleLogs.value) {
                        console.log("Tag: " + receivedAccelerometer.beacon + ", X: " + receivedAccelerometer.xyz[0] + ", Y: " + receivedAccelerometer.xyz[1] + ", Z: " + receivedAccelerometer.xyz[2])
                      }
                      
                      liveTagsAccelerometer.value[index] = { beacon: receivedAccelerometer.beacon, x: receivedAccelerometer.xyz[0], y: receivedAccelerometer.xyz[1], z: receivedAccelerometer.xyz[2] }
                    }
                  }
                };
                reader.readAsText(event.data);
              } 
              else {
                if (event.data.includes("expired")) {
                  licenseExpiredVisible.value = true
                }
                // console.log("event.data is not an instance of Blob!");
              }
            };

            accelerometerSocket.onopen = function(event) {
              console.log(event);
              console.log("Successfully connected to the accelerometer websocket server...");
              accelerometerSocket.send("startaccelerometer-" + network.value.id + "-" + user.value.uid + "-" + companyId.value);
              isStarted.value = true
            };

            accelerometerSocket.onclose = function(event) {
              console.log(event);
              console.log("accelerometer websocket is closed...");
              setTimeout(function() {
                isStarted.value = false
              }, 1500);
            };

            accelerometerSocket.onerror = function(event) {
              console.log(event);
              console.log("accelerometer websocket error");
              setTimeout(function() {
                isStarted.value = false
              }, 1500);
            };
          }, 1500);
        }
        })
        .catch((error) => {
            onError('Failed to add user. Please logout and login again')
            console.log(error)
            if (error.response.status == 444) {
                licenseExpiredVisible.value = true
            }
        })
    }

    function onStopTracking() {
      // Add loading view
      loading.value = true

      trackingSocket.send("stoptracking-" + network.value.id + "-" + user.value.uid + "-" + companyId.value);
      trackingSocket.close();
      trackingSocket = null; // prevent memory leak

      thermometerSocket.send("stopthermometer-" + network.value.id + "-" + user.value.uid + "-" + companyId.value);
      thermometerSocket.close();
      thermometerSocket = null; // prevent memory leak

      accelerometerSocket.send("stopaccelerometer-" + network.value.id + "-" + user.value.uid + "-" + companyId.value);
      accelerometerSocket.close();
      accelerometerSocket = null; // prevent memory leak


      // 1.5 delay to make sure all the new packets have been added to the scene
      setTimeout(function() {
        isStarted.value = false

        // remove rays
        for (var i = group.children.length - 1; i >= 0; i--) {
          group.remove(group.children[i]);
        }
        // remove tags from scene
        if (liveTagsTracking.value.length) {
          for (let i = 0; i < liveTagsTracking.value.length; i++) {
            var tag = scene.getObjectByName(liveTagsTracking.value[i].beacon);
            // remove tag label (CSS2DObject)
            while (tag.children.length) {
              tag.remove(tag.children[0]);
            }
            scene.remove(tag)
            var existingTagUR = scene.getObjectByName(liveTagsTracking.value[i].beacon + "ur");
            scene.remove(existingTagUR)
            renderer.render(scene, camera)
          }
        }
      
        liveTagsTracking.value = []
        liveTagsThermometer.value = []
        liveTagsAccelerometer.value = []

        clearInterval(timer.value)

        // Remove loading view
        loading.value = false

      }, 1500);
    }

    const checkTags = () => {
      if (liveTagsTracking.value.length) {
        for (let i = 0; i < liveTagsTracking.value.length; i++) {
          var currentDate = new Date()
          let date = liveTagsTracking.value[i].date
          var differenceInSeconds = Math.abs(currentDate - date)/1000
          if (differenceInSeconds > 10) {
            // Hide tag and uncertainty radius
            var existingTag = scene.getObjectByName(liveTagsTracking.value[i].beacon);
            existingTag.visible = false
            var existingTagUR = scene.getObjectByName(liveTagsTracking.value[i].beacon + "ur");
            existingTagUR.visible = false
            renderer.render(scene, camera)
          }
        }
      }
    }

    const drawRay = (rays) => {
      // Remove Ray
      for (var i = group.children.length - 1; i >= 0; i--) {
          group.remove(group.children[i]);
      }
      if (isRayActivated.value) {
        // Draw Ray
        var raysArray = rays
        if(rays.length) {
          for (var j = rays.length -1; j >= 0; j--) {
            if (rays[j].ray.length) {
              var item = rays[j].ray
              const linematerial = new LineBasicMaterial( { color: getRayColour(item[6]) } );
              const points = [];
              points.push( new Vector3( item[0], item[1], item[2] ) );
              points.push( new Vector3( item[3], item[4], item[5] ) );
              const geometry = new BufferGeometry().setFromPoints( points );
              const line = new Line( geometry, linematerial );
              // line.name = "ray"
              group.add(line)
            }
          }
          scene.add(group);
        }
      }
    }

    const getRayColour = (value) => {
      if (value >= 0.8) {
        return '#FF0000'
      }
      if (value >= 0.6 && value < 0.8) {
        return '#FFA500'
      }
      if (value >= 0.4 && value < 0.6) {
        return '#FFFF00'
      }
      if (value >= 0.2 && value < 0.4) {
        return '#00FF00'
      }
      if (value < 0.2) {
        return '#0000FF'
      }
    }
    
    function onDocumentMouseDown(event) {
      event.preventDefault();
      mouse.x = ( ( event.clientX - renderer.domElement.offsetLeft ) / renderer.domElement.clientWidth ) * 2 - 1;
      mouse.y = - ( ( event.clientY  - renderer.domElement.offsetTop ) / renderer.domElement.clientHeight ) * 2 + 1;
      raycaster.setFromCamera( mouse, camera );
      const intersects = raycaster.intersectObjects( scene.children );
      if ( intersects.length > 0 ) {
          intersects[0].object.callback(intersects[0].object.name, intersects[0].object.position, intersects[0].point);
      }
    }

    const calculateGlobalPosition = () => {
      var pi = Math.PI
      var radians = (anchor.value[2] || 0)*(pi/180)
      var globalX = Number(((anchor.value[0] || 0)+(Math.cos(radians)*(tempLocator.value.location[0] || 0)-Math.sin(radians)*(tempLocator.value.location[1] || 0))).toFixed(2))
      var globalY = Number(((anchor.value[1] || 0)+(Math.sin(radians)*(tempLocator.value.location[0] || 0)+Math.cos(radians)*(tempLocator.value.location[1] || 0))).toFixed(2))
      var globalZ = tempLocator.value.location[2]

      return { globalX, globalY, globalZ }
    }
    
    const setGlobalPosition = () => {
      const { globalX, globalY, globalZ } = calculateGlobalPosition()
      locatorObj.value.position.set(globalX, globalY, globalZ)
      if (tempLocator.value.imu.length) {
        var imu = tempLocator.value.imu
        setIMU(imu[9], imu[10], imu[11], imu[12])
      }
    }

    const setFOVQuaternion = (quatX, quatY, quatZ, quatW) => {
      fov.quaternion.set(quatX, quatY, quatZ, quatW)
      renderer.render(scene, camera)
    }

    const setFOV = () => {
      // Remove old fov
      scene.remove(fov);
      fov.geometry.dispose()
      // Add new fov
      var pi = Math.PI
      var phistart = 0
      var phiend = 0
      var thetastart = 0
      var thetaend = 0
      if (tempLocator.value.customfov == 0) {
        phistart = 0*(pi/180)
        phiend = 360*(pi/180)-phistart
        thetastart = 102*(pi/180)
        thetaend = 180*(pi/180)-thetastart
      }
      else {
        phistart = (tempLocator.value.phistartglobal)*(pi/180)
        phiend = (tempLocator.value.phiendglobal)*(pi/180) - (tempLocator.value.phistartglobal)*(pi/180)
        thetastart = (tempLocator.value.thetastartglobal)*(pi/180)
        thetaend = (tempLocator.value.thetaendglobal)*(pi/180) - (tempLocator.value.thetastartglobal)*(pi/180)
      }
      const newFovGeometry = new SphereGeometryIOSEA(tempLocator.value.radiusglobal, 30, 30, phistart, phiend, thetastart, thetaend);
      fov = new Mesh(newFovGeometry, fovMaterial);
      if (tempLocator.value.customfov == 0) {
        var imu = tempLocator.value.imu
        fov.quaternion.set(imu[9], imu[10], imu[11], imu[12])
      }
      const { globalX, globalY, globalZ } = calculateGlobalPosition()
      fov.position.set(globalX, globalY, globalZ)
      fov.rotation.z = tempLocator.value.rotateslice*(pi/180)
      scene.add(fov);
    }

    const toggleLocatorsFOV = () => {
      for (let i = 0; i < locators.value.length; i++) {
        if (showFOV.value) {
          var pi = Math.PI
          var phistart = 0
          var phiend = 0
          var thetastart = 0
          var thetaend = 0
          if (locators.value[i].customfov == 0) {
            phistart = 0*(pi/180)
            phiend = 360*(pi/180)-phistart
            thetastart = 102*(pi/180)
            thetaend = 180*(pi/180)-thetastart
          }
          else {
            phistart = (locators.value[i].phistartglobal)*(pi/180)
            phiend = (locators.value[i].phiendglobal)*(pi/180) - (locators.value[i].phistartglobal)*(pi/180)
            thetastart = (locators.value[i].thetastartglobal)*(pi/180)
            thetaend = (locators.value[i].thetaendglobal)*(pi/180) - (locators.value[i].thetastartglobal)*(pi/180)
          }
          const locatorFOVGeometry = new SphereGeometryIOSEA(locators.value[i].radiusglobal, 30, 30, phistart, phiend, thetastart, thetaend);
          const locatorFOVMaterial = new MeshBasicMaterial({ color: '#ADD8E6', opacity: 0.6, transparent: true, side: 2 });
          const locatorFOV = new Mesh(locatorFOVGeometry, locatorFOVMaterial);
          locatorFOV.name = locators.value[i].name + "fov"
          locatorFOV.callback = function(name, position, point) {
                  // Find the locator index and open the locators drawer
                  const index = locators.value.findIndex((locator, index) => {
                    if (locator.name + "fov" === name) {
                        return true
                      }
                    })
                  if (index != -1) {
                    locatorIndexInDrawer.value = index
                  }
                  locatorsDrawer.value = true
                }
          if (locators.value[i].customfov == 0) {
            var imu = locators.value[i].imu
            locatorFOV.quaternion.set(imu[9], imu[10], imu[11], imu[12])
          }
          locatorFOV.position.set(locators.value[i].location[0],locators.value[i].location[1],locators.value[i].location[2])
          locatorFOV.rotation.z = locators.value[i].rotateslice*(pi/180)
          scene.add(locatorFOV);
          renderer.render(scene, camera)
        }
        else {
          var locatorFOV = scene.getObjectByName(locators.value[i].name + "fov");
          scene.remove(locatorFOV);
        }
      }
    }

    const toggleUncertaintyRadius = () => {
      if (liveTagsTracking.value.length) {
        for (let i = 0; i < liveTagsTracking.value.length; i++) {
          var existingTagUR = scene.getObjectByName(liveTagsTracking.value[i].beacon + "ur");
          existingTagUR.visible = showUncertaintyRadius.value
          renderer.render(scene, camera)
        }
      }
    }

    const addLocatorsToScene = () => {
      if (networkPage.value == "tracking" && locators.value.length) {
        loader.load("/locator.glb", (gltf) => {  
          gltf.scene.scale.set(2, 2, 2);

          for (let i = 0; i < locators.value.length; i++) {
            // Create a closure to capture the current value of locator
            ((currentLocator) => {
              var locator = currentLocator.clone();
              locator.name = locators.value[i].name;
              locator.position.set(locators.value[i].location[0], locators.value[i].location[1], locators.value[i].location[2]);
              var imu = locators.value[i].imu;
              locator.quaternion.set(imu[9], imu[10], imu[11], imu[12]);
              const directionalLight = new DirectionalLight(0xffffff, 0.5);
              directionalLight.position.set(0, 0, 0);
              locator.add(directionalLight);
              locator.traverse(function (child) {
                if (child.isMesh) {
                  child.callback = function (name, position, point) {
                    // Find the locator index and open the locators drawer
                    const index = locators.value.findIndex((loc, index) => {
                      if (loc.name === locator.name) {
                        return true;
                      }
                    });
                    if (index != -1) {
                      locatorIndexInDrawer.value = index;
                    }
                    locatorsDrawer.value = true;
                  };
                }
              });
              scene.add(locator);
            })(gltf.scene);
          }
          renderer.render(scene, camera);
          toggleLocatorsFOV();
        });
      }
      
      if (networkPage.value == "setup") {
        loader.load("/locator.glb", gltf => {
            gltf.scene.scale.set(2, 2, 2)
            locatorObj.value = gltf.scene
            locatorObj.value.name = tempLocator.value.name
            if (tempLocator.value.imu.length) {
              setFOV()
              setGlobalPosition()
            }
            gltf.scene.traverse( function ( child ) {
              if ( child.isMesh ) {
                child.callback = function(name, position, point) { }
              }
            });
            scene.add(gltf.scene);
            renderer.render(scene, camera)
          });
      }
    }

    const setIMU = (quatX, quatY, quatZ, quatW) => {
      locatorObj.value.quaternion.set( quatX, quatY, quatZ, quatW);
      renderer.render(scene, camera)
    }

    const onResetCamera = () => {
      controls.value.reset()
      
      // center camera on the object (ellipse in this case)
      var bounding = plane.geometry.boundingSphere
      // aspect equals window.innerWidth / window.innerHeight
      if ( width.value/height.value > 1.0 ) {
          // if view is wider than it is tall, zoom to fit height
          camera.zoom = renderer.domElement.clientHeight / ( bounding.radius * (isFullScreen.value ? 4 : 2) )
      }
      else {
          // if view is taller than it is wide, zoom to fit width
          camera.zoom = renderer.domElement.clientWidth  / ( bounding.radius * (isFullScreen.value ? 4 : 2) )
      }
    
      camera.updateProjectionMatrix();
      renderer.render(scene, camera);
      if (networkPage.value == "tracking") {
        labelRenderer.render(scene, camera);
      }
    }

    const onClickNetworkDetails = () => {
      isFullScreen.value = false
      isStarted.value = false
      liveTagsTracking.value = []
      liveTagsThermometer.value = []
      liveTagsAccelerometer.value = []
      clearInterval(timer.value)
      emit('onClickNetworkDetails')
    }

    const onOpenDisplayOptions = () => {
      displayOptionsDrawer.value = true
    }

    const getTagLabel = (name) => {
      for (let i = 0; i < tags.value.length; i++) {
        if (tags.value[i].name == name) {
          return tags.value[i].label
        }
      }
    }

    const getSceneTopPosition = computed(() => {
      if (networkPage.value == "setup") {
        if (isFullScreen.value) {
          return 10
        }
        else {
          return canvas.value?.getBoundingClientRect().top || 10
        }
      }
      return 60
    })

    const onLicenseExpired = async () => {
        await logout();
    }

    const onError = (description) => {
      ElNotification({
          title: 'Error',
          message: description,
          type: 'error',
          duration: 10000
      })
    }

    watch(anchor.value, () => {
      vector.value.position.set(anchor.value[0], anchor.value[1], 0.2)
      vector.value.rotation.set(0, 0, ((anchor.value[2] || 0)*(Math.PI/180)))
      setGlobalPosition()
      renderer.render(scene, camera)
    });
    
    watch(user, () => {
      if (!user.value) {
        router.push({ name: "SignIn" });
      }
    });

    watch(tempLocator.value, () => {
      if (networkPage.value == "setup") {
        setFOV()
      }
      setGlobalPosition()
      renderer.render(scene, camera)
    });

    watch(displayGrid, () => {
      gridHelper.visible = displayGrid.value
      renderer.render(scene, camera)
    });

    watch(tagLabel, () => {
      if (networkPage.value == "tracking") {
        if (tagLabel.value) {
          canvas.value.appendChild(labelRenderer.domElement);
          labelRenderer.render(scene, camera);
        }
        else {
          if (canvas.value.contains(labelRenderer.domElement)) {
            canvas.value.removeChild(labelRenderer.domElement);
            labelRenderer.render(scene, camera);
          }
        }
      }
    });

    watch(showFOV, () => {
      toggleLocatorsFOV()
      renderer.render(scene, camera)
    });

    watch(showUncertaintyRadius, () => {
      toggleUncertaintyRadius()
      renderer.render(scene, camera)
    });

    onMounted(async () => {
      // Fix - Need to find out why tagRadio value doesn't work even though it is false
      tagRadio.value = false
      canvas.value.appendChild(renderer.domElement)
      if (networkPage.value == "tracking") {
        if (tagLabel.value) {
          canvas.value.appendChild(labelRenderer.domElement);
        }
      }
      
      // OrbitControls
      controls.value = new OrbitControls(camera, canvas.value);
      controls.value.addEventListener('change', onChangeControls);
      // controls.target.set(1,2,3);
      // controls.target.copy(cube.position);
      // controls.enabled = false;
      // controls.minDistance = 5;
      // controls.maxDistance = 20;
      // controls.enableZoom = false;
      // controls.enableRotate = false;
      // controls.enablePan = false;
      // controls.minAzimuthAngle = - Math.PI/2;
      // controls.maxAzimuthAngle = Math.PI/2;
      // controls.minPolarAngle = - Math.PI/2;
      // controls.maxPolarAngle = Math.PI;

      // Center the Camera on plane
      controls.value.target.copy(plane.position);
      controls.value.update();
      controls.value.saveState()

      addLocatorsToScene()

      // Add Anchor
        loader.load("/vector.glb", gltf => {
          vector.value = gltf.scene
          if (anchor.value.length) {
            if (networkPage.value == "setup") {
              vector.value.position.set(anchor.value[0], anchor.value[1], 0.2)
              vector.value.rotation.set(0, 0, ((anchor.value[2] || 0)*(Math.PI/180)))
            }
            if (networkPage.value == "tracking") {
              vector.value.position.set(0, 0, 0.2)
            }
          }
          gltf.scene.traverse( function ( child ) {
            if ( child.isMesh ) {
              child.callback = function(name, position, point) { }
            }
          });
          scene.add(gltf.scene);
          renderer.render(scene, camera);
        });

      // Fit perspective camera to working space
      // var dist = camera.position.distanceTo( plane.position )
      // var height = ( plane.geometry.parameters.height > plane.geometry.parameters.width ) ? plane.geometry.parameters.width : plane.geometry.parameters.height
      // var fov = 2 * Math.atan( height / ( 1.9 * dist ) ) * ( 180 / Math.PI )
      // camera.fov = fov;
      
      // Fit Orthographic Camera to working space
      // center camera on the object (ellipse in this case)
      var bounding = plane.geometry.boundingSphere
      // aspect equals window.innerWidth / window.innerHeight
      if ( width.value/height.value > 1.0 ) {
          // if view is wider than it is tall, zoom to fit height
          camera.zoom = renderer.domElement.clientHeight / ( bounding.radius * 2 )
      }
      else {
          // if view is taller than it is wide, zoom to fit width
          camera.zoom = renderer.domElement.clientWidth  / ( bounding.radius * 2 )
      }

      camera.updateProjectionMatrix();
      renderer.render(scene, camera);
      // labelRenderer.render(scene, camera);

      startWatch.value = true
      
      window.addEventListener('resize', onResize);
      canvas.value.addEventListener('click', onDocumentMouseDown, false);
      canvas.value.addEventListener('dblclick', function() {
        if (networkPage.value == "setup") {
          if (isFullScreen.value) {
            screenRatio.value = 0.5
          }
          else {
            screenRatio.value = 1
          }
          isFullScreen.value = !isFullScreen.value
          onResize()
        }
      })

      if (isFullScreen.value) {
        screenRatio.value = 1
        onResize()
      }
    })

    onUnmounted(() => {
      window.removeEventListener("resize", onResize);
      if (isStarted.value) {
        onStopTracking()
      }
    });

    return {
      canvas,
      networkPage,
      onClickNetworkDetails, 
      onStartTracking,
      onStopTracking,
      setIMU,
      setFOV,
      setFOVQuaternion,
      rightOffset,
      onResetCamera,
      onOpenDisplayOptions,
      getSceneTopPosition,
      Refresh,
      Operation,
      CloseBold,
      ArrowLeftBold,
      CaretRight,
      loading,
      licenseExpiredVisible,
      onLicenseExpired
    };
  }
};
</script>

<style scoped>
.controlBtn {
   position: absolute;
   margin: 10px;
 }

 .custom-button {
  --el-color-success: #2FDD92 !important; /* Override the --el-color-success variable */
}
/* #scene-container {
  position: absolute;
  width: 60%;
  height: 60%;
  background-color: skyblue;
} */
</style>
