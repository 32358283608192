import { createRouter, createWebHistory } from 'vue-router';
import SignIn from '../views/SignIn.vue';
import SignUp from '../views/SignUp.vue';
import Map from '../views/Map.vue';
import Tracking from '../views/Tracking.vue';
import Networks from '../views/Networks.vue';
import AddWorkingSpace from '../views/AddWorkingSpace.vue';
import ManageAccount from '../views/ManageAccount.vue';
import TermsAndConditions from '../views/TermsAndConditions.vue';
import NotFound from '../views/NotFound.vue';
import { projectAuth } from '../firebase/config';

// auth guard
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if (!user) {
    next({name: 'SignIn'})
  } else {
    next()
  }
}

const requireNoAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if (user) {
    next({name: 'Networks'})
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'SignIn',
    component: SignIn,
    beforeEnter: requireNoAuth
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    beforeEnter: requireNoAuth
  },
  {
    path: '/networks',
    name: 'Networks',
    component: Networks,
    beforeEnter: requireAuth
  },
  {
    path: '/tracking/:id',
    name: 'Tracking',
    component: Tracking,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/addWorkingSpace',
    name: 'AddWorkingSpace',
    component: AddWorkingSpace,
    beforeEnter: requireAuth
  },
  {
    path: '/manageAccount',
    name: 'ManageAccount',
    component: ManageAccount,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/map',
    name: 'Map',
    component: Map,
    beforeEnter: requireAuth
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
    beforeEnter: requireNoAuth
  },
  // catchall 404
  {
    path: '/:catchAll(.*)',
    name: NotFound,
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
