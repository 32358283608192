<template>
<!-- Modal Views - Start -->
  <el-dialog v-model="scannerVisible" title="Add New Locator" width="30%">
    <StreamBarcodeReader @decode="(a, b, c) => onDecode(a, b, c)" @loaded="onLoaded"></StreamBarcodeReader>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="scannerVisible = false">Close</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- Modal Views - End -->

  <div class="row align-items-center" style="container-fluid; min-height: 88vh;">
    <div class="signin customContainer">
      <div class="demo-image">
        <el-image style="width: 100px; height: 100px" src="logo-signin.png" fit="scale-down"/>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-2 d-flex align-items-center" style="height: 50px; font-size: 14px">
          Name*
        </div>
        <div class="col-sm-4">
          <input required placeholder="First Name" v-model="name" style="height: 35px; font-size: 14px;">
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-2 d-flex align-items-center" style="height: 50px; font-size: 14px">
          Company*
        </div>
        <div class="col-sm-4">
          <input required placeholder="Company" v-model="company" style="height: 35px; font-size: 14px;">
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-2 d-flex align-items-center" style="height: 50px; font-size: 14px;">
          Business Unit
        </div>
        <div class="col-sm-4">
          <input placeholder="Business Unit" v-model="businessUnit" style="height: 35px; font-size: 14px;">
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-2 d-flex align-items-center" style="height: 50px; font-size: 14px;">
          Referral
        </div>
        <div class="col-sm-4">
          <input placeholder="Referral" v-model="referral" style="height: 35px; font-size: 14px;">
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-2 d-flex align-items-center" style="height: 50px; font-size: 14px;">
          Contact Number
        </div>
        <div class="col-sm-4">
          <input placeholder="Contact Number" v-model="contactNumber" style="height: 35px; font-size: 14px;">
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-2 d-flex align-items-center" style="height: 50px; font-size: 14px">
          Email*
        </div>
        <div class="col-sm-4">
          <input type="email" required placeholder="Email" v-model="email" style="height: 35px; font-size: 14px;">
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-2 d-flex align-items-center" style="height: 50px; font-size: 14px">
          Create Password*
        </div>
        <div class="col-sm-4">
          <input type="password" required placeholder="Password" v-model="password" style="height: 35px; font-size: 14px;">
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-2 d-flex align-items-center" style="height: 50px; font-size: 14px">
          Confirm Password*
        </div>
        <div class="col-sm-4">
          <input type="password" required placeholder="Confirm Password" v-model="confirmPassword" style="height: 35px; font-size: 14px;">
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-2 d-flex align-items-center" style="height: 50px; font-size: 14px">
          Device Serial No.*
        </div>
        <div class="col-sm-3">
          <input required placeholder="Device Serial Number" v-model="serialNumber" style="height: 35px; font-size: 14px;">
        </div>
        <div class="col-md-1 d-flex align-items-center" style="height: 50px; font-size: 14px;">
          <el-button type="primary" @click="scannerVisible = true; scannerType = 'serial'" :icon="Camera" style="border-radius: 20px;"></el-button>
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-2 d-flex align-items-center" style="height: 50px; font-size: 14px">
          Device MAC*
        </div>
        <div class="col-sm-3">
          <input required placeholder="Device MAC" v-model="mac" style="height: 35px; font-size: 14px;">
        </div>
        <div class="col-md-1 d-flex align-items-center" style="height: 50px; font-size: 14px;">
          <el-button type="primary" @click="scannerVisible = true; scannerType = 'mac'" :icon="Camera" style="border-radius: 20px;"></el-button>
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <el-checkbox v-model="checked" style="margin-top: 25px;"></el-checkbox>
        <div style="display: inline-block; vertical-align: middle; margin-left: 8px; font-size: 14px;">
          I accept the
        </div>
        <div style="display: inline-block; vertical-align: middle; margin-left: 2px; font-size: 14px;">
          <el-button type="primary" link @click="onClickTermsAndConditions" style="height: 20px;">terms and conditions</el-button>
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-sm-6">
          <button class="btn btn-primary" style="width: 100%; height: 40px; border-radius: 20px;" @click="onClickCreateAccount" >
            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Create Account
          </button>
        </div>
      </div>
      <div class="row align-items-center justify-content-center" style="height: 50px; font-size: 14px;"> 
        <el-button type="primary" link @click="onClickSignIn" style="height: 20px;">Sign in instead</el-button>
      </div>
    </div>
  </div>
  <footer class="footer mt-auto py-4">
    <div style="font-size: 14px;">Copyright © 2024 IOSEA Inc. All rights reserved.</div>
    <div>
      <p style="font-size: 13px;">Version 2.4.3</p>
    </div>
  </footer>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { projectFirestore, createFirestoreTimestamp } from '../firebase/config';
import { ElNotification } from "element-plus";
import { Camera } from '@element-plus/icons-vue';
import { StreamBarcodeReader } from "vue-barcode-reader";
const crypto = require('crypto');

export default {
  components: { 
    StreamBarcodeReader
  },
  setup() {
    const router = useRouter();
    const ep = ref('api-iosea-rtls-lab.net');
    const key = ref("SSLKEY");
    const secret = ref("kFnz8Fi/hexhjIYGNhM2C0QJxNA=");
    const loading = ref(false);
    const name = ref("");
    const company = ref("");
    const businessUnit = ref("");
    const referral = ref("");
    const email = ref("");
    const contactNumber = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const mac = ref("");
    const serialNumber = ref("");
    const scannerVisible = ref(false);
    const scannerType = ref("");
    const checked = ref(false)

    if (process.env.VUE_APP_API_ENDPOUNT) {
        ep.value = process.env.VUE_APP_API_ENDPOUNT
    }
    if (process.env.VUE_APP_SECRET) {
        secret.value = process.env.VUE_APP_SECRET
    }

    const headers = { Authorization: "Bearer " + secret.value };

    const onClickCreateAccount = async () => {
      loading.value = true;

      // Fields Validation
      if (!name.value || !company.value || !email.value || !password.value || !confirmPassword.value || !mac.value || !serialNumber.value) {
        onWarning("Please fill the required fields")
        loading.value = false;
        return
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email.value)) {
        onError("Invalid email address")
        loading.value = false;
        return
      }

      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      if (!passwordRegex.test(password.value)) {
        onError("Password must be at least 8 characters long and contain at least one letter, one number, and one special character");
        loading.value = false;
        return;
      }
      // if (password.value.length < 8) {
      //   onError("Password must be at least 8 characters long")
      //   loading.value = false;
      //   return
      // }
      
      if (password.value != confirmPassword.value) {
        onError("Password and Confirm Password does not match")
        loading.value = false;
        return
      }

      if (!checked.value) {
        onError("Please accept the terms and conditions to proceed.")
        loading.value = false;
        return
      }

      var signUpCompanyObject = {
        name: name.value,
        company: company.value,
        businessUnit: businessUnit.value,
        referral: referral.value,
        contactNumber: contactNumber.value,
        email: email.value,
        password: password.value,
        mac: mac.value.replace(/\s/g, "").toLowerCase(),
        serialNumber: serialNumber.value.replace(/\s/g, "").toLowerCase()
      }

      axios.post('https://' + ep.value + ':443/' + 'signup-company', signUpCompanyObject, { headers })
      .then((res) => {
        loading.value = false;
        onSuccess('Sign up completed successfully')
        router.push({ name: "SignIn" });
        })
      .catch((error) => {
        onError(error.response.statusText)
        console.log(error)
        loading.value = false;
      })
    };

    const onClickTermsAndConditions = () => {
      window.open('/terms-and-conditions', '_blank')
    }

    const onDecode = (a, b, c) => {
      if (scannerType.value == "mac") {
        mac.value = a.toLowerCase()
      }
      if (scannerType.value == "serial") {
        serialNumber.value = a.toLowerCase()
      } 
      // Close scanner modal view
      scannerVisible.value = false
    };

    const onLoaded = () => {
      // console.log("ready to scan");
    };

    const onClickSignIn = () => {
      router.push({ name: "SignIn" });
    }

    const generateRandomUid = (length) => {
      const randomBytes = crypto.randomBytes(length);
      const uid = randomBytes.toString('hex');
      return uid;
    }

    const onSuccess = description => {
      ElNotification({
        title: "Success",
        message: description,
        type: "success",
        duration: 5000
      });
    };

    const onWarning = (description) => {
      ElNotification({
        title: 'Warning',
        message: description,
        type: 'warning',
        duration: 5000
      })
    }

    const onError = description => {
      ElNotification({
        title: "Error",
        message: description,
        type: "error",
        duration: 10000
      });
    };

    return {
      name,
      company,
      businessUnit,
      referral,
      contactNumber,
      email,
      password,
      confirmPassword,
      mac,
      serialNumber,
      onClickCreateAccount,
      onClickSignIn,
      loading,
      Camera,
      scannerVisible,
      scannerType,
      onDecode,
      onLoaded,
      checked,
      onClickTermsAndConditions
    };
  }
};
</script>