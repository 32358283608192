import { ref } from 'vue';
 import axios from "axios";

 const getAPIVersion = () => {
    const apiVersion = ref("");
    const protocol = ref(window.localStorage.getItem("protocol"));
    const ep = ref(window.localStorage.getItem("endpoint"));
    const port = ref(window.localStorage.getItem("port"));
    const key = ref(window.localStorage.getItem("key"));
    const headers = { 'Authorization': 'Bearer ' + key.value };

    if (process.env.VUE_APP_API_PORT) {
        port.value = process.env.VUE_APP_API_PORT
    }

     const loadAPIVersion = async () => {
          try {
             const response = await axios.get(protocol.value  + ep.value + port.value + "/version", { headers })
             const isDataAvailable = response.data && response.data.length;
             apiVersion.value =  isDataAvailable ? (" - API Version " + response.data) : ""
             window.localStorage.setItem('apiversion', apiVersion.value)
         }
         catch (err) {
             console.log(err.message)
         }
     }

     return { apiVersion, loadAPIVersion }
 }

 export default getAPIVersion