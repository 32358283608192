import { onMounted, onUnmounted, ref } from "vue";

export default function useWindowResize() {
  const height = ref(String(window.innerHeight));
  const width = ref(String(window.innerWidth));

  function resize() {
    height.value = String(window.innerHeight)
    width.value = String(window.innerWidth)
  }

  onMounted(() => {
    resize()
    window.addEventListener("resize", resize);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", resize);
  });

  return { height, width };
}