<template>
<div style="margin: 30px auto;">
    <h1>404</h1>
    <h2>Page not found</h2>
</div>
</template>

<style>
    h1 {
        text-align: center;
    }
    h2 {
        text-align: center;
    }
</style>

