import { ref } from 'vue'
import axios from "axios";
import { ElNotification } from 'element-plus';

const getNetworks = () => {
    const networks = ref([]);
    const networksError = ref(null);
    const haveNetworksLoaded = ref(false);
    const protocol = ref(window.localStorage.getItem("protocol"));
    const ep = ref(window.localStorage.getItem("endpoint"));
    const port = ref(window.localStorage.getItem("port"));
    const key = ref(window.localStorage.getItem("key"));
    const headers = { 'Authorization': 'Bearer ' + key.value };

    if (process.env.VUE_APP_API_PORT) {
        port.value = process.env.VUE_APP_API_PORT
    }

    const loadNetworks = async () => {
         try {
            const res = await axios.get(protocol.value + ep.value + port.value + "/networks", { headers })
            
            var networkData = res.data
            for (let i = 0; i < networkData.length; i++) {
                if (networkData[i].dimensions) {
                    // Make sure network has dimension and the length with x, y and z
                    if (networkData[i].dimensions.length == 3) {
                        if (!networkData[i].image) {
                            // Make sure network has image property (empty string)
                            networkData[i] = {id: networkData[i].id, name: networkData[i].name, dimensions: networkData[i].dimensions, image: ""}
                        }                        
                        networks.value.push(networkData[i])
                    }
                } 
            }
        }
        catch (err) {
            ElNotification({
                title: 'Error',
                message: 'Failed to load the networks',
                type: 'error',
                duration: 10000
            })
            networksError.value = err.response.status
            console.log(networksError.value)
        }
    }

    return { networks, networksError, haveNetworksLoaded, loadNetworks }
}

export default getNetworks