<template>
  <div>
    <div v-if="pdfUrl">
      <iframe :src="pdfUrl" width="100%" height="500px" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { getDownloadURL, ref as storageRef } from 'firebase/storage';
import { storage } from '../firebase/config'; // Import the initialized Firebase app.

export default {
  setup() {
    const pdfUrl = ref('');

    onMounted(async () => {
      try {
        const pdfRef = storageRef(storage, 'gs://ioseaplethora_termsandconditions/terms-and-conditions.pdf');
        const url = await getDownloadURL(pdfRef);
        pdfUrl.value = url;
      } catch (error) {
        console.error('Error fetching PDF URL:', error);
      }
    })

    return {
      pdfUrl,
    };
  },
};
</script>

<style>
  iframe {
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
    height: 100vh;
  }
</style>
