<template>
  <!-- Modal Views - Start -->
  <el-dialog v-model="editProfileVisible" title="Edit Profile Details" width="30%">
    <el-form label-position="left" label-width="120px" style="max-width: 460px">
      <div class="row align-items-center justify-content-center">
      <div class="col-md-4 d-flex align-items-center justify-content-end" style="height: 50px; font-size: 14px">
        Name
      </div>
      <div class="col-sm-6">
        <input required placeholder="Name" v-model="tempProfile.name" style="height: 35px; font-size: 14px;width: 100%">
      </div>
      <div class="col-sm-2">        
      </div>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-md-4 d-flex align-items-center justify-content-end" style="height: 50px; font-size: 14px">
        Email
      </div>
      <div class="col-sm-6">
        <input required placeholder="Email" disabled v-model="tempProfile.email" style="height: 35px; font-size: 14px;width: 100%">
      </div>
      <div class="col-sm-2">        
      </div>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-md-4 d-flex align-items-center justify-content-end" style="height: 50px; font-size: 14px">
        Contact Number
      </div>
      <div class="col-sm-6">
        <input required placeholder="Contact Number" v-model="tempProfile.contactNumber" style="height: 35px; font-size: 14px;width: 100%">
      </div>
      <div class="col-sm-2">        
      </div>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-md-4 d-flex align-items-center justify-content-end" style="height: 50px; font-size: 14px">
        Company
      </div>
      <div class="col-sm-6">
        <input required placeholder="Company" disabled v-model="companyDisplayName" style="height: 35px; font-size: 14px;width: 100%">
      </div>
      <div class="col-sm-2">        
      </div>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-md-4 d-flex align-items-center justify-content-end" style="height: 50px; font-size: 14px">
        Business Unit
      </div>
      <div class="col-sm-6">
        <input required placeholder="Business Unit" v-model="tempProfile.businessUnit" style="height: 35px; font-size: 14px;width: 100%">
      </div>
      <div class="col-sm-2">        
      </div>
    </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editProfileVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onUpdateProfile">Update</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="addUserVisible" title="Add New User" width="30%">
    <el-form label-position="left" label-width="120px" style="max-width: 460px">
      <div class="row align-items-center justify-content-center">
      <div class="col-md-4 d-flex align-items-center justify-content-end" style="height: 50px; font-size: 14px">
        Name
      </div>
      <div class="col-sm-6">
        <input required placeholder="Name" v-model="tempUser.name" style="height: 35px; font-size: 14px;width: 100%">
      </div>
      <div class="col-sm-2">        
      </div>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-md-4 d-flex align-items-center justify-content-end" style="height: 50px; font-size: 14px">
        Email
      </div>
      <div class="col-sm-6">
        <input required placeholder="Email" v-model="tempUser.email" style="height: 35px; font-size: 14px;width: 100%">
      </div>
      <div class="col-sm-2">        
      </div>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-md-4 d-flex align-items-center justify-content-end" style="height: 50px; font-size: 14px">
        Contact Number
      </div>
      <div class="col-sm-6">
        <input required placeholder="Contact Number" v-model="tempUser.contactNumber" style="height: 35px; font-size: 14px;width: 100%">
      </div>
      <div class="col-sm-2">        
      </div>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-md-4 d-flex align-items-center justify-content-end" style="height: 50px; font-size: 14px">
        Business Unit
      </div>
      <div class="col-sm-6">
        <input required placeholder="Business Unit" v-model="tempUser.businessUnit" style="height: 35px; font-size: 14px;width: 100%">
      </div>
      <div class="col-sm-2">        
      </div>
    </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addUserVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onAddUser">Add</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="setAdminVisible" title="Warning" width="30%">
    <span>Are you sure you want to set this user as an admin and revoke your own admin role?</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="setAdminVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onSetAdmin()"
          >Confirm</el-button
        >
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="licenseExpiredVisible" title="License Expired" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width="30%">
    <span>Your license has expired. Please contact IOSEA support team.</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onLicenseExpired()">Ok</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- Modal Views - End -->

  <Navbar v-model:showNavbarLinks="showNavbarLinks"/>
    <el-row class="pt-5" align="top">
      <el-col :span="4">
        <el-menu default-active="1" @select="handleSelect">
          <el-menu-item index="1">
            <el-icon><UserFilled /></el-icon>
            <span>Profile</span>
          </el-menu-item>
          <el-menu-item v-if="role == 'admin'" index="2">
            <el-icon><Share /></el-icon>
            <span>User Management</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      
      <el-col :span="16" style="justify-content: center; align-items: center; flex-direction: column; display: flex;">
        <div class="row justify-content-md-left py-4">
          <h2>{{ selectedSection }}</h2>
        </div>
        <div v-if="sectionNumber == '1'" class="py-4" style="container-fluid; min-height: 62vh; padding-bottom;">
          <div class="info-row">
            <div class="label">Name</div>
            <div class="value">{{ name }}</div>
          </div>
          <el-divider />
          <div class="info-row">
            <div class="label">Email</div>
            <div class="value">{{ email }}</div>
          </div>
          <el-divider />
          <div class="info-row">
            <div class="label">Contact Number</div>
            <div class="value">{{ contactNumber }}</div>
          </div>
          <el-divider />
          <div class="info-row">
            <div class="label">Company</div>
            <div class="value">{{ companyDisplayName }}</div>
          </div>
          <el-divider />
          <div class="info-row">
            <div class="label">Business Unit</div>
            <div class="value">{{ businessUnit }}</div>
          </div>
          <el-divider />
          <div class="py-4">
            <el-button type="primary" @click="onClickEdit">Edit</el-button>
          </div>
        </div>
        <div v-if="sectionNumber == '2' && role == 'admin'" class="py-4" style="container-fluid; min-height: 62vh; padding-bottom: 50px; width: 90%">
          <el-table :data="users">
            <el-table-column label="Name">
              <template #default="scope">
                <div style="display: flex; align-items: center; font-size: 13px">
                  <span>{{ scope.row.name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Email">
              <template #default="scope">
                <div style="display: flex; align-items: center; font-size: 13px">
                  <span>{{ scope.row.email }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Contact Number" width="160">
              <template #default="scope">
                <div style="display: flex; align-items: center; font-size: 13px">
                  <span>{{ scope.row.contact_number }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Business Unit" width="170">
              <template #default="scope">
                <div style="display: flex; align-items: center; font-size: 13px">
                  <span>{{ scope.row.business_unit }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Role" width="80">
              <template #default="scope">
                <div style="display: flex; align-items: center; font-size: 13px">
                  <span>{{ scope.row.role }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="" width="120">
              <template #default="scope">
                <div style="display: flex; align-items: center">
                  <el-dropdown v-if="email != scope.row.email" size="small" split-button type="primary">
                  Actions
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="() => handleAction('resendEmail', scope.row.email)">Resend Email</el-dropdown-item>
                      <el-dropdown-item @click="() => handleAction('setAsAdmin', scope.row.email)">Set as Admin</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="py-4">
            <el-button type="primary" @click="onClickAdd">Add User</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  <footer class="footer mt-auto py-4">
    <div style="font-size: 14px;">Copyright © 2024 IOSEA Inc. All rights reserved.</div>
    <div>
      <p style="font-size: 13px;">Version 2.4.3 {{ apiVersion }}</p>
    </div>
  </footer>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import useLogout from "../composables/useLogout";
import Navbar from "../components/Navbar.vue";
import axios from "axios";
import getUser from "../composables/getUser";
import { projectAuth, projectFirestore } from '../firebase/config';
import { ElNotification } from "element-plus";
import { UserFilled, Share } from '@element-plus/icons-vue';

export default {
  components: { Navbar, UserFilled, Share },
  setup() {
    const router = useRouter();
    const { user } = getUser();
    const { logout, logoutError } = useLogout();
    const showNavbarLinks = ref(true);
    const sectionNumber = ref("1");
    const selectedSection = ref("Profile");
    const protocol = ref(window.localStorage.getItem("protocol"));
    const ep = ref(window.localStorage.getItem("endpoint"));
    const port = ref(window.localStorage.getItem("port"));
    const companyId = ref(window.localStorage.getItem('companyId'));
    const companyDisplayName = ref(window.localStorage.getItem("companyDisplayName"));
    const name = ref(window.localStorage.getItem("name"));
    const email = ref(window.localStorage.getItem("email"));
    const contactNumber = ref(window.localStorage.getItem("contactNumber"));
    const businessUnit = ref(window.localStorage.getItem("businessUnit"));
    const role = ref(window.localStorage.getItem("role"));
    const apiVersion = ref(window.localStorage.getItem("apiversion"));
    const key = ref(window.localStorage.getItem("key"));
    const headers = { 'Authorization': 'Bearer ' + key.value };
    const editProfileVisible = ref(false);
    const addUserVisible = ref(false);
    const tempProfile = ref({name: "", email: "", contactNumber: "", businessUnit: ""});
    const tempUser = ref({name: "", email: "", contactNumber: "", businessUnit: ""});
    const users = ref([]);
    const setAdminVisible = ref(false);
    const tempAdminEmail = ref(null);
    const licenseExpiredVisible = ref(false);

    const handleSelect = (key, keyPath) => {
      sectionNumber.value = key
      if (sectionNumber.value == "1") {
        selectedSection.value = "Profile"
      }
      if (sectionNumber.value == "2") {
        selectedSection.value = "Accounts"
      }
    }

    const onClickEdit = () => {
      editProfileVisible.value = true
      tempProfile.value = {name: name.value, email: email.value, contactNumber: contactNumber.value, businessUnit: businessUnit.value}
    }

    const onUpdateProfile = () => {
      if (!tempProfile.value.name) {
        onWarning("Please fill the name field")
        return
      }

      editProfileVisible.value = false

      const updatedFields = {
        name: tempProfile.value.name,
        contact_number: tempProfile.value.contactNumber || "",
        business_unit: tempProfile.value.businessUnit || ""
      };

      let user = projectAuth.currentUser
      projectFirestore.collection('users').doc(user.uid).update(updatedFields)
      .then(() => {
        name.value = updatedFields.name
        contactNumber.value = updatedFields.contact_number
        businessUnit.value = updatedFields.business_unit
        window.localStorage.setItem('name', updatedFields.name)
        window.localStorage.setItem('contactNumber', updatedFields.contact_number)
        window.localStorage.setItem('businessUnit', updatedFields.business_unit)
      })
      .catch((error) => {
          onError('Failed to update user profile')
          console.error("Error updating user details: ", error);
      })
    }

    const getUsersWithCompany = async () => {
      users.value = []
      try {
        const querySnapshot = await projectFirestore.collection('users')
          .where('company_id', '==', companyId.value)
          .get();

        querySnapshot.forEach((doc) => {
          users.value.push(doc.data());
        });
        
        return users;
      } catch (error) {
        console.error('Error getting users with company:', error);
        throw error;
      }
    };

    const onClickAdd = () => {
      if (users.value.length < 3) {
        addUserVisible.value = true
        tempUser.value = {name: "", email: "", contactNumber: "", businessUnit: ""}
      }
      else {
        onWarning("User Limit Exceeded. Your company has reached the maximum allowed number of users. Please contact IOSEA for further assistance.")
      }
    }

    const onAddUser = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(tempUser.value.email)) {
        onError("Invalid email address")
        return
      }

      addUserVisible.value = false
      var newUser = {
        name: tempUser.value.name,
        email: tempUser.value.email,
        role: 'user',
        companyId: companyId.value,
        contactNumber: tempUser.value.contactNumber,
        businessUnit: tempUser.value.businessUnit,
        referral: name.value
      }
      // Sign up user
      axios.post(protocol.value + ep.value + port.value + '/signup-user', newUser, { headers })
      .then((res) => {  
      if (res.status == 200) {
        projectAuth.sendPasswordResetEmail(tempUser.value.email)
        .then(() => {
          onSuccess("User created successfully")
          users.value.push(newUser);
        })
        .catch((error) => {
          onError('Failed sending email to user. Please try again later')
          console.error("Error sending password reset email:", error);
        });
      }
      })
      .catch((error) => {
        onError(error.response.statusText)
        console.log(error)
        if (error.response.status == 444) {
          licenseExpiredVisible.value = true
        }
      })
    }

    const handleAction = (action, email) => {
      switch (action) {
        case 'resendEmail':
          projectAuth.sendPasswordResetEmail(email)
          .then(() => {
            onSuccess("Email sent successfully")
          })
          .catch((error) => {
            onError('Failed sending email to user. Please try again later')
            console.error("Error sending password reset email:", error);
          });
          break;
        case 'setAsAdmin':
          setAdminVisible.value = true
          tempAdminEmail.value = email
          break;
        default:
          break;
      }
    };

    const onSetAdmin = async () => {
      setAdminVisible.value = false
      try {
        const querySnapshot = await projectFirestore.collection('users')
          .where('email', '==', tempAdminEmail.value)
          .get();

        const updatedField = {
          role: 'admin'
        };

        querySnapshot.forEach((doc) => {
          // Set user role to admin
          projectFirestore.collection('users').doc(doc.id).update(updatedField)
          .then(() => {

            // Update admin email inside company
            const updatedAdminEmail = {
              admin_email: tempAdminEmail.value
            }
            projectFirestore.collection('companies').doc(companyId.value).update(updatedAdminEmail)
            .then(() => {
              // Update current user role to user
              let user = projectAuth.currentUser
              const currentUserUpdatedField = {
                role: 'user'
              };
              projectFirestore.collection('users').doc(user.uid).update(currentUserUpdatedField)
              .then(() => {
                role.value = "user"
                sectionNumber.value = "1"
                window.localStorage.setItem('role', "user")
              })
              .catch((error) => {
                  onError('Failed to update current user role')
                  console.error("Error updating current user role: ", error);
              })
            })
            .catch((error) => {
                onError('Failed to update admin email')
                console.error("Error updating admin email: ", error);
            })
          })
          .catch((error) => {
              onError('Failed to set user as an admin')
              console.error("Error setting user as admin: ", error);
          })
        });
        
        return users;
      } catch (error) {
        console.error('Error setting user as admin:', error);
        throw error;
      }
    };

    const onLicenseExpired = async () => {
      await logout();
    }

    const onSuccess = (description) => {
      ElNotification({
        title: 'Success',
        message: description,
        type: 'success',
        duration: 5000
      })
    }

    const onWarning = (description) => {
      ElNotification({
        title: 'Warning',
        message: description,
        type: 'warning',
        duration: 5000
      })
    }

    const onError = (description) => {
      ElNotification({
        title: 'Error',
        message: description,
        type: 'error',
        duration: 10000
      })
    }

    watch(user, () => {
      if (!user.value) {
        router.push({ name: "SignIn" });
      }
    });

    onMounted(() => {
      getUsersWithCompany()
    });

    return {
      showNavbarLinks,
      handleSelect,
      sectionNumber,
      selectedSection,
      companyId,
      companyDisplayName,
      name,
      email,
      contactNumber,
      businessUnit,
      role,
      Document,
      onClickEdit,
      editProfileVisible,
      addUserVisible,
      onUpdateProfile,
      tempProfile,
      tempUser,
      users,
      onClickAdd,
      onAddUser,
      apiVersion,
      onSetAdmin,
      setAdminVisible,
      handleAction,
      licenseExpiredVisible,
      onLicenseExpired
    };
  }
};
</script>

<style>
.info-row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  text-align: left;
}

.label {
  width: 200px;
  font-weight: 500;
}

.value {
  flex: 1;
  text-align: left;
}
</style>