<template>
  <!-- Modal Views - Start -->
    <el-dialog v-model="resetStorageVisible" title="Warning" width="30%">
      <span>You have selected to permanently delete all the networks details from database. Do you want to continue?</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetStorageVisible = false">Cancel</el-button>
          <el-button type="primary" @click="onResetStorage()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="licenseExpiredVisible" title="License Expired" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width="30%">
      <span>Your license has expired. Please contact IOSEA support team.</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="onLicenseExpired()">Ok</el-button>
        </span>
      </template>
    </el-dialog>
  <!-- Modal Views - End -->

  <div id="nav">
    <nav class="container navbar navbar-expand-md navbar-custom">
      <a href="#" @click="onClickLogo" class="navbar-brand">
        <img src="/logo-signin.png" width="80" height="20">
      </a>
      <button class="navbar-toggler navbar-light" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="navbar-nav mr-auto">
          <router-link v-if="showNavbarLinks == true" :to="{ name: 'Networks' }" class="nav-item nav-link" style="margin-left:20px">Networks</router-link>
          <a href="https://ioseadocumentation.net" target="_blank" class="nav-item nav-link" style="margin-left:20px">Documentation</a>
        </div>
        <ul v-if="user" class="my-auto" style="list-style-type: none">
          <li class="dropdown">
            <a class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-weight: normal;">
              {{ user.email }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" id="logout" @click="onClickManageAccount">Manage Account</a>
              <a class="dropdown-item" id="logout" @click="onClickLogout">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useRouter } from "vue-router";
import useLogout from "../composables/useLogout";
import getUser from "../composables/getUser";
import { projectFirestore } from '../firebase/config';
import axios from "axios";
import { ElNotification } from 'element-plus';

export default {
  props: ['showNavbarLinks'],
  setup(props) {
    const router = useRouter();
    const { logout, logoutError } = useLogout();
    const { user } = getUser();
    const protocol = ref(window.localStorage.getItem("protocol"));
    const ep = ref(window.localStorage.getItem("endpoint"));
    const port = ref(window.localStorage.getItem("port"));
    const key = ref(window.localStorage.getItem("key"));
    const headers = { 'Authorization': 'Bearer ' + key.value };
    const resetStorageVisible = ref(false);
    const profile = ref(null);
    const users = ref([]);
    const licenseExpiredVisible = ref(false);

    const showNavbarLinks = computed({
      get: () => props.showNavbarLinks,
      set: (value) => emit("update:showNavbarLinks", value),
    });

    if (process.env.VUE_APP_API_PORT) {
         port.value = process.env.VUE_APP_API_PORT
     }

    const onClickManageAccount = async () => {
      router.push({ name: "ManageAccount" });
    }

    const onClickLogout = async () => {
      var uid = user.value.uid
      await logout();
      if (!logoutError.value) {
        axios.delete(protocol.value + ep.value + port.value + '/user/' + uid, { headers })
          .then((res) => {
          if (res.status == 200) {
              // onSuccess('User added')
          }
          })
          .catch((error) => {
            // onError('Failed to delete user')
            console.log(error)
          })
      }
    }

    const onResetStorage = () => {
      resetStorageVisible.value = false
      axios.get(protocol.value + ep.value + port.value + "/storagereset", { headers })
        .then((res) => {
          if (res.status == 200) {
            onSuccess('Networks details removed')
            router.push({ name: "Networks" });
          }
        })
        .catch((error) => {
          onError('Failed to remove the networks details')
          console.log(error)
          if (error.response.status == 444) {
              licenseExpiredVisible.value = true
          }
        })
    }

    const onClickLogo = () => {
      router.push({ name: "Networks" });
    }

    const onLicenseExpired = async () => {
        await logout();
    }

    const onSuccess = (description) => {
      ElNotification({
          title: 'Success',
          message: description,
          type: 'success',
          duration: 5000
      })
    }

    const onError = (description) => {
      ElNotification({
          title: 'Error',
          message: description,
          type: 'error',
          duration: 10000
      })
    }

    return {
      onClickManageAccount, 
      onClickLogout,
      onResetStorage,
      onClickLogo,
      user,
      resetStorageVisible,
      licenseExpiredVisible,
      onLicenseExpired 
    };
  }
};
</script>

<style>
#nav {
  padding: 20px;
  text-align: center;
}

#nav a {
  font-size: 14px;
}

.navbar-custom {
  background-color: #ffffff;
  border-bottom: 1px solid #dcdcdc;
}

.navbar-nav .nav-link {
  color: #2c3e50;
}

.navbar-nav .nav-link:hover {
  color: #00d1fd;
}

.navbar-nav .nav-item.active .nav-link {
  color: #00d1fd;
  font-weight: bold;
  border-top: 3px solid #00d1fd;
}

.dropdown-toggle {
  color: #2c3e50;
}

.dropdown-toggle:hover {
  color: #00d1fd;
  border: none;
  outline: none;
  text-decoration: none;
}

.dropdown-menu {
  background-color: #ffffff;
}

.dropdown-item {
  color: #2c3e50;
}

.dropdown-item:hover {
  background-color: #f0f0f0 !important;
  color: #00d1fd !important;
}
</style>