<template>
  <!-- <div ref="canvas" id="scene-container"></div> -->
  <div ref="canvas" id="canvas"></div>
</template>

<script>
/* eslint-disable no-undef */
import { computed, ref, onMounted, onUnmounted, watch } from "vue";
import getUser from "../composables/getUser";
import { useRouter } from "vue-router";
import useWindowResize from '../use/useWindowResize.js';
import { 
  WebGLRenderer, 
  PerspectiveCamera, 
  OrthographicCamera, 
  Scene, 
  Mesh, 
  PlaneBufferGeometry, 
  BoxBufferGeometry, 
  SphereBufferGeometry, 
  MeshBasicMaterial, 
  MeshStandardMaterial, 
  Color, 
  DirectionalLight, 
  AmbientLight, 
  HemisphereLight,
  NumberKeyframeTrack,
  VectorKeyframeTrack,
  AnimationClip,
  AnimationMixer,
  Clock,
  Vector3,
  Vector2,
  Raycaster,
  LineBasicMaterial,
  BufferGeometry,
  Line,
  Object3D,
  TextureLoader,
  MeshPhongMaterial,
  ArrowHelper
  
  } from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';

export default {
  components: { },
  props: ['network'],
  setup(props, { emit }) {
    const network = computed({
      get: () => props.network,
      set: (value) => emit("update:network", value),
    });

    const { user } = getUser();
    const router = useRouter();
    const {width, height} = useWindowResize()
    const colourArray = ref([
      "#0080FF",
      "#C92519",
      "#FAE500",
      "#0EA7A5",
      "#FA7A35",
      "#50C878",
      "#FF007F",
      "#2142AB",
      "#367588",
      "#6C3082",
      "#708090",
      "#D1E231",
      "#FFDDF4",
      "#4D4141",
      "#FFFDD0",
      "#FF9B49",
      "#A020F0",
      "#93C572",
      "#367588",
      "#8E7F6F",
      "#54622E",
      "#99AEBB",
      "#A67C25",
      "#C5DAFF",
      "#4DD21D"
    ])

    const canvas = ref(null);
    const scene = new Scene();
    const raycaster = new Raycaster();
    const mouse = new Vector2();
    var group = new Object3D();
    const vector = ref(null);
    const leftArrow = ref(null);
    const rightArrow = ref(null);
    const topArrow = ref(null);
    const bottomArrow = ref(null);
    const screenRatio = ref(0.5)

    scene.background = new Color('#F2F2F2');

    const camera = new OrthographicCamera( width.value*screenRatio.value / - 2, width.value*screenRatio.value / 2, height.value*screenRatio.value / 2, height.value*screenRatio.value / - 2, 0.1, network.value.y + 1000 );
    camera.position.set(network.value.x/2, network.value.y/2, network.value.y + 100);

    const planeMaterial = new MeshBasicMaterial({
        color: (network.value.image ? 0xffffff : '#666666'),
        side: 2
    });

    var planeTexture = new TextureLoader().load(
      network.value.image,
        function () {
            planeMaterial.map = planeTexture
            planeMaterial.needsUpdate = true
            renderer.render(scene, camera);
        }
    );

    const planeGeo = new PlaneBufferGeometry(network.value.x, network.value.y, network.value.z, 1)
    const plane = new Mesh(planeGeo, planeMaterial);
    plane.position.set(network.value.x/2, network.value.y/2, 0)
    plane.callback = function(name, position, point) { }
    
    var fontLoader = new FontLoader();
    fontLoader.load("https://threejs.org/examples/fonts/helvetiker_regular.typeface.json", function(font) { 
            var  textGeo = new TextGeometry(String(network.value.x) + 'm', {
                    size: 0.25,
                    height: 0.02,
                    curveSegments: 6,
                    bevelThickness: 2,
                    bevelSize: 2,
                    // bevelEnabled: true,
                    font: font
            });
            var textMaterial = new MeshPhongMaterial( { color: 0x000000, specular: 0x000000 } );
            var text = new Mesh(textGeo , textMaterial);
            text.position.set((network.value.x/2) - 0.25, -0.3 - 0.125, 0);
            scene.add(text);
            renderer.render(scene, camera);
        })
        fontLoader.load("https://threejs.org/examples/fonts/helvetiker_regular.typeface.json", function(font) { 
            var  textGeo = new TextGeometry(String(network.value.y) + 'm', {
                    size: 0.25,
                    height: 0.02,
                    curveSegments: 6,
                    bevelThickness: 2,
                    bevelSize: 2,
                    // bevelEnabled: true,
                    font: font
            });
            var textMaterial = new MeshPhongMaterial( { color: 0x000000, specular: 0x000000 } );
            var text = new Mesh(textGeo , textMaterial);
            text.position.set(-0.3 - 0.25, (network.value.y/2) - 0.125, 0);
            scene.add(text);
            renderer.render(scene, camera);
        })

    // dosen't need if using MeshBasicMaterial
    const light = new DirectionalLight('white', 0.5);
    light.position.set(0, 0, 100);

    const ambientLight = new HemisphereLight(
      'white', // bright sky color
      'black', // dim ground color
      3, // intensity
    );

    const loader = new GLTFLoader();

    scene.add(plane, ambientLight, light);

    const renderer = new WebGLRenderer({ antialias: true })
    // turn on the physically correct lighting model
    renderer.physicallyCorrectLights = true;

    // Set the camera's aspect ratio
    camera.aspect = width.value*screenRatio.value / height.value*screenRatio.value;

    // update the camera's frustum
    camera.updateProjectionMatrix();

    // update the size of the renderer AND the canvas
    renderer.setSize(width.value*screenRatio.value, height.value*screenRatio.value);
    // Set the device pixel ratio
    renderer.setPixelRatio(window.devicePixelRatio);

    const onResize = () => {
      camera.aspect = (width.value*screenRatio.value) / (height.value*screenRatio.value);
      camera.updateProjectionMatrix();
      renderer.setSize(width.value*screenRatio.value, height.value*screenRatio.value);
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.render(scene, camera);
    }

    const onChange = () => {
      renderer.render(scene, camera);
    }
    
    function onDocumentMouseDown(event) {
      event.preventDefault();
      mouse.x = ( ( event.clientX - renderer.domElement.offsetLeft ) / renderer.domElement.clientWidth ) * 2 - 1;
      mouse.y = - ( ( event.clientY  - renderer.domElement.offsetTop ) / renderer.domElement.clientHeight ) * 2 + 1;
      raycaster.setFromCamera( mouse, camera );
      const intersects = raycaster.intersectObjects( scene.children );
      if ( intersects.length > 0 ) {
          intersects[0].object.callback(intersects[0].object.name, intersects[0].object.position, intersects[0].point);
      }
    }

    watch(user, () => {
      if (!user.value) {
        router.push({ name: "SignIn" });
      }
    });

    watch(network.value, () => {
      vector.value.rotation.set(0, 0, ((network.value.trueNorthOffset || 0)*(Math.PI/180)))
      renderer.render(scene, camera)
    });

    onMounted(async () => {
      canvas.value.appendChild(renderer.domElement)
      
      // OrbitControls
      const controls = new OrbitControls(camera, canvas.value);
      controls.addEventListener('change', onChange);
      // Center the Camera on plane
      controls.target.copy(plane.position);
      controls.update();    

      // Add Anchor
        loader.load("/compass.glb", gltf => {
          vector.value = gltf.scene
          var side = (network.value.x > network.value.y) ? network.value.y : network.value.x
          vector.value.scale.set(side/4,side/4,side/4)
          vector.value.position.set(network.value.x/2, network.value.y/2, 0.2)
          scene.add(gltf.scene);
          renderer.render(scene, camera);
        });

        // const dir = new Vector3( 3, 0, 0 );
        // //normalize the direction vector (convert to vector of length 1)
        // dir.normalize();
        // const origin = new Vector3( 0, -0.3, 0.2 );
        // const length = 3;
        // const hex = 0x000000;
        // const arrowHelper = new ArrowHelper( dir, origin, length, hex );
        // scene.add( arrowHelper );

        loader.load("/arrow.glb", gltf => {
          leftArrow.value = gltf.scene
          leftArrow.value.scale.set(network.value.x/3,network.value.x/3,network.value.x/3)
          leftArrow.value.rotation.set(0, 0, ((180 || 0)*(Math.PI/180)))
          leftArrow.value.position.set(network.value.x/3, -0.3, 0)
          scene.add(gltf.scene);
          renderer.render(scene, camera);
        });

        loader.load("/arrow.glb", gltf => {
          rightArrow.value = gltf.scene
          rightArrow.value.scale.set(network.value.x/3,network.value.x/3,network.value.x/3)
          rightArrow.value.position.set(network.value.x/3*2, -0.3, 0)
          scene.add(gltf.scene);
          renderer.render(scene, camera);
        });

        loader.load("/arrow.glb", gltf => {
          topArrow.value = gltf.scene
          topArrow.value.scale.set(network.value.y/3,network.value.y/3,network.value.y/3)
          topArrow.value.rotation.set(0, 0, ((90 || 0)*(Math.PI/180)))
          topArrow.value.position.set(-0.3, network.value.y/3*2, 0)
          scene.add(gltf.scene);
          renderer.render(scene, camera);
        });

        loader.load("/arrow.glb", gltf => {
          bottomArrow.value = gltf.scene
          bottomArrow.value.scale.set(network.value.y/3,network.value.y/3,network.value.y/3)
          bottomArrow.value.rotation.set(0, 0, ((270 || 0)*(Math.PI/180)))
          bottomArrow.value.position.set(-0.3, network.value.y/3, 0)
          scene.add(gltf.scene);
          renderer.render(scene, camera);
        });
      
      // Fit Orthographic Camera to working space
      // center camera on the object (ellipse in this case)
      var bounding = plane.geometry.boundingSphere
      // aspect equals window.innerWidth / window.innerHeight
      if ( width.value/height.value > 1.0 ) {
          // if view is wider than it is tall, zoom to fit height
          camera.zoom = renderer.domElement.clientHeight / ( bounding.radius * 2 )
      }
      else {
          // if view is taller than it is wide, zoom to fit width
          camera.zoom = renderer.domElement.clientWidth  / ( bounding.radius * 2 )
      }

      camera.updateProjectionMatrix();
      renderer.render(scene, camera)
      
      window.addEventListener('resize', onResize);
      canvas.value.addEventListener('click', onDocumentMouseDown, false);
    })

    onUnmounted(() => {
      window.removeEventListener("resize", onResize);
    });

    return {
      canvas
    };
  }
};
</script>

<style>
</style>
