<template>
  <Navbar v-model:showNavbarLinks="showNavbarLinks"/>

  <!-- Modal Views - Start -->
  <el-dialog v-model="deleteNetworkVisible" title="Warning" width="30%">
    <span>Are you sure you want to delete this Network?</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="deleteNetworkVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onDeleteNetwork()">Confirm</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="editDialogVisible" title="Edit" width="30%">
    <el-form
      label-position="left"
      label-width="120px"
      style="max-width: 460px"
    >
      <el-form-item label="Name">
        {{ networkToEdit.name }}
      </el-form-item>
      <el-form-item label="X">
        <el-input-number v-model.number="networkToEdit.x"/>
      </el-form-item>
      <el-form-item label="Y">
        <el-input-number v-model.number="networkToEdit.y"/>
      </el-form-item>
      <el-form-item label="Z">
        <el-input-number v-model.number="networkToEdit.z"/>
      </el-form-item>
      <el-form-item class="mt-5" label="Map (optional)">
        <input ref="fileInput" class="form-control-file" style="display: none" type="file" accept=".jpg,.jpeg,.png" @change="onFileSelected">
        <el-button type="primary" @click="fileInput.click()">Choose File</el-button>
      </el-form-item>
      <el-form-item v-if="imageView">
        <img :src="imageView" class="rounded d-block" style="height: 120px;">
      </el-form-item>
      <el-form-item v-if="selectedFile">
        <p>{{ imageName }}</p>
      </el-form-item>
      <el-form-item v-if="imageView">
        <el-button type="primary" text @click="onRemoveFile">Remove File</el-button>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onEditNetwork">Update</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="licenseExpiredVisible" title="License Expired" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width="30%">
    <span>Your license has expired. Please contact IOSEA support team.</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onLicenseExpired()">Ok</el-button
        >
      </span>
    </template>
  </el-dialog>
  <!-- Modal Views - End -->

  <div style="container-fluid; min-height: 78vh;">
    <div class="container my-4">
      <div class="row justify-content-md-center">
        <div class="justify-content-center"> 
          <br/>
          <el-button type="primary" @click="onAddNetwork">Add a new working space</el-button>
        </div>
      </div>
    </div>
    <div class="customContainer my-5" v-if="networks.length">
      <table class="table table-sm">
        <thead class="thead-light">
          <tr>
            <th scope="col">Working Space</th>
            <th scope="col">X</th>
            <th scope="col">Y</th>
            <th scope="col">Z</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(network, index) in networks">
            <tr>
              <td class="align-middle">{{ network.name }}</td>
              <td class="align-middle">{{ Number(network.dimensions[0]).toFixed(2) }}</td>
              <td class="align-middle">{{ Number(network.dimensions[1]).toFixed(2) }}</td>
              <td class="align-middle">{{ Number(network.dimensions[2]).toFixed(2) }}</td>
              <td>
                <el-button type="primary" @click="onSelectNetwork(index)">Select</el-button>
                <el-button type="primary" @click="onClickEdit(index)">Edit</el-button>
                <el-button type="danger" @click="deleteNetworkVisible = true; networkToDelete = network.id">Delete</el-button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
  <footer class="footer mt-auto py-4">
    <div style="font-size: 14px;">Copyright © 2024 IOSEA Inc. All rights reserved.</div>
    <div>
      <p style="font-size: 13px;">Version 2.4.3 {{ apiVersion }}</p>
    </div>
  </footer>
</template>

<script>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import getUser from "../composables/getUser";
import useLogout from "../composables/useLogout";
import getNetworks from '../composables/getNetworks';
import getAPIVersion from '../composables/getAPIVersion';
import Navbar from "../components/Navbar.vue";
import axios from "axios";
import { ElNotification } from 'element-plus';

export default {
  components: { Navbar },
  setup() {
    const showNavbarLinks = ref(true);
    const { user } = getUser();
    const { logout, logoutError } = useLogout();
    const router = useRouter();
    const network = ref({id: null, name: null, x: null, y: null, z: null, ip: null, port: null});
    const protocol = ref(window.localStorage.getItem("protocol"));
    const ep = ref(window.localStorage.getItem("endpoint"));
    const port = ref(window.localStorage.getItem("port"));
    const key = ref(window.localStorage.getItem("key"));
    const headers = { 'Authorization': 'Bearer ' + key.value };
    const deleteNetworkVisible = ref(false);
    const networkToDelete = ref(null);
    const editDialogVisible = ref(false);
    const networkToEdit = ref({id: null, name: null, x: null, y: null, z: null, image: ""});
    const fileInput = ref(null);
    const selectedFile = ref(null);
    const imageView = ref(null);
    const imageName = ref("No File Selected");
    const licenseExpiredVisible = ref(false);

    if (process.env.VUE_APP_API_PORT) {
        port.value = process.env.VUE_APP_API_PORT
    }

    const { apiVersion, loadAPIVersion } = getAPIVersion()
     loadAPIVersion()

    const { networks, networksError, haveNetworksLoaded, loadNetworks } = getNetworks()
    loadNetworks()

    const onAddNetwork = () => {
      var networkNames = networks.value.map(function(network) {
        return network.name;
      })
      
      router.push({ name: "AddWorkingSpace" })
    }

    const onSelectNetwork = (index) => {
        router.push({ name: "Tracking", params: { id: networks.value[index].id } })
    }

    const onClickEdit = (index) => {
      networkToEdit.value = {
        id: networks.value[index].id,
        name: networks.value[index].name, 
        x: Number(networks.value[index].dimensions[0].toFixed(2)), 
        y: Number(networks.value[index].dimensions[1].toFixed(2)),
        z: Number(networks.value[index].dimensions[2].toFixed(2)),
        image: networks.value[index].image
      }
      if (networks.value[index].image) {
        imageView.value = networks.value[index].image;
      }
      
      editDialogVisible.value = true
    }
    
    const onEditNetwork = () => {
      if (!networkToEdit.value.x) {
          onWarning("Please fill the x field");
          return;
      }
      if (!networkToEdit.value.y) {
          onWarning("Please fill the y fields");
          return;
      }
      if (!networkToEdit.value.z) {
          onWarning("Please fill the z fields");
          return;
      }
      
      if (networkToEdit.value.x && networkToEdit.value.y && networkToEdit.value.z) {
        var networkObj = {
          setup: {
            id: networkToEdit.value.id,
            name: networkToEdit.value.name,
            dimensions: [networkToEdit.value.x, networkToEdit.value.y, networkToEdit.value.z],
            image: networkToEdit.value.image
          }
        }
        axios.put(protocol.value + ep.value + port.value + '/network', networkObj, { headers })
        .then((res) => {
        if (res.status == 200) {
            onSuccess('Network updated')

            // Find the network index and update the network list
            const index = networks.value.findIndex((network, index) => {
              if (network.name === networkToEdit.value.name) {
                  return true
                }
              })
            if (index != -1) {
              networks.value[index] = {
                id: networkToEdit.value.id,
                name: networkToEdit.value.name, 
                dimensions: [networkToEdit.value.x, networkToEdit.value.y, networkToEdit.value.z], 
                image: networkToEdit.value.image
              }
            }
            editDialogVisible.value = false
        }
        })
        .catch((error) => {
          onError('Failed to update network')
          console.log(error)
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
      }
    }

    const onFileSelected = (event) => {
      if (event.target.files[0].size > 2000000) {
        onError("The size limit for image is 2MB.")
      }
      else {
        selectedFile.value = event.target.files[0]
        loadImageView()
        imageName.value = selectedFile.value.name
      }
    }

    const loadImageView = () => {
        if (FileReader && selectedFile.value) {
        var fr = new FileReader();
        fr.onload = function () {
            imageView.value = fr.result;
            networkToEdit.value.image = fr.result;
        }
        fr.readAsDataURL(selectedFile.value);
      }
    }

    const onRemoveFile = () => {
      selectedFile.value = null
      imageView.value = null
      networkToEdit.value.image = ""
    }

    const onDeleteNetwork = () => {
      deleteNetworkVisible.value = false
      axios.delete(protocol.value + ep.value + port.value + "/network/" + networkToDelete.value, { headers })
        .then((res) => {
          if (res.status == 200) {
            onSuccess('Network deleted')
            const index = networks.value.findIndex((network, index) => {
              if (network.id === networkToDelete.value) {
                  return true
                  }
              })
            if (index != -1) {
                networks.value.splice(index, 1)
                networkToDelete.value = null
            }
          }
        })
        .catch((error) => {
          onError('Failed to delete network')
          console.error(error)
          networkToDelete.value = null
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
    }

    const onLicenseExpired = async () => {
      await logout();
    }

    const onSuccess = (description) => {
      ElNotification({
        title: 'Success',
        message: description,
        type: 'success',
        duration: 5000
      })
    }

    const onWarning = (description) => {
      ElNotification({
        title: 'Warning',
        message: description,
        type: 'warning',
        duration: 5000
      })
    }

    const onError = (description) => {
      ElNotification({
        title: 'Error',
        message: description,
        type: 'error',
        duration: 10000
      })
    }

    watch(user, () => {
      if (!user.value) {
        router.push({ name: "SignIn" })
      }
    });

    watch(networksError, (currentError) => {
      if (currentError == 444) {
        licenseExpiredVisible.value = true
      }
    });

    return { 
      showNavbarLinks, 
      network, 
      onAddNetwork, 
      networks, 
      networksError, 
      haveNetworksLoaded, 
      onSelectNetwork, 
      onDeleteNetwork,
      deleteNetworkVisible,
      networkToDelete,
      apiVersion,
      networkToEdit,
      editDialogVisible,
      onClickEdit,
      onEditNetwork,
      onFileSelected,
      imageView,
      imageName,
      selectedFile,
      fileInput,
      onRemoveFile,
      licenseExpiredVisible,
      onLicenseExpired  
    }
 }
}
</script>

<style>
</style>