<template>
    <!-- Modal Views - Start -->
    <el-dialog v-model="resetSettingsVisible" title="Warning" width="30%">
        <span>This process will revert all the settings back to the default values. Do you want to continue?</span>
        <template #footer>
        <span class="dialog-footer">
            <el-button @click="resetSettingsVisible = false">Cancel</el-button>
            <el-button type="primary" @click="onResetSettings()"
            >Confirm</el-button
            >
        </span>
        </template>
    </el-dialog>

    <el-dialog v-model="clearCacheVisible" title="Warning" width="30%">
        <span>This process will erase temporary cache. Do you want to continue?</span>
        <template #footer>
        <span class="dialog-footer">
            <el-button @click="clearCacheVisible = false">Cancel</el-button>
            <el-button type="primary" @click="onClearCache()"
            >Confirm</el-button
            >
        </span>
        </template>
    </el-dialog>

    <el-dialog v-model="licenseExpiredVisible" title="License Expired" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width="30%">
        <span>Your license has expired. Please contact IOSEA support team.</span>
        <template #footer>
        <span class="dialog-footer">
            <el-button type="primary" @click="onLicenseExpired()">Ok</el-button
            >
        </span>
        </template>
    </el-dialog>
    <!-- Modal Views - End -->

    <div class="container" style="container-fluid; min-height: 75vh;">
        <div v-if="presetSettings" class="my-4">
            <div>
                <el-radio-group v-model="presetSettingsradio" @change="onChangePresetSettings">
                    <el-radio :label="1">Default</el-radio>
                    <el-radio :label="2">Asset Tracking</el-radio>
                    <el-radio :label="3">RTLS</el-radio>
                    <el-radio :label="4">Multi-Locators</el-radio>
                </el-radio-group>
            </div>
            <div class="mt-2" style="font-size: small">
                    {{ presetSettingsDescription }}
            </div>
            <div class="mt-4">
                <el-button type="primary" @click="onSavePresetSettings">Save</el-button>
            </div>
            <br/>
            <el-divider />
        </div>
        <div v-if="!presetSettings" class="demo-collapse mb-4">
            <el-collapse accordion>
                <el-collapse-item v-if="settingsFullAccess || aoaSettingAccess" title="AoA Settings" name="1">
                    <div class="form-row">
                        <div class="row justify-content-md-left col-md-11"> 
                            <div class="form-group col-md-2">
                                <span class="lb-sm">deltaHorizon</span>
                                <el-input-number v-model.number="aoaSettings.deltahorizon"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">enableHorizonFilter</span>
                                <el-input-number v-model.number="aoaSettings.enablehorizonfilter"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">coreCount</span>
                                <el-input-number v-model.number="aoaSettings.corecount"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">enableAggregator</span>
                                <el-input-number v-model.number="aoaSettings.enableaggregator"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">fovMode</span>
                                <el-input-number v-model.number="aoaSettings.fovmode"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="row justify-content-md-left col-md-11">
                            <div class="form-group col-md-2">
                                <span class="lb-sm">PANormalize</span>
                                <el-input-number v-model.number="aoaSettings.panormalize"/>
                            </div> 
                            <div class="form-group col-md-2">
                                <span class="lb-sm">miniAllowableFOMNorm</span>
                                <el-input-number v-model.number="aoaSettings.miniallowablefomnorm"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">miniAllowableFOMNonnorm</span>
                                <el-input-number v-model.number="aoaSettings.miniallowablefomnonnorm"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">activateFOMNorm</span>
                                <el-input-number v-model.number="aoaSettings.activatefomnorm"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">debugMode</span>
                                <el-input-number v-model.number="aoaSettings.debugmode"/>
                            </div>
                        </div>
                        <div class="justify-content-center col-md-1"> 
                            <br/>
                            <el-button type="primary" @click="onSaveAOA">Save</el-button>
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item v-if="settingsFullAccess || rtlsSettingAccess" title="RTLS Settings" name="2">
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11">
                            <div class="form-group col-md-2">
                                <span class="lb-sm">normalizeWeight</span>
                                <el-input-number v-model.number="rtlsSettings.normalizeweightinstance"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">floorDetection</span>
                                <el-input-number v-model.number="rtlsSettings.floordetection"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">height</span>
                                <el-input-number v-model.number="rtlsSettings.height"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">consolidate</span>
                                <el-input-number v-model.number="rtlsSettings.consolidate"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">fomExponent</span>
                                <el-input-number v-model.number="rtlsSettings.fomexponent"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11"> 
                            <div class="form-group col-md-2">
                                <span class="lb-sm">crossSection</span>
                                <el-input-number v-model.number="rtlsSettings.crosssection"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">rssiExponent</span>
                                <el-input-number v-model.number="rtlsSettings.rssiexponent"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">qualityWeightLow</span>
                                <el-input-number v-model.number="rtlsSettings.qualityweightlow"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">qualityWeightMid</span>
                                <el-input-number v-model.number="rtlsSettings.qualityweightmid"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">qualityWeightHigh</span>
                                <el-input-number v-model.number="rtlsSettings.qualityweighthigh"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11"> 
                            <div class="form-group col-md-2">
                                <span class="lb-sm">stationaryDetection</span>
                                <el-input-number v-model.number="rtlsSettings.stationarydetection"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">angularThreshold</span>
                                <el-input-number v-model.number="rtlsSettings.angularthreshold"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">gearZeroThreshold</span>
                                <el-input-number v-model.number="rtlsSettings.gearzerothreshold"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">gearOneThreshold</span>
                                <el-input-number v-model.number="rtlsSettings.gearonethreshold"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">gearZeroCount</span>
                                <el-input-number v-model.number="rtlsSettings.gearzerocount"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11"> 
                            <div class="form-group col-md-2">
                                <span class="lb-sm">gearOneCount</span>
                                <el-input-number v-model.number="rtlsSettings.gearonecount"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">gearTwoCount</span>
                                <el-input-number v-model.number="rtlsSettings.geartwocount"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">noiseaxm</span>
                                <el-input-number v-model.number="rtlsSettings.noiseaxm"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">noiseaym</span>
                                <el-input-number v-model.number="rtlsSettings.noiseaym"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">noiseazm</span>
                                <el-input-number v-model.number="rtlsSettings.noiseazm"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11"> 
                            <div class="form-group col-md-2">
                                <span class="lb-sm">noiseaxZeroGear</span>
                                <el-input-number v-model.number="rtlsSettings.noiseaxzerogear"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">noiseayZeroGear</span>
                                <el-input-number v-model.number="rtlsSettings.noiseayzerogear"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">noiseazZeroGear</span>
                                <el-input-number v-model.number="rtlsSettings.noiseazzerogear"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">noiseaxOneGear</span>
                                <el-input-number v-model.number="rtlsSettings.noiseaxonegear"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">noiseayOneGear</span>
                                <el-input-number v-model.number="rtlsSettings.noiseayonegear"/>
                            </div> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11">
                            <div class="form-group col-md-2">
                                <span class="lb-sm">noiseazOneGear</span>
                                <el-input-number v-model.number="rtlsSettings.noiseazonegear"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">noiseaxTwoGear</span>
                                <el-input-number v-model.number="rtlsSettings.noiseaxtwogear"/>
                            </div> 
                            <div class="form-group col-md-2">
                                <span class="lb-sm">noiseayTwoGear</span>
                                <el-input-number v-model.number="rtlsSettings.noiseaytwogear"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">noiseazTwoGear</span>
                                <el-input-number v-model.number="rtlsSettings.noiseaztwogear"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">modeRTLS</span>
                                <el-input-number v-model.number="rtlsSettings.modertls"/>
                            </div> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11">
                            <div class="form-group col-md-2">
                                <span class="lb-sm">activateHoldon</span>
                                <el-input-number v-model.number="rtlsSettings.activateholdon"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">maxAllowableDistance3d</span>
                                <el-input-number v-model.number="rtlsSettings.maximumallowabledistance3d"/>
                            </div> 
                            <div class="form-group col-md-2">
                                <span class="lb-sm">maxAllowableRadius3d</span>
                                <el-input-number v-model.number="rtlsSettings.maximumallowableradius3d"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">maxAllowableRadius2d</span>
                                <el-input-number v-model.number="rtlsSettings.maximumallowableradius2d"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">continues3dMode</span>
                                <el-input-number v-model.number="rtlsSettings.continues3dmode"/>
                            </div> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11">
                            <div class="form-group col-md-2">
                                <span class="lb-sm">zMin</span>
                                <el-input-number v-model.number="rtlsSettings.zmin"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">zMax</span>
                                <el-input-number v-model.number="rtlsSettings.zmax"/>
                            </div> 
                            <div class="form-group col-md-2">
                                <span class="lb-sm">zStep</span>
                                <el-input-number v-model.number="rtlsSettings.zstep"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">minAllowableFOM2D</span>
                                <el-input-number v-model.number="rtlsSettings.minimumallowablefom2d"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">minAllowableFOM3D</span>
                                <el-input-number v-model.number="rtlsSettings.minimumallowablefom3d"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11">
                            <div class="form-group col-md-2">
                                <span class="lb-sm">weightedAveRadius</span>
                                <el-input-number v-model.number="rtlsSettings.weightedaveradius"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">maxCoverage</span>
                                <el-input-number v-model.number="rtlsSettings.maxcoverage"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">minAllowableFOMModeZero</span>
                                <el-input-number v-model.number="rtlsSettings.minimumallowablefommodezero"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">processNoise</span>
                                <el-input-number v-model.number="rtlsSettings.processnoise"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">initialPVelocity</span>
                                <el-input-number v-model.number="rtlsSettings.initialpvelocity"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11">
                            <div class="form-group col-md-2">
                                <span class="lb-sm">initialPPosition</span>
                                <el-input-number v-model.number="rtlsSettings.initialpposition"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">modeKalman</span>
                                <el-input-number v-model.number="rtlsSettings.modekalman"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">initialAcceleration</span>
                                <el-input-number v-model.number="rtlsSettings.initialacceleration"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">isRadiusActivated</span>
                                <el-input-number v-model.number="rtlsSettings.isradiusactivated"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">maxRadius</span>
                                <el-input-number v-model.number="rtlsSettings.maxradius"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11">
                            <div class="form-group col-md-2">
                                <span class="lb-sm">nElement</span>
                                <el-input-number v-model.number="rtlsSettings.nelement"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">minPointsHighXYZMode0Ratio</span>
                                <el-input-number v-model.number="rtlsSettings.minimumpointshighxyzmode0ratio"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">epsilonHighXYZMode0</span>
                                <el-input-number v-model.number="rtlsSettings.epsilonhighxyzmode0"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">minPointsHighXYZFinalRatio</span>
                                <el-input-number v-model.number="rtlsSettings.minimumpointshighxyzfinalratio"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">epsilonHighXYZFinal</span>
                                <el-input-number v-model.number="rtlsSettings.epsilonhighxyzfinal"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11">
                            <div class="form-group col-md-2">
                                <span class="lb-sm">locationQueueMaxSize</span>
                                <el-input-number v-model.number="rtlsSettings.locationqueuemaxsize"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">maxTimeInterval</span>
                                <el-input-number v-model.number="rtlsSettings.maxtimeinterval"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">maxAllowableMode2Meter</span>
                                <el-input-number v-model.number="rtlsSettings.maxallowablemode2meter"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">maxSteepAngleLocal</span>
                                <el-input-number v-model.number="rtlsSettings.maxsteepanglelocal"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">maxSteepAngleGlobal</span>
                                <el-input-number v-model.number="rtlsSettings.maxsteepangleglobal"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11">
                            <div class="form-group col-md-2">
                                <span class="lb-sm">timeWindow</span>
                                <el-input-number v-model.number="rtlsSettings.timewindow"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">minMinPointsHighXYZFinalRatio</span>
                                <el-input-number v-model.number="rtlsSettings.minimumminimumpointshighxyzfinalratio"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">enableWeight2d</span>
                                <el-input-number v-model.number="rtlsSettings.enableweight2d"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">analytics</span>
                                <el-input-number v-model.number="rtlsSettings.analytics"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">timeHistoryWindow</span>
                                <el-input-number v-model.number="rtlsSettings.timehistorywindow"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row justify-content-md-left col-md-11">
                            <div class="form-group col-md-2">
                                <span class="lb-sm">displayRay</span>
                                <el-input-number v-model.number="rtlsSettings.displayray"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">debugMode</span>
                                <el-input-number v-model.number="rtlsSettings.debugmode"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">velocityThreshold</span>
                                <el-input-number v-model.number="rtlsSettings.velocitythreshold"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">ctrHoldonLockThreshold</span>
                                <el-input-number v-model.number="rtlsSettings.counterholdonlockingthreshold"/>
                            </div>
                            <div class="form-group col-md-2">
                                <span class="lb-sm">ctrHoldonUnlockThreshold</span>
                                <el-input-number v-model.number="rtlsSettings.counterholdonunlockingthreshold"/>
                            </div>
                        </div>
                        <div class="justify-content-center col-md-1">
                            <br/>
                            <el-button type="primary" @click="onSaveRTLS">Save</el-button>
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item v-if="settingsFullAccess || generalSettingAccess" title="General Settings" name="3">
                    <div class="row">
                        <div class="row justify-content-md-left col-sm-11">
                            <div class="form-group col-md-2">
                                <span class="lb-sm">fir2count</span>
                                <el-input-number v-model.number="generalSettings.fir2count"/>
                            </div>
                        </div>
                        <div class="justify-content-center col-md-1">
                            <br/>
                            <el-button type="primary" @click="onSaveGeneral">Save</el-button>
                        </div>
                    </div>
                </el-collapse-item>
                <el-collapse-item v-if="settingsFullAccess || clientStreamSettingAccess" title="Client Stream Settings" name="4">
                     <div class="row">
                         <div class="row justify-content-md-left col-sm-11">
                             <div class="form-group col-md-2">
                                 <span class="lb-sm">streamInterval</span>
                                 <el-input-number v-model.number="clientStreamSettings.streaminterval"/>
                             </div>
                             <div class="form-group col-md-2">
                                 <span class="lb-sm">streamIntervalAll</span>
                                 <el-input-number v-model.number="clientStreamSettings.streamintervalall"/>
                             </div>
                         </div>
                         <div class="justify-content-center col-md-1">
                             <br/>
                             <el-button type="primary" @click="onSaveClientStream">Save</el-button>
                         </div>
                     </div>
                 </el-collapse-item>
            </el-collapse>
        </div>
        <div class="justify-content-center mb-4">
            <br/>
            <!-- <el-button type="primary" @click="resetSettingsVisible = true">Default Settings</el-button> -->
            <div v-if="!presetSettings" class="btn-group mr-4" role="group">
                <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span v-show="loadingStatus" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Preset Settings
                </button>
                <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                    <a class="dropdown-item" @click="onResetSettings(); loadingStatus = true">Default</a>
                    <a class="dropdown-item" @click="setAssetTrackingSettings(); loadingStatus = true">Asset Tracking</a>
                    <a class="dropdown-item" @click="setRTLSSettings(); loadingStatus = true">RTLS</a>
                    <a class="dropdown-item" @click="setMultiLocatorsSettings(); loadingStatus = true">Multi-Locators</a>
                </div>
            </div>
            <el-button v-if="!presetSettings" type="primary" class="mr-4" @click="clearCacheVisible = true">Clear Cache</el-button>
            <el-button v-if="presetSettings" type="primary" @click="clearCacheVisible = true">Clear Cache</el-button>
            <el-button v-if="!presetSettings" type="primary" class="mr-4" @click="exportSettings()">Export Settings</el-button>
            <input ref="fileInput" class="form-control-file" style="display: none" type="file" accept=".json" @change="onFileSelected">
            <el-button v-if="!presetSettings" type="primary" @click="fileInput.click()">Import Settings</el-button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import useLogout from "../composables/useLogout";
import axios from "axios";
import { ElNotification } from 'element-plus';
import getNetworkDetails from '../composables/getNetworkDetails';

export default {
    props: [
        'networkId',
        'aoaSettings',
        'rtlsSettings', 
        'generalSettings',
        'clientStreamSettings'
    ],
    setup(props) {
        const { logout, logoutError } = useLogout();
        const networkId = ref(props.networkId);
        const aoaSettings = ref(props.aoaSettings);
        const rtlsSettings = ref(props.rtlsSettings);
        const generalSettings = ref(props.generalSettings);
        const clientStreamSettings = ref(props.clientStreamSettings);
        const protocol = ref(window.localStorage.getItem("protocol"));
        const ep = ref(window.localStorage.getItem("endpoint"));
        const port = ref(window.localStorage.getItem("port"));
        const key = ref(window.localStorage.getItem("key"));
        const headers = { 'Authorization': 'Bearer ' + key.value };
        const settingsFullAccess = ref(window.localStorage.getItem("settingsFullAccess") == 'true' ? true : false);
        const aoaSettingAccess = ref(window.localStorage.getItem("aoaSetting") == 'true' ? true : false);
        const rtlsSettingAccess = ref(window.localStorage.getItem("rtlsSetting") == 'true' ? true : false);
        const generalSettingAccess = ref(window.localStorage.getItem("generalSetting") == 'true' ? true : false);
        const clientStreamSettingAccess = ref(window.localStorage.getItem("clientStreamSetting") == 'true' ? true : false);
        const presetSettings = ref(window.localStorage.getItem("presetSettings") == 'true' ? true : false);
        const resetSettingsVisible = ref(false);
        const clearCacheVisible = ref(false);
        const presetSettingsradio = ref(1);
        const presetSettingsDescription = ref("Settings configuration for out of the box performance. Best for the initial system validation.");
        const loadingStatus = ref(false);
        const fileInput = ref(null);
        const selectedFile = ref(null);
        const licenseExpiredVisible = ref(false);

        if (process.env.VUE_APP_API_PORT) {
            port.value = process.env.VUE_APP_API_PORT
        }

        const onSaveAOA = () => {
            putSettings({
                setup: {
                    id: networkId.value
                }, 
                aoasettings: {
                    network: networkId.value,
                    deltahorizon: aoaSettings.value.deltahorizon, 
                    enablehorizonfilter: aoaSettings.value.enablehorizonfilter,
                    corecount: aoaSettings.value.corecount,
                    enableaggregator: aoaSettings.value.enableaggregator,
                    fovmode: aoaSettings.value.fovmode,
                    panormalize: aoaSettings.value.panormalize,
                    miniallowablefomnorm: aoaSettings.value.miniallowablefomnorm,
                    miniallowablefomnonnorm: aoaSettings.value.miniallowablefomnonnorm,
                    activatefomnorm: aoaSettings.value.activatefomnorm,
                    debugmode: aoaSettings.value.debugmode
                }
            })
        }

        const onSaveRTLS = () => {
            putSettings({
                setup: {
                    id: networkId.value
                },
                rtlssettings: {
                    network: networkId.value, 
                    normalizeweightinstance: rtlsSettings.value.normalizeweightinstance, 
                    floordetection: rtlsSettings.value.floordetection, 
                    height: rtlsSettings.value.height, 
                    consolidate: rtlsSettings.value.consolidate, 
                    fomexponent: rtlsSettings.value.fomexponent,
                    crosssection: rtlsSettings.value.crosssection,
                    rssiexponent: rtlsSettings.value.rssiexponent,
                    qualityweightlow: rtlsSettings.value.qualityweightlow,
                    qualityweightmid: rtlsSettings.value.qualityweightmid,
                    qualityweighthigh: rtlsSettings.value.qualityweighthigh,
                    stationarydetection: rtlsSettings.value.stationarydetection,
                    angularthreshold: rtlsSettings.value.angularthreshold,
                    gearzerothreshold: rtlsSettings.value.gearzerothreshold,
                    gearonethreshold: rtlsSettings.value.gearonethreshold,
                    gearzerocount: rtlsSettings.value.gearzerocount,
                    gearonecount: rtlsSettings.value.gearonecount,
                    geartwocount: rtlsSettings.value.geartwocount,
                    noiseaxm: rtlsSettings.value.noiseaxm,
                    noiseaym: rtlsSettings.value.noiseaym,
                    noiseazm: rtlsSettings.value.noiseazm,
                    noiseaxzerogear: rtlsSettings.value.noiseaxzerogear,
                    noiseayzerogear: rtlsSettings.value.noiseayzerogear,
                    noiseazzerogear: rtlsSettings.value.noiseazzerogear,
                    noiseaxonegear: rtlsSettings.value.noiseaxonegear,
                    noiseayonegear: rtlsSettings.value.noiseayonegear,
                    noiseazonegear: rtlsSettings.value.noiseazonegear,
                    noiseaxtwogear: rtlsSettings.value.noiseaxtwogear,
                    noiseaytwogear: rtlsSettings.value.noiseaytwogear,
                    noiseaztwogear: rtlsSettings.value.noiseaztwogear,
                    modertls: rtlsSettings.value.modertls,
                    activateholdon: rtlsSettings.value.activateholdon,
                    maximumallowabledistance3d: rtlsSettings.value.maximumallowabledistance3d,
                    maximumallowableradius3d: rtlsSettings.value.maximumallowableradius3d,
                    maximumallowableradius2d: rtlsSettings.value.maximumallowableradius2d,
                    continues3dmode: rtlsSettings.value.continues3dmode,
                    zmin: rtlsSettings.value.zmin,
                    zmax: rtlsSettings.value.zmax,
                    zstep: rtlsSettings.value.zstep,
                    minimumallowablefom2d: rtlsSettings.value.minimumallowablefom2d,
                    minimumallowablefom3d: rtlsSettings.value.minimumallowablefom3d,
                    weightedaveradius: rtlsSettings.value.weightedaveradius,
                    maxcoverage: rtlsSettings.value.maxcoverage,
                    minimumallowablefommodezero: rtlsSettings.value.minimumallowablefommodezero,
                    processnoise: rtlsSettings.value.processnoise,
                    initialpvelocity: rtlsSettings.value.initialpvelocity,
                    initialpposition: rtlsSettings.value.initialpposition,
                    modekalman: rtlsSettings.value.modekalman,
                    initialacceleration: rtlsSettings.value.initialacceleration,
                    isradiusactivated: rtlsSettings.value.isradiusactivated,
                    maxradius: rtlsSettings.value.maxradius,
                    nelement: rtlsSettings.value.nelement,
                    minimumpointshighxyzmode0ratio: rtlsSettings.value.minimumpointshighxyzmode0ratio,
                    epsilonhighxyzmode0: rtlsSettings.value.epsilonhighxyzmode0,
                    minimumpointshighxyzfinalratio: rtlsSettings.value.minimumpointshighxyzfinalratio,
                    epsilonhighxyzfinal: rtlsSettings.value.epsilonhighxyzfinal,
                    locationqueuemaxsize: rtlsSettings.value.locationqueuemaxsize,
                    maxtimeinterval: rtlsSettings.value.maxtimeinterval,
                    maxallowablemode2meter: rtlsSettings.value.maxallowablemode2meter,
                    maxsteepanglelocal: rtlsSettings.value.maxsteepanglelocal,
                    maxsteepangleglobal: rtlsSettings.value.maxsteepangleglobal,
                    timewindow: rtlsSettings.value.timewindow,
                    minimumminimumpointshighxyzfinalratio: rtlsSettings.value.minimumminimumpointshighxyzfinalratio,
                    enableweight2d: rtlsSettings.value.enableweight2d,
                    analytics: rtlsSettings.value.analytics,
                    timehistorywindow: rtlsSettings.value.timehistorywindow,
                    displayray: rtlsSettings.value.displayray,
                    debugmode: rtlsSettings.value.debugmode,
                    velocitythreshold: rtlsSettings.value.velocitythreshold,
                    counterholdonlockingthreshold: rtlsSettings.value.counterholdonlockingthreshold,
                    counterholdonunlockingthreshold: rtlsSettings.value.counterholdonunlockingthreshold
                }
            })
        }

        const onSaveGeneral = () => {
            putSettings({
                setup: {
                    id: networkId.value
                },
                generalsettings: { 
                    network: networkId.value,
                    fir2count: generalSettings.value.fir2count
                }
            })
        }

        const onSaveClientStream = () => {
            putSettings({
                setup: {
                    id: networkId.value
                },
                clientstreamsettings: { 
                    network: networkId.value,
                    streaminterval: clientStreamSettings.value.streaminterval,
                    streamintervalall: clientStreamSettings.value.streamintervalall
                }
            })
        }

        const putSettings = (value) => {
            axios.put(protocol.value + ep.value + port.value + "/network", value, { headers })
            .then((res) => {
                if (res.status == 200) {
                    var data = res.data
                    aoaSettings.value = data.aoasettings
                    rtlsSettings.value = data.rtlssettings
                    generalSettings.value = data.generalsettings
                    clientStreamSettings.value = data.clientstreamsettings
                    onSuccess('Settings saved')
                    loadingStatus.value = false
                }
            })
            .catch((error) => {
                onError('Failed to save the settings')
                loadingStatus.value = false
                console.log(error)
                if (error.response.status == 444) {
                    licenseExpiredVisible.value = true
                }
            })
        }

        const onResetSettings = () => {
            resetSettingsVisible.value = false
            var networkObj = {
                id: networkId.value
            }
            axios.post(protocol.value + ep.value + port.value + "/settingsreset/", networkObj, { headers })
            .then((res) => {
            if (res.status == 200) {
                // Wait for 2 second and then fetch the settings
                var startTimer = setInterval(() => {
                    axios.get(protocol.value + ep.value + port.value + "/network/" + networkId.value, { headers })
                    .then((res) => {
                        if (res.status == 200) {
                            var data = res.data
                            aoaSettings.value = data.aoasettings
                            rtlsSettings.value = data.rtlssettings
                            generalSettings.value = data.generalsettings
                            clientStreamSettings.value = data.clientstreamsettings
                            onSuccess('Settings reset')
                            loadingStatus.value = false
                        }
                    })
                    .catch((error) => {
                        onError('Failed to reset the settings')
                        loadingStatus.value = false
                        console.log(error)
                        if (error.response.status == 444) {
                            licenseExpiredVisible.value = true
                        }
                    })
                  // Removev timer
                  clearInterval(startTimer)
                }, 2000)
            }
            })
            .catch((error) => {
                onError('Failed to reset the settings')
                loadingStatus.value = false
                console.log(error)
                if (error.response.status == 444) {
                    licenseExpiredVisible.value = true
                }
            })
        }

        const onClearCache = () => {
            clearCacheVisible.value = false
            var networkObj = {
                id: networkId.value
            }
            axios.post(protocol.value + ep.value + port.value + "/cachereset/", networkObj, { headers })
            .then((res) => {
            if (res.status == 200) {
                onSuccess('Cache cleared')
            }
            })
            .catch((error) => {
                onError('Failed to clear cache')
                console.log(error)
                if (error.response.status == 444) {
                    licenseExpiredVisible.value = true
                }
            })
        }

        const onChangePresetSettings = (newValue) => {
            switch(newValue) {
                case 1:
                    presetSettingsDescription.value = 'Settings configuration for out of the box performance. Best for the initial system validation.';
                    break;
                case 2:
                    presetSettingsDescription.value = 'Settings configuration for higher precision and lower refresh rate. Best for stationary asset tracking.';
                    break;
                case 3:
                    presetSettingsDescription.value = 'Settings configuration for higher precision real-time tracking. Best for moving object tracking.';
                    break;
                case 4:
                    presetSettingsDescription.value = 'Settings configuration for multiple locators setup. Best for increasing covered tracking area.';
                    break;
                default:
                    presetSettingsDescription.value = 'Settings configuration for out of the box performance. Best for the initial system validation.';
            }
        }

        const onSavePresetSettings = () => {
            switch(presetSettingsradio.value) {
                case 1:
                    onResetSettings();
                    break;
                case 2:
                    setAssetTrackingSettings();
                    break;
                case 3:
                    setRTLSSettings();
                    break;
                case 4:
                    setMultiLocatorsSettings();
                    break;
                default:
                    onResetSettings();
            }
        }

        const setAssetTrackingSettings = () => {
            putSettings({
                setup: {
                    id: networkId.value
                },
                generalsettings: { 
                    network: networkId.value,
                    fir2count: 96
                },
                rtlssettings: {
                    network: networkId.value,
                    normalizeweightinstance: 5,
                    floordetection: 0,
                    height: 0,
                    consolidate: 8,
                    fomexponent: 0,
                    crosssection: 2,
                    rssiexponent: 0,
                    qualityweightlow: 2,
                    qualityweightmid: 12,
                    qualityweighthigh: 2,
                    stationarydetection: 1,
                    angularthreshold: 8,
                    gearzerothreshold: 0.8,
                    gearonethreshold: 0.6,
                    gearzerocount: 100,
                    gearonecount: 30,
                    geartwocount: 10,
                    noiseaxm: 1,
                    noiseaym: 1,
                    noiseazm: 1,
                    noiseaxzerogear: 0.01,
                    noiseayzerogear: 0.01,
                    noiseazzerogear: 0.01,
                    noiseaxonegear: 10,
                    noiseayonegear: 10,
                    noiseazonegear: 10,
                    noiseaxtwogear: 50,
                    noiseaytwogear: 50,
                    noiseaztwogear: 50,
                    modertls: 0,
                    activateholdon: 1,
                    maximumallowabledistance3d: 0,
                    maximumallowableradius3d: 0.3,
                    maximumallowableradius2d: 0.5,
                    continues3dmode: 1,
                    zmin: 0,
                    zmax: 3,
                    zstep: 0.5,
                    minimumallowablefom2d: 10,
                    minimumallowablefom3d: 18,
                    weightedaveradius: 1,
                    maxcoverage: 4,
                    minimumallowablefommodezero: 13,
                    processnoise: 1000,
                    initialpvelocity: 1000,
                    initialpposition: 1,
                    modekalman: 1,
                    initialacceleration: 1,
                    isradiusactivated: 1,
                    maxradius: 2,
                    nelement: 24,
                    minimumpointshighxyzmode0ratio: 0.51,
                    epsilonhighxyzmode0: 4,
                    minimumpointshighxyzfinalratio: 0.51,
                    epsilonhighxyzfinal: 2,
                    locationqueuemaxsize: 8,
                    maxtimeinterval: 86400,
                    maxallowablemode2meter: 8,
                    maxsteepanglelocal: 102,
                    maxsteepangleglobal: 102,
                    timewindow: 3,
                    minimumminimumpointshighxyzfinalratio: 5,
                    enableweight2d: 1,
                    analytics: 0,
                    timehistorywindow: 30,
                    displayray: 1,
                    debugmode: 0,
                    velocitythreshold: 0.2,
                    counterholdonlockingthreshold: 24,
                    counterholdonunlockingthreshold: 8
                }
            })
        }

        const setRTLSSettings = () => {
            putSettings({
                setup: {
                    id: networkId.value
                },
                generalsettings: { 
                    network: networkId.value,
                    fir2count: 12
                },
                rtlssettings: {
                    network: networkId.value,
                    normalizeweightinstance: 5,
                    floordetection: 0,
                    height: 0.9,
                    consolidate: 4,
                    fomexponent: 0,
                    crosssection: 2,
                    rssiexponent: 0,
                    qualityweightlow: 2,
                    qualityweightmid: 8,
                    qualityweighthigh: 2,
                    stationarydetection: 1,
                    angularthreshold: 6,
                    gearzerothreshold: 0.8,
                    gearonethreshold: 0.6,
                    gearzerocount: 100,
                    gearonecount: 30,
                    geartwocount: 10,
                    noiseaxm: 1,
                    noiseaym: 1,
                    noiseazm: 1,
                    noiseaxzerogear: 0.01,
                    noiseayzerogear: 0.01,
                    noiseazzerogear: 0.01,
                    noiseaxonegear: 10,
                    noiseayonegear: 10,
                    noiseazonegear: 10,
                    noiseaxtwogear: 50,
                    noiseaytwogear: 50,
                    noiseaztwogear: 50,
                    modertls: 0,
                    activateholdon: 1,
                    maximumallowabledistance3d: 0,
                    maximumallowableradius3d: 0.3,
                    maximumallowableradius2d: 0.75,
                    continues3dmode: 1,
                    zmin: 0,
                    zmax: 3,
                    zstep: 0.5,
                    minimumallowablefom2d: 10,
                    minimumallowablefom3d: 18,
                    weightedaveradius: 1,
                    maxcoverage: 4,
                    minimumallowablefommodezero: 13,
                    processnoise: 1000,
                    initialpvelocity: 1000,
                    initialpposition: 1,
                    modekalman: 0,
                    initialacceleration: 1,
                    isradiusactivated: 1,
                    maxradius: 1,
                    nelement: 12,
                    minimumpointshighxyzmode0ratio: 0.51,
                    epsilonhighxyzmode0: 3,
                    minimumpointshighxyzfinalratio: 0.51,
                    epsilonhighxyzfinal: 1,
                    locationqueuemaxsize: 2,
                    maxtimeinterval: 86400,
                    maxallowablemode2meter: 10,
                    maxsteepanglelocal: 115,
                    maxsteepangleglobal: 102,
                    timewindow: 3,
                    minimumminimumpointshighxyzfinalratio: 5,
                    enableweight2d: 1,
                    analytics: 0,
                    timehistorywindow: 30,
                    displayray: 1,
                    debugmode: 0,
                    velocitythreshold: 0.2,
                    counterholdonlockingthreshold: 15,
                    counterholdonunlockingthreshold: 7
                }
            })
        }

        const setMultiLocatorsSettings = () => {
            putSettings({
                setup: {
                    id: networkId.value
                },
                generalsettings: { 
                    network: networkId.value,
                    fir2count: 90
                },
                rtlssettings: {
                    network: networkId.value,
                    normalizeweightinstance: 5,
                    floordetection: 0,
                    height: 0.5,
                    consolidate: 8,
                    fomexponent: 0,
                    crosssection: 2,
                    rssiexponent: 0,
                    qualityweightlow: 2,
                    qualityweightmid: 8,
                    qualityweighthigh: 2,
                    stationarydetection: 1,
                    angularthreshold: 8,
                    gearzerothreshold: 0.8,
                    gearonethreshold: 0.6,
                    gearzerocount: 100,
                    gearonecount: 30,
                    geartwocount: 10,
                    noiseaxm: 1,
                    noiseaym: 1,
                    noiseazm: 1,
                    noiseaxzerogear: 0.01,
                    noiseayzerogear: 0.01,
                    noiseazzerogear: 0.01,
                    noiseaxonegear: 1,
                    noiseayonegear: 1,
                    noiseazonegear: 1,
                    noiseaxtwogear: 10,
                    noiseaytwogear: 10,
                    noiseaztwogear: 10,
                    modertls: 0,
                    activateholdon: 0,
                    maximumallowabledistance3d: 0,
                    maximumallowableradius3d: 0.3,
                    maximumallowableradius2d: 0.5,
                    continues3dmode: 1,
                    zmin: 0,
                    zmax: 3,
                    zstep: 0.5,
                    minimumallowablefom2d: 10,
                    minimumallowablefom3d: 18,
                    weightedaveradius: 1,
                    maxcoverage: 4,
                    minimumallowablefommodezero: 15,
                    processnoise: 1000,
                    initialpvelocity: 1000,
                    initialpposition: 1,
                    modekalman: 0,
                    initialacceleration: 1,
                    isradiusactivated: 1,
                    maxradius: 1,
                    nelement: 12,
                    minimumpointshighxyzmode0ratio: 0.51,
                    epsilonhighxyzmode0: 4,
                    minimumpointshighxyzfinalratio: 0.51,
                    epsilonhighxyzfinal: 0.75,
                    locationqueuemaxsize: 4,
                    maxtimeinterval: 86400,
                    maxallowablemode2meter: 8,
                    maxsteepanglelocal: 125,
                    maxsteepangleglobal: 102,
                    timewindow: 3,
                    minimumminimumpointshighxyzfinalratio: 5,
                    enableweight2d: 1,
                    analytics: 0,
                    timehistorywindow: 30,
                    displayray: 1,
                    debugmode: 0,
                    velocitythreshold: 0.2,
                    counterholdonlockingthreshold: 15,
                    counterholdonunlockingthreshold: 7
                }
            })
        }

        const exportSettings = () => {
            const jsonData = {
                aoasettings: aoaSettings.value,
                rtlssettings: rtlsSettings.value,
                generalsettings: generalSettings.value,
                clientstreamSettings: clientStreamSettings.value
            };
            const data = JSON.stringify(jsonData, null, 2);
            const blob = new Blob([data], { type: 'application/json' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = networkId.value + '-settings-data.json';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        const onFileSelected = (event) => {
            if (event.target.files && event.target.files.length > 0) {
                if (event.target.files[0].size > 1000000) {
                    onError("The size limit for image is 1MB.");
                } 
                else {
                    selectedFile.value = event.target.files[0];
                    readFile();
                }
            }
        }
        const readFile = () => {
            const reader = new FileReader();
            reader.onload = () => {
                try {
                    const jsonData = JSON.parse(reader.result);
                    if (jsonData.aoasettings && jsonData.rtlssettings && jsonData.generalsettings && jsonData.clientstreamSettings) {
                        putSettings({
                            setup: {
                                id: networkId.value
                            },
                            aoasettings: jsonData.aoasettings,
                            rtlssettings: jsonData.rtlssettings,
                            generalsettings: jsonData.generalsettings,
                            clientstreamSettings: jsonData.clientstreamSettings
                        })
                    } 
                    else {
                        onError("Some settings are missing in the JSON file.");
                    }
                } catch (error) {
                    onError("Error parsing JSON file.");
                }
            };
            reader.readAsText(selectedFile.value);
        };

        const onLicenseExpired = async () => {
            await logout();
        }

        const onSuccess = (description) => {
            ElNotification({
                title: 'Success',
                message: description,
                type: 'success',
                duration: 5000
            })
        }

        const onError = (description) => {
            ElNotification({
                title: 'Error',
                message: description,
                type: 'error',
                duration: 10000
            })
        }

        onMounted(() => {
            if (generalSettings.value.fir2count == 12) {
                if (rtlsSettings.value.consolidate == 1) {
                    presetSettingsradio.value = 1
                    onChangePresetSettings(1)
                }
                else if (rtlsSettings.value.consolidate == 4) {
                    presetSettingsradio.value = 3
                    onChangePresetSettings(3)
                }
            }
            else if (generalSettings.value.fir2count == 90) {
                presetSettingsradio.value = 4
                onChangePresetSettings(4)
            }
            else if (generalSettings.value.fir2count == 96) {
                presetSettingsradio.value = 2
                onChangePresetSettings(2)
            }
        })

        return { 
            aoaSettings,
            rtlsSettings, 
            generalSettings,
            clientStreamSettings,
            onSaveAOA,
            onSaveClientStream,
            onSaveRTLS,
            onSaveGeneral,
            settingsFullAccess,
            aoaSettingAccess,
            rtlsSettingAccess,
            generalSettingAccess,
            clientStreamSettingAccess,
            resetSettingsVisible,
            clearCacheVisible,
            onResetSettings,
            onClearCache,
            presetSettingsradio,
            presetSettingsDescription,
            presetSettings,
            onChangePresetSettings,
            onSavePresetSettings,
            setAssetTrackingSettings,
            setRTLSSettings,
            setMultiLocatorsSettings,
            loadingStatus,
            exportSettings,
            fileInput,
            selectedFile,
            onFileSelected,
            licenseExpiredVisible,
            onLicenseExpired
        }
    }
}
</script>