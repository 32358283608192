<template>
  <Navbar v-model:showNavbarLinks="showNavbarLinks"/>

    <!-- Modal Views - Start -->
    <el-dialog v-model="licenseExpiredVisible" title="License Expired" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width="30%">
        <span>Your license has expired. Please contact IOSEA support team.</span>
        <template #footer>
        <span class="dialog-footer">
            <el-button type="primary" @click="onLicenseExpired()">Ok</el-button
            >
        </span>
        </template>
    </el-dialog>
    <!-- Modal Views - End -->

    <div style="container-fluid; min-height: 78vh;">
        <div class="container my-5 ">
            <div class="justify-content-center mb-4">
                <h2>Add a new working space</h2>
            </div>
            <div class="justify-content-center mb-5">
                <el-steps :active="active" finish-status="success" align-center >
                    <el-step title="Name" />
                    <el-step title="Map (optional)" />
                    <el-step title="Dimensions" />
                    <!-- <el-step title="True North" />
                    <el-step title="Magnetic Declination" /> -->
                </el-steps>
            </div>
            <div v-if="active == 0" class="row justify-content-md-center mb-3">
                <div class="form-group col-md-3">
                    <span class="lb-sm">Name</span>
                    <el-input v-model="network.name" />
                </div>
            </div>
            <div v-if="active == 1" class="container my-5">
                <input ref="fileInput" class="form-control-file" style="display: none" type="file" accept=".jpg,.jpeg,.png" @change="onFileSelected">
                <el-button v-if="active > 0" type="primary" @click="fileInput.click()">Choose File</el-button>
            </div>
            <div v-if="active == 1 && selectedFile" class="container text-center my-5">
                <img ref="imageView" class="rounded mx-auto d-block" style="height: 200px;">
            </div>
            <div v-if="active == 1" class="container text-center my-5">
                <p>{{ imageName }}</p>
            </div>
            <div v-if="active == 2" class="container my-3 ">
                <div class="row justify-content-md-center">
                    <div class="form-group col-md-2">
                        <span class="lb-sm">X (meter)</span>
                        <el-input-number v-model.number="network.x"/>
                    </div>
                    <div class="form-group col-md-2">
                        <span class="lb-sm">Y (meter)</span>
                        <el-input-number v-model.number="network.y"/>
                    </div>
                    <div class="form-group col-md-2">
                        <span class="lb-sm">Z (meter)</span>
                        <el-input-number v-model.number="network.z"/>
                    </div>
                </div>
            </div>
            <!-- <div v-if="active == 3" class="threejscontainer my-5">
                <div class="row my-3">
                    <div class="col col-md-3 my-auto mr-3">
                        <label for="my-auto">True North Offset</label>
                    </div>
                    <div class="col col-md-8">
                        <el-slider v-model="network.trueNorthOffset" :max="360" show-input/>
                    </div> 
                </div>
                <FloorPlanScene v-model:network="network" />
            </div> -->
            <!-- <div v-if="active == 4" class="container my-5">
                <el-radio-group v-model="declinationOptions">
                    <el-radio :label="0">Automatic</el-radio>
                    <el-radio :label="1">Latitude/Longitude</el-radio>
                    <el-radio :label="2">Manual</el-radio>
                </el-radio-group>
            </div>
            <div v-if="active == 4 && declinationOptions == 0" >
                <p>We calculate magnetic declination angle based on your current location.<br/> 
                    You need to allow the browser to access your current location.</p>
                <button type="button" class="btn btn-primary" @click="calculateDeclinationAuto">
                    <span v-show="loadingDeclination" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Calculate Declination
                </button>
                <h5 v-if="network.declination" class="mt-4"> {{ network.declination }} &deg;</h5>
            </div>
            <div v-if="active == 4 && declinationOptions == 1" class="container my-5">
                <p>We calculate magnetic declination angle based on the provided  Latitude and Longitude</p>
                <div class="row">
                    <div class="col col-md-3"></div>  
                    <div class="col col-md-3">
                        <span class="lb-sm">Latitude</span>
                        <input type="number" class="form-control" required v-model="latitude">
                    </div>
                    <div class="col col-md-3">
                        <span class="lb-sm">Longitude</span>
                        <input type="number" class="form-control" required v-model="longitude">
                    </div>
                    <div class="col col-md-3"></div> 
                </div>
                
                <button type="button" class="btn btn-primary mt-4" @click="calculateDeclinationSemi">
                    <span v-show="loadingDeclination" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Calculate Declination
                </button>
                <h5 v-if="network.declination" class="mt-4"> {{ network.declination }} &deg;</h5>
            </div> -->
            <!-- <div v-if="active == 4 && declinationOptions == 2" class="mb-3">
                <p>You need to provide the magnetic declination angle.<br/>  
                    You may use <a href="https://www.ngdc.noaa.gov/geomag/calculators/magcalc.shtml" target="_blank" class="link-info">National Centers for Environmental Information website</a></p>
                    <div class="row justify-content-md-center">
                        <div class="form-group col-md-3">
                            <span class="lb-sm">Magnetic Declination</span>
                            <input type="number" class="form-control" required v-model="network.declination">
                        </div>
                    </div>
            </div> -->
            <div class="container justify-content-center mt-5">
                <div class="btn-toolbar justify-content-center">
                    <el-button v-if="active > 0" type="primary" @click="onClickBack">Back</el-button>
                    <el-button v-if="active < 2" type="primary" @click="onClickNext">Next</el-button>
                    <el-button v-if="active == 2" type="primary" @click="onClickSave">Save</el-button>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer mt-auto py-4">
        <div style="font-size: 14px;">Copyright © 2024 IOSEA Inc. All rights reserved.</div>
        <div>
        <p style="font-size: 13px;">Version 2.4.3 {{ apiVersion }}</p>
        </div>
    </footer>
</template>

<script>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import useLogout from "../composables/useLogout";
import getUser from "../composables/getUser";
import Navbar from "../components/Navbar.vue";
import axios from "axios";
import { ElNotification } from 'element-plus';
// import { ref as sRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
// import { storage } from '../firebase/config'
import FloorPlanScene from "../components/FloorPlanScene.vue";
import { onerror } from 'q';

export default {
  props: [],
  components: { Navbar, FloorPlanScene },
  setup() {
    const showNavbarLinks = ref(true);
    const { user } = getUser();
    const { logout, logoutError } = useLogout();
    const router = useRouter();
    const protocol = ref(window.localStorage.getItem("protocol"));
    const ep = ref(window.localStorage.getItem("endpoint"));
    const apiVersion = ref(window.localStorage.getItem("apiversion"));
    const port = ref(window.localStorage.getItem("port"));
    const key = ref(window.localStorage.getItem("key"));
    const headers = { 'Authorization': 'Bearer ' + key.value };
    const network = ref({name: null, x: null, y: null, z: null, trueNorthOffset: 0, image: "", declination: null});
    const fileInput = ref(null);
    const selectedFile = ref(null);
    const active = ref(0);
    const imageView = ref(null);
    const imageName = ref("Upload floor plan or click next to skip");
    const loadingDeclination = ref(false);
    const declinationOptions = ref(0);
    const latitude = ref(null);
    const longitude = ref(null);
    const licenseExpiredVisible = ref(false);

    if (process.env.VUE_APP_API_PORT) {
        port.value = process.env.VUE_APP_API_PORT
    }

    const onClickBack = () => {
        if (active.value == 0) {
            router.push({ name: "Networks" })
        }
        if (active.value == 2) {
            loadImageView()
        }

        active.value--
    }

    const onClickNext = () => {
      if (active.value == 0 && !network.value.name) {
        onWarning("Please fill the name field");
        return;
      }

      if (active.value == 0) {
        loadImageView()
      }
    //   if (active.value == 2) {
    //       if (!network.value.x) {
    //           onWarning("Please fill the x field");
    //           return;
    //       }
    //       if (!network.value.y) {
    //           onWarning("Please fill the y fields");
    //           return;
    //       }
    //       if (!network.value.z) {
    //           onWarning("Please fill the z fields");
    //           return;
    //       }
    //   } 
        
      active.value++
    }

    const onClickSave = () => {
        if (active.value == 2) {
            if (!network.value.x) {
                onWarning("Please fill the x field");
                return;
            }
            if (!network.value.y) {
                onWarning("Please fill the y fields");
                return;
            }
            if (!network.value.z) {
                onWarning("Please fill the z fields");
                return;
            }
        } 
        
        if (network.value.name && network.value.name != " " && network.value.x && network.value.y && network.value.z) {
            var networkObj = {
                setup: {
                    name: network.value.name,
                    dimensions: [network.value.x, network.value.y, network.value.z],
                    image: network.value.image
                }
            }
            axios.post(protocol.value + ep.value + port.value + '/network', networkObj, { headers })
            .then((res) => {
            if (res.status == 200) {
                onSuccess('Network added')
                var id = res.data.setup.id
                router.push({ name: "Tracking", params: { id: id } })
            }
            })
            .catch((error) => {
                onError('Failed to add network')
                console.log(error)
                if (error.response.status == 444) {
                    licenseExpiredVisible.value = true
                }
            })
        }

        // if (network.value.declination) {
        //     // Upload Image to Firbase storage, once got the download url back, then save the network to backend
        //     const metaData = {
        //         contentType: selectedFile.value.type
        //     }
        //     const storageRef = sRef(storage, selectedFile.value.name);
        //     const uploadTask =  uploadBytesResumable(storageRef, selectedFile.value);
        //     uploadTask.on('state_changed', (snapshot) => {
        //         var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //         //   console.log("Upload " + progress + "%")
        //         },
        //         (error) => {
        //             console.log(error)
        //             onError('Failed to add network')
        //         },
        //         () => {
        //         getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        //             network.value.image = downloadURL
        //             // Save the network to backend
        //             if (network.value.name && network.value.name != " " && network.value.x && network.value.y && network.value.z) {
        //                 axios.post(protocol.value + ep.value + port.value + '/network', { name: network.value.name, dimensions: [network.value.x, network.value.y, network.value.z] }, { headers })
        //                 .then((res) => {
        //                 if (res.status == 200) {
        //                     onSuccess('Network added')
        //                     router.push({ name: "Tracking", params: { name: network.value.name } })
        //                 }
        //                 })
        //                 .catch((error) => {
        //                 onError('Failed to add network')
        //                 console.log(error)
        //                 if (error.response.status == 444) {
        //                      licenseExpiredVisible.value = true
        //                 }
        //                 })
        //             }
        //             else {
        //                 onWarning('Please fill in all the required fields')
        //             }
        //             router.push({ name: "Networks" })
        //         });
        //         }
        //     )
        // }
        // else {
        //     onWarning('You need to calculate the magnetic declination before saving')
        // }
    }
    
    const onFileSelected = (event) => {
      if (event.target.files[0].size > 2000000) {
         onError("The size limit for image is 2MB.")
       }
       else {
         selectedFile.value = event.target.files[0]
         loadImageView()
         imageName.value = selectedFile.value.name
       }
    }

    const loadImageView = () => {
        if (FileReader && selectedFile.value) {
        var fr = new FileReader();
        fr.onload = function () {
            imageView.value.src = fr.result;
            network.value.image = fr.result;
        }
        fr.readAsDataURL(selectedFile.value);
      }
    }

    // const calculateDeclinationAuto = () => {
    //     loadingDeclination.value = true
    //     navigator.geolocation.getCurrentPosition(
    //         position => {
    //             calculateDeclination(position.coords.latitude, position.coords.longitude)
    //         },
    //         error => {
    //             console.log(error.message);
    //             onError("User denied geolocation.")
    //             loadingDeclination.value = false
    //         },
    //     )
    // }

    // const calculateDeclinationSemi = () => {
    //     if (latitude.value && longitude.value) {
    //         loadingDeclination.value = true
    //         calculateDeclination(latitude.value, longitude.value)
    //     }
    //     else {
    //         onWarning('Please fill the latitude and longitude fields')
    //     }
    // }

    // const calculateDeclination = (lat, lng) => {
    //     const geomagnetism = require('geomagnetism');
    //     // information for "right now"
    //     const info = geomagnetism.model().point([lat, lng]);
    //     network.value.declination = info.decl
    //     loadingDeclination.value = false
    // }

    const onLicenseExpired = async () => {
        await logout();
    }

    const onSuccess = (description) => {
        ElNotification({
            title: 'Success',
            message: description,
            type: 'success',
            duration: 5000
        })
    }

    const onWarning = (description) => {
        ElNotification({
            title: 'Warning',
            message: description,
            type: 'warning',
            duration: 5000
        })
    }

    const onError = (description) => {
        ElNotification({
            title: 'Error',
            message: description,
            type: 'error',
            duration: 10000
        })
    }

    watch(user, () => {
        if (!user.value) {
            router.push({ name: "SignIn" })
        }
    });

    return { 
        showNavbarLinks, 
        network, 
        active, 
        onClickBack, 
        onClickNext,
        onClickSave, 
        fileInput, 
        onFileSelected,
        imageView, 
        imageName,
        selectedFile, 
        // calculateDeclinationAuto,
        // calculateDeclinationSemi, 
        loadingDeclination,
        declinationOptions,
        latitude,
        longitude,
        apiVersion,
        licenseExpiredVisible,
        onLicenseExpired
    }
  }
}
</script>

<style>
</style>