<template>
  <Navbar v-if="!isFullScreen" v-model:showNavbarLinks="showNavbarLinks"/>

  <!-- Modal Views - Start -->
  <el-dialog v-model="scannerVisible" title="Add New Locator" width="30%">
    <StreamBarcodeReader @decode="(a, b, c) => onDecode(a, b, c)" @loaded="onLoaded"></StreamBarcodeReader>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="scannerVisible = false">Close</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="locatorDialogVisible" title="Add New Locator" width="30%">
     <el-form label-position="left" label-width="120px" style="max-width: 460px">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-4 d-flex align-items-center justify-content-end" style="height: 50px; font-size: 14px">
          Serial Number
        </div>
        <div class="col-sm-6">
          <input required placeholder="Device Serial Number" v-model="tempLocator.serialNumber" style="height: 35px; font-size: 14px;width: 100%">
        </div>
        <div class="col-md-2 d-flex align-items-center" style="height: 50px; font-size: 14px;">
          <el-button type="primary" @click="scannerVisible = true; scannerType = 'serial'" :icon="Camera" style="border-radius: 20px;"></el-button>
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-4 d-flex align-items-center justify-content-end" style="height: 50px; font-size: 14px">
          MAC
        </div>
        <div class="col-sm-6">
          <input required placeholder="Device MAC" v-model="tempLocator.name" style="height: 35px; font-size: 14px;width: 100%">
        </div>
        <div class="col-md-2 d-flex align-items-center" style="height: 50px; font-size: 14px;">
          <el-button type="primary" @click="scannerVisible = true; scannerType = 'mac'" :icon="Camera" style="border-radius: 20px;"></el-button>
        </div>
      </div>
     </el-form>
     <template #footer>
       <span class="dialog-footer">
         <el-button @click="locatorDialogVisible = false">Cancel</el-button>
         <el-button type="primary" @click="onAddLocator">Add</el-button>
       </span>
     </template>
   </el-dialog>

  <el-dialog v-model="tagDialogVisible" title="Register New Tag" width="30%">
    <el-form
      label-position="left"
      label-width="120px"
      style="max-width: 460px"
    >
      <el-form-item label="Tag MAC">
        <el-input v-model="newTag.name" />
      </el-form-item>
      <el-form-item label="Label">
        <el-input v-model="newTag.label" />
      </el-form-item>
      <el-form-item label="Tag 3D Object">
        <!-- <el-select v-model="newTag.asset" class="m-2" placeholder="Select">
          <el-option
            v-for="item in tagAssets"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select> -->
        <el-tree-select v-model="newTag.asset" :data="tagAssets" :render-after-expand="false" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="tagDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onRegisterTag">Add</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="editTagDialogVisible" title="Edit Tag" width="30%">
    <el-form
      label-position="left"
      label-width="120px"
      style="max-width: 460px"
    >
      <el-form-item label="Tag MAC">
        {{ tagToEdit.name }}
      </el-form-item>
      <el-form-item label="Label">
        <el-input v-model="tagToEdit.label" />
      </el-form-item>
      <el-form-item label="Tag 3D Object">
        <el-tree-select v-model="tagToEdit.asset" :data="tagAssets" :render-after-expand="false" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editTagDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onEditTag">Update</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="deleteLocatorVisible" title="Warning" width="30%">
    <span>Are you sure you want to delete this locator?</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="deleteLocatorVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onDeleteLocator()"
          >Confirm</el-button
        >
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="deleteTagVisible" title="Warning" width="30%">
    <span>Are you sure you want to delete this Tag?</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="deleteTagVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onDeleteTag()"
          >Confirm</el-button
        >
      </span>
    </template>
  </el-dialog>

  <el-dialog v-model="licenseExpiredVisible" title="License Expired" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width="30%">
    <span>Your license has expired. Please contact IOSEA support team.</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="onLicenseExpired()">Ok</el-button
        >
      </span>
    </template>
  </el-dialog>

  <el-drawer v-model="locatorsDrawer" title="Locators" direction="rtl" size="25%">
    <el-table :data="locators" :row-class-name="locatorsTableRowClassName">
      <el-table-column label="Name">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ scope.row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="X" width="60">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ locationComponentToString(scope.row.location[0], false) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Y" width="60">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ locationComponentToString(scope.row.location[1], false) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Z" width="60">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ locationComponentToString(scope.row.location[2], false) }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </el-drawer>

  <el-drawer v-model="tagsDrawer" title="Tags" direction="rtl" size="40%">
    Tracking
    <el-table :data="liveTagsTracking" :row-class-name="trackingTableRowClassName">
      <el-table-column label="MAC" width="150">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ scope.row.beacon }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Label">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ getTagLabel(scope.row.beacon) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="X" width="60">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ Number(scope.row.x.toFixed(2)) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Y" width="60">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ Number(scope.row.y.toFixed(2)) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Z" width="60">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ Number(scope.row.z.toFixed(2)) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Stationary Confidence" width="100">
         <template #default="scope">
           <div style="display: flex; align-items: center">
             <span>{{ Number(scope.row.stationaryconfidence).toString() + "%" }}</span>
           </div>
         </template>
       </el-table-column>
      <el-table-column label="Timestamp">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ formatDate(scope.row.date) }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-divider />
    Temperature
     <el-table :data="liveTagsThermometer" :row-class-name="temperatureTableRowClassName">
      <el-table-column label="MAC" width="150">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ scope.row.beacon }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Label">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ getTagLabel(scope.row.beacon) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Value">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ scope.row.value }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-divider />
    Acceleration
    <el-table :data="liveTagsAccelerometer" :row-class-name="accelerationTableRowClassName">
      <el-table-column label="MAC" width="150">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ scope.row.beacon }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Label">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ getTagLabel(scope.row.beacon) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="X" width="60">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ scope.row.x }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Y" width="60">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ scope.row.y }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Z">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ scope.row.z }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </el-drawer>

  <el-drawer v-model="displayOptionsDrawer" title="Display Options" direction="rtl" size="25%">
    <div class="row">
      <div class="row justify-content-md-left col-md-12">
          <div class="form-group col-md-4 d-flex justify-content-left" style="font-size: small">
              Display Tags
          </div>
          <div class="form-group col-md-8 d-flex justify-content-left">
              <el-switch v-model="tagRadio" size="small" active-text="Registered" inactive-text="All" />
          </div>
      </div>
    </div>
    <div class="row">
        <div class="row justify-content-md-left col-md-12">
            <div class="form-group col-md-4 d-flex justify-content-left" style="font-size: small">
                Activate Ray
            </div>
            <div class="form-group col-md-8 d-flex justify-content-left">
                <el-switch v-model="isRayActivated" size="small" active-text="Activated" inactive-text="Deactivated" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="row justify-content-md-left col-md-12">
            <div class="form-group col-md-4 d-flex justify-content-left" style="font-size: small">
                Field of View
            </div>
            <div class="form-group col-md-8 d-flex justify-content-left">
                <el-switch v-model="showFOV" size="small" active-text="Show" inactive-text="Hide" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="row justify-content-md-left col-md-12">
            <div class="form-group col-md-4 d-flex justify-content-left" style="font-size: small">
                Uncertainty Radius
            </div>
            <div class="form-group col-md-8 d-flex justify-content-left">
                <el-switch v-model="showUncertaintyRadius" size="small" active-text="Show" inactive-text="Hide" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="row justify-content-md-left col-md-12">
            <div class="form-group col-md-4 d-flex justify-content-left" style="font-size: small">
                Grid
            </div>
            <div class="form-group col-md-8 d-flex justify-content-left">
                <el-switch v-model="displayGrid" size="small" active-text="On" inactive-text="Off" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="row justify-content-md-left col-md-12">
            <div class="form-group col-md-4 d-flex justify-content-left" style="font-size: small">
                Tag Label
            </div>
            <div class="form-group col-md-8 d-flex justify-content-left">
                <el-switch v-model="tagLabel" size="small" active-text="On" inactive-text="Off" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="row justify-content-md-left col-md-12">
            <div class="form-group col-md-4 d-flex justify-content-left" style="font-size: small">
                Last Known
            </div>
            <div class="form-group col-md-8 d-flex justify-content-left">
                <el-switch v-model="lastKnown" size="small" active-text="On" inactive-text="Off" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="row justify-content-md-left col-md-12">
            <div class="form-group col-md-4 d-flex justify-content-left" style="font-size: small">
                Console Logs
            </div>
            <div class="form-group col-md-8 d-flex justify-content-left">
                <el-switch v-model="consoleLogs" size="small" active-text="On" inactive-text="Off" />
            </div>
        </div>
    </div>
  </el-drawer>
  <!-- Modal Views - End -->

  <div v-if="networkPage != 'setup'" class="btn-toolbar" style="justify-content: center; display: flex;">
    <!-- <div style="font-size: 14px;">Copyright © 2024 IOSEA Inc. All rights reserved.</div> -->
    <div v-if="networkPage == 'details'" class="container text-right mb-3">
      <div class="row">
        <div class="col-md-6 text-left" style="font-size: 15px;">
          &emsp; Networks &emsp;>&emsp;<b>{{ network.name }}</b> &emsp;> <el-button type="primary" text @click="onClickTracking" :icon="Location">Tracking</el-button> 
          <span v-if="debuggingAccess">
            &emsp;-&emsp; <el-button type="primary" text @click="toggleDebugging" :icon="DataLine">Debugging</el-button>
          </span>
        </div>
        <div v-if="displaySettings" class="col-md-6">
          <el-button type="primary" text @click="toggleSettings" :icon="Tools"> Settings</el-button>
        </div>
      </div>
    </div>
    <div v-if="networkPage == 'settings'" class="container text-left mb-3">
      <el-button type="primary" text @click="toggleSettings" :icon="ArrowLeftBold"> Back</el-button>
    </div>
  </div>
  <div :style=" isFullScreen ? '' : 'min-height: 70vh;' ">
    <div v-if="networkPage == 'setup' && !isFullScreen" class="container">
      <div class="container text-left mb-3">
        <div class="row">
          <div class="col text-left">
            <el-button type="primary" text @click="onClickBack" :icon="ArrowLeftBold"> Back</el-button>
          </div>
          <div class="col text-center" style="font-size: 15px;">
            {{ network.name }} &emsp;>&emsp; <b>{{ locators[tempIndex].name }}</b>
          </div>
          <div class="col">
          </div>
        </div>
      </div>
    </div>
    <div v-if="networkPage == 'details'" class="container justify-content-center">
      <div class="btn-toolbar my-5 justify-content-center">
        <el-button type="primary" @click="locatorDialogVisible = true">Add Locator</el-button>
      </div>
      <div class="customContainer mb-5" v-if="locators.length">
        <table class="table table-sm">
          <thead style="background:#F2F2F2">
            <tr>
              <th scope="col">Serial Number</th>
              <th scope="col">Locator MAC</th>
              <th scope="col">Sensitivity</th>
              <th scope="col">X</th>
              <th scope="col">Y</th>
              <th scope="col">Z</th>
              <th scope="col">Roll<sub>x</sub></th>
              <th scope="col">Pitch<sub>y</sub></th>
              <th scope="col">Yaw<sub>z</sub></th>
              <th scope="col">Status</th>
              <!-- <th scope="col">Actions</th> -->
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(locator, index) in locators">
              <tr>
                <td class="align-middle">{{ locator.serialNumber }}</td>
                <td class="align-middle">{{ locator.name }}</td>
                <td class="align-middle">{{ locator.sensitivity }}</td>
                <td class="align-middle">{{ locationComponentToString(locator.location[0], true) }}</td>
                <td class="align-middle">{{ locationComponentToString(locator.location[1], true) }}</td>
                <td class="align-middle">{{ locationComponentToString(locator.location[2], true) }}</td>
                <td class="align-middle">{{ getRoll(locator.imu) }}</td>
                <td class="align-middle">{{ getPitch(locator.imu) }}</td>
                <td class="align-middle">{{ getYaw(locator.imu) }}</td>
                <td class="align-middle">
                  <h5><span class="badge badge-secondary" :class="{'badge-success': (locator.status === 'started'), 'badge-info': (locator.status === 'connected')}">{{ locator.status || 'disconnected' }}</span></h5>
                </td>
                <td>
                  <div class="btn-group" role="group">
                    <button id="btnGroupDrop1" type="button" class="btn btn-primary dropdown-toggle btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span v-show="loadingStatus && tempIndex == index" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Actions
                    </button>
                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                      <a class="dropdown-item" @click="onSetupLocator(index); tempIndex = index">Setup</a>
                      <a class="dropdown-item" @click="onSyncLocator(locator.name); tempIndex = index">Sync</a>
                      <!-- <a class="dropdown-item" @click="onConnectLocator(locator.name)">Connect</a>
                      <a class="dropdown-item" @click="onDisconnectLocator(locator.name)">Disconnect</a> -->
                      <a class="dropdown-item" @click="onStartLocator(locator.name); tempIndex = index">Start</a>
                      <a class="dropdown-item" @click="onStopLocator(locator.name); tempIndex = index">Stop</a>
                      <a class="dropdown-item" @click="onRebootLocator(locator.name); tempIndex = index">Reboot</a>
                      <a class="dropdown-item" @click="deleteLocatorVisible = true; locatorToDelete = locator.name">Delete</a>
                      <!-- <a class="dropdown-item" @click="onPingLocator(locator.name)">Ping</a> -->
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <hr class="mb-4" v-if="locators.length" style="width: 80%;"/>
      <el-button class="mt-4" type="primary" @click="tagDialogVisible = true">Register Tag</el-button>
      <div class="customContainer my-5" v-if="tags.length">
        <table class="table table-sm">
          <thead style="background:#F2F2F2">
            <tr>
              <th scope="col">Tag MAC</th>
              <th scope="col">Label</th>
              <th scope="col">3D Object</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(tag, index) in tags">
              <tr>
                <td class="align-middle">
                  {{ tag.name }}
                </td>
                <td class="align-middle">
                  {{ tag.label }}
                </td>
                <td class="align-middle">
                  {{ tagAssetsDict(tag.asset) }}
                </td>
                <td>
                  <el-button type="primary" @click="onClickEditTag(index)">Edit</el-button>
                  <el-button type="danger" @click="deleteTagVisible = true; tagToDelete = tag">Delete</el-button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="networkPage == 'setup'" style="width:100%;">
      <div v-if="!isFullScreen">
        <div class="container">
          <el-collapse accordion>
            <!-- <el-collapse-item title="Locator Endpoint" name="1">
              <div class="row">
                <div class="row justify-content-md-left col-md-11">
                  <div class="col col-md-4">
                    <input type="text" class="form-control" required v-model="tempLocator.ip" placeholder="127.0.0.1">
                  </div>
                </div>
                <div class="justify-content-center col-md-1">
                    <el-button type="primary" @click="onSaveLocatorIP">Save</el-button>
                </div>
              </div>
            </el-collapse-item> -->
            <el-collapse-item title="Locator Position" name="1">
              <div class="row">
                <div class="row justify-content-md-left col-md-11">
                  <div class="col col-md-2 my-auto mr-3">
                    <label class="my-auto">Anchor</label>
                  </div>
                  <div class="col col-md-2">
                    <span class="lb-sm">X (meter)</span>
                    <el-input-number v-model.number="anchor[0]"/>
                  </div>
                  <div class="col col-md-2">
                    <span class="lb-sm">Y (meter)</span>
                    <el-input-number v-model.number="anchor[1]"/>
                  </div>
                  <div class="col col-md-2">
                    <span class="lb-sm">Angle</span>
                    <el-input-number v-model.number="anchor[2]"/>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="row justify-content-md-left col-md-11">
                  <div class="col col-md-2 my-auto mr-3">
                    <label class="my-auto">{{ tempLocator.name }}</label>
                  </div>
                  <div class="col col-md-2">
                    <span class="lb-sm">X (meter)</span>
                    <el-input-number v-model.number="tempLocator.location[0]"/>
                  </div>
                  <div class="col col-md-2">
                    <span class="lb-sm">Y (meter)</span>
                    <el-input-number v-model.number="tempLocator.location[1]"/>
                  </div>
                  <div class="col col-md-2">
                    <span class="lb-sm">Z (meter)</span>
                    <el-input-number v-model.number="tempLocator.location[2]"/>
                  </div>
                </div>
                <div class="justify-content-center col-md-1">
                  <el-button type="primary" @click="onSaveLocatorPosition(tempLocator.name)">Save</el-button>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="Locator Orientation" name="2">
              <div class="row">
                <div class="col col-md-2 my-auto mr-3">
                  <label class="my-auto">Method:</label>
                </div>
                <div class="col col-md-6 text-left">
                  <el-radio-group v-model="tempLocator.automatic">
                    <el-radio :label="1" disabled>Automatic</el-radio>
                    <el-radio :label="2">Semi-Automatic</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div v-if="tempLocator.automatic == 1" class="row mt-5">
                <div class="justify-content-center col col-md-12 my-auto">
                    <span class="mr-3" style="font-size: 16px">This method acquires the locator's orientation automatically.</span> 
                </div>  
              </div>
              <div v-if="tempLocator.automatic == 1" class="row mt-3">
                <div class="justify-content-center col col-md-12 my-auto">
                  <button type="button" class="btn btn-primary btn-sm" @click="onAcquireLocatorOrientation">
                    <span v-show="loadingIMU" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Acquire and Save
                  </button>
                </div> 
              </div>
              <div v-if="tempLocator.automatic == 2"  class="row mt-3">
                <div class="col col-md-2 my-auto mr-3">
                  <label class="my-auto">Mount</label>
                </div>
                <div class="col col-md-8 text-left">
                  <el-radio-group v-model="imu.mount">
                    <el-radio :label="0">Ceiling Mount</el-radio>
                    <el-radio :label="-45">45 degree</el-radio>
                    <el-radio :label="-90">Wall Mount</el-radio>
                    <el-radio :label="-135" disabled>-45 degree</el-radio>
                    <el-radio :label="-180" disabled>Table Top</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div v-if="tempLocator.automatic == 2" class="row mt-3">
                <div class="col col-md-2 my-auto mr-3">
                  <label for="my-auto">Angle</label>
                </div>
                <div class="row justify-content-md-left col-md-7">
                  <el-slider v-model="imu.angle" :max="360" show-input/>
                </div>
                <div class="col col-md-2">
                </div>
                <div class="justify-content-center col-md-1 my-auto">
                  <el-button type="primary" @click="onSaveIMU">Save</el-button>
                </div> 
              </div>
            </el-collapse-item>
            <el-collapse-item title="Locator Sensitivity" name="3">
              <div class="row">
                <div class="row justify-content-md-left col-md-11">
                  <div class="form-group col-md-2">
                    <span class="lb-sm">RSSI (dBm)</span>
                    <el-input-number v-model.number="tempLocator.sensitivity" placeholder="-94"/>
                  </div>
                </div>
                <div class="justify-content-center col-md-1">
                  <br/>
                  <el-button type="primary" @click="onSaveLocatorSensitivity">Save</el-button>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="Locator Field of View" name="4">
              <div class="row">
                <!-- <div class="col col-md-2 my-auto mr-3">
                  <label class="my-auto">Method:</label>
                </div> -->
                <div class="col col-md-6 text-left">
                  <el-radio-group v-model="tempLocator.customfov">
                    <el-radio :label="0">Default</el-radio>
                    <el-radio :label="1">Customize</el-radio>
                  </el-radio-group>
                </div>
                <div class="col col-md-5">
                </div>
                <div v-if="tempLocator.customfov == 0" class="justify-content-center col-md-1 my-auto">
                  <el-button type="primary" @click="onSaveFieldOfView">Save</el-button>
                </div> 
              </div>
              <div v-if="tempLocator.customfov == 1" class="row mt-3">
                <div class="col col-md-2 my-auto mr-3">
                  <label for="my-auto">Theta Start</label>
                </div>
                <div class="row justify-content-md-left col-md-7">
                  <el-slider v-model="tempLocator.thetastartglobal" :max="180" show-input/>
                </div>
              </div>
              <div v-if="tempLocator.customfov == 1" class="row mt-3">
                <div class="col col-md-2 my-auto mr-3">
                  <label for="my-auto">Theta Stop</label>
                </div>
                <div class="row justify-content-md-left col-md-7">
                  <el-slider v-model="tempLocator.thetaendglobal" :max="180" show-input/>
                </div>
              </div>
              <div v-if="tempLocator.customfov == 1" class="row mt-3">
                <div class="col col-md-2 my-auto mr-3">
                  <label for="my-auto">Phi Start</label>
                </div>
                <div class="row justify-content-md-left col-md-7">
                  <el-slider v-model="tempLocator.phistartglobal" :max="360" show-input/>
                </div>
              </div>
              <div v-if="tempLocator.customfov == 1" class="row mt-3">
                <div class="col col-md-2 my-auto mr-3">
                  <label for="my-auto">Phi Stop</label>
                </div>
                <div class="row justify-content-md-left col-md-7">
                  <el-slider v-model="tempLocator.phiendglobal" :max="360" show-input/>
                </div>
              </div>
              <div v-if="tempLocator.customfov == 1" class="row mt-3">
                <div class="col col-md-2 my-auto mr-3">
                  <label for="my-auto">Rotate Slice</label>
                </div>
                <div class="row justify-content-md-left col-md-7">
                  <el-slider v-model="tempLocator.rotateslice" :max="360" show-input/>
                </div>
              </div>
              <div v-if="tempLocator.customfov == 1" class="row mt-3">
                <div class="col col-md-2 my-auto mr-3">
                  <label for="my-auto">Radius</label>
                </div>
                <div class="row justify-content-md-left col-md-7">
                  <el-slider v-model="tempLocator.radiusglobal" :step="0.1" :max="10" show-input/>
                </div>
                <div class="col col-md-2">
                </div>
                <div class="justify-content-center col-md-1 my-auto">
                  <el-button type="primary" @click="onSaveFieldOfView">Save</el-button>
                </div> 
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div :class=" isFullScreen ? '' : 'threejscontainer'">
        <ThreeScene ref="three"
        @onClickNetworkDetails="onClickNetworkDetails"
        v-model:networkPage="networkPage"
        v-model:startWatch="startWatch" 
        v-model:network="network" 
        v-model:locators="locators" 
        v-model:tags="tags" 
        v-model:anchor="anchor"
        v-model:readyToSetAnchor="readyToSetAnchor" 
        v-model:tempLocator="tempLocator"
        v-model:allowSetPosition="allowSetPosition" 
        v-model:isStarted="isStarted" 
        v-model:isFullScreen="isFullScreen"
        v-model:isRayActivated="isRayActivated"
        v-model:showFOV="showFOV"
        v-model:showUncertaintyRadius="showUncertaintyRadius"
        v-model:locatorsDrawer="locatorsDrawer"
        v-model:tagsDrawer="tagsDrawer"
        v-model:displayOptionsDrawer="displayOptionsDrawer"
        v-model:locatorIndexInDrawer="locatorIndexInDrawer"
        v-model:trackingIndexInDrawer="trackingIndexInDrawer"
        v-model:temperatureIndexInDrawer="temperatureIndexInDrawer"
        v-model:accelerationIndexInDrawer="accelerationIndexInDrawer"
        v-model:liveTagsTracking="liveTagsTracking"
        v-model:liveTagsThermometer="liveTagsThermometer"
        v-model:liveTagsAccelerometer="liveTagsAccelerometer"
        v-model:displayGrid="displayGrid"
        v-model:tagLabel="tagLabel"
        v-model:tagRadio="tagRadio"
        v-model:lastKnown="lastKnown"
        v-model:consoleLogs="consoleLogs"
        />
      </div>
    </div>
    <div v-if="networkPage == 'tracking'">
      <ThreeScene ref="three"
      @onClickNetworkDetails="onClickNetworkDetails"
      v-model:networkPage="networkPage"
      v-model:startWatch="startWatch" 
      v-model:network="network" 
      v-model:locators="locators" 
      v-model:tags="tags" 
      v-model:anchor="anchor"
      v-model:readyToSetAnchor="readyToSetAnchor" 
      v-model:tempLocator="tempLocator"
      v-model:allowSetPosition="allowSetPosition" 
      v-model:isStarted="isStarted" 
      v-model:isFullScreen="isFullScreen"
      v-model:isRayActivated="isRayActivated"
      v-model:showFOV="showFOV"
      v-model:showUncertaintyRadius="showUncertaintyRadius"
      v-model:locatorsDrawer="locatorsDrawer"
      v-model:tagsDrawer="tagsDrawer"
      v-model:displayOptionsDrawer="displayOptionsDrawer"
      v-model:locatorIndexInDrawer="locatorIndexInDrawer"
      v-model:trackingIndexInDrawer="trackingIndexInDrawer"
      v-model:temperatureIndexInDrawer="temperatureIndexInDrawer"
      v-model:accelerationIndexInDrawer="accelerationIndexInDrawer"
      v-model:liveTagsTracking="liveTagsTracking"
      v-model:liveTagsThermometer="liveTagsThermometer"
      v-model:liveTagsAccelerometer="liveTagsAccelerometer"
      v-model:displayGrid="displayGrid"
      v-model:tagLabel="tagLabel"
      v-model:tagRadio="tagRadio"
      v-model:lastKnown="lastKnown"
      v-model:consoleLogs="consoleLogs"
      />
    </div>
    <div v-if="networkPage == 'settings'">
      <Settings 
        :networkId="network.id"
        :aoaSettings="aoaSettings"
        :rtlsSettings="rtlsSettings" 
        :generalSettings="generalSettings"
        :clientStreamSettings="clientStreamSettings"
      />
    </div>
    <div v-if="networkPage == 'debugging'">
      <Debugging
        @toggleDebugging="toggleDebugging" 
        :network="network"
        :locators="locators"
        :tags="tags"
      />
    </div>
  </div>
  <footer v-if="!isFullScreen || networkPage == 'debugging'" class="footer mt-auto py-4">
    <div style="font-size: 14px;">Copyright © 2024 IOSEA Inc. All rights reserved.</div>
    <div>
      <p style="font-size: 13px;">Version 2.4.3 {{ apiVersion }}</p>
    </div>
  </footer>
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { projectAuth, projectFirestore } from '../firebase/config';
import getUser from "../composables/getUser";
import useLogout from "../composables/useLogout";
import Navbar from "../components/Navbar.vue";
import Settings from "../components/Settings.vue";
import ThreeScene from "../components/ThreeScene.vue";
import Debugging from "../components/Debugging.vue";
import axios from "axios";
import getNetworkDetails from '../composables/getNetworkDetails';
import useWindowResize from '../use/useWindowResize.js';
import { constants } from 'fs';
import { add, divide, multiply, complex, sqrt } from 'mathjs';
import { ElNotification } from 'element-plus';
import { ArrowLeftBold, Tools, Location, DataLine, Camera } from '@element-plus/icons-vue';
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  props: ['id'],
  components: { Navbar, Settings, ThreeScene, Debugging, StreamBarcodeReader },
  setup(props) {
    const networkId = ref(props.id)
    const showNavbarLinks = ref(true);
    const { width, height } = useWindowResize();
    const { user } = getUser();
    const { logout, logoutError } = useLogout();
    const userIdCache = ref(null);
    const router = useRouter();
    const protocol = ref(window.localStorage.getItem("protocol"));
    const ep = ref(window.localStorage.getItem("endpoint"));
    const clientstreamendpoint = ref(window.localStorage.getItem("clientstreamendpoint"));
    const gatewayendpoint = ref(window.localStorage.getItem("gatewayendpoint"));
    const apiVersion = ref(window.localStorage.getItem("apiversion"));
    const wsprotocol = ref(window.localStorage.getItem("wsprotocol"));
    const port = ref(window.localStorage.getItem("port"));
    const wsport = ref(window.localStorage.getItem("wsport"));
    const key = ref(window.localStorage.getItem("key"));
    const expiry = ref(window.localStorage.getItem("expiry"));
    const headers = { 'Authorization': 'Bearer ' + key.value };
    const licenseExpiredVisible = ref(false);

    if (process.env.VUE_APP_API_PORT) {
        port.value = process.env.VUE_APP_API_PORT
    }

    if (process.env.VUE_APP_WS_PORT) {
        wsport.value = process.env.VUE_APP_WS_PORT
    }

    const displaySettings = ref(window.localStorage.getItem("displaySettings") == 'true' ? true : false);
    const debuggingAccess = ref(window.localStorage.getItem("debuggingAccess") == 'true' ? true : false); 
    const settingsAccessibleItems = ref(window.localStorage.getItem("settingsAccessibleItems"));
    const companyId = ref(window.localStorage.getItem("companyId"));
    const isStarted = ref(false);
    const selectedLocator = ref(0);
    const newTag = ref({name: null, label: "", asset: "#0077B6"});
    const tempLocator = ref({ 
      name: null,
      serialNumber: null, 
      location: [], 
      anchor: [],  
      status: "disconnected",
      imu: [], 
      mount: null, 
      angle: null, 
      automatic: 0,
      customfov: 0,
      rotateslice: 0,
      thetastartglobal: 0,
      thetaendglobal: 180,
      phistartglobal: 0,
      phiendglobal: 360,
      radiusglobal: 1,
      sensitivity: -200
    });
    const anchor = ref([]);
    const imu = ref({mount: null, angle: 0});
    const networkPage = ref("details");
    const readyToSetAnchor = ref(false);
    const three = ref(null);
    const allowSetPosition = ref(false);
    const startWatch = ref(false);
    const loadingIMU = ref(false);
    const loadingStatus = ref(false);
    const tempIndex = ref(null);
    const isFullScreen = ref(false);
    const editWSCancelBtn = ref(null);
    const addLocatorCancelBtn = ref(null);
    const registerTagCancelBtn = ref(null);
    const deleteLocatorVisible = ref(false);
    const locatorToDelete = ref(null);
    const deleteTagVisible = ref(false);
    const tagToDelete = ref(null);
    const tagToEdit = ref(null);
    const isRayActivated = ref(false);
    const showFOV = ref(false);
    const showUncertaintyRadius = ref(true);
    const locatorsDrawer = ref(false);
    const tagsDrawer = ref(false);
    const displayOptionsDrawer = ref(false);
    const locatorIndexInDrawer = ref(null);
    const trackingIndexInDrawer = ref(null);
    const temperatureIndexInDrawer = ref(null);
    const accelerationIndexInDrawer = ref(null);
    const liveTagsTracking = ref([]);
    const liveTagsThermometer = ref([]);
    const liveTagsAccelerometer = ref([]);
    const displayGrid = ref(true);
    const tagLabel = ref(false);
    const lastKnown = ref(true);
    const consoleLogs = ref(false);
    const tagDialogVisible = ref(false);
    const editTagDialogVisible = ref(false);
    const locatorDialogVisible = ref(false);
    const tagRadio = ref('false');
    const scannerVisible = ref(false);
    const scannerType = ref("");
    let socket = ref(null);

    const tagAssets = [
      {
        value: '1',
        label: 'Sphere',
        children: [
          {
            value: '#FFBE0E',
            label: 'Yellow',
          },
          {
            value: '#F77F00',
            label: 'Orange',
          },
          {
            value: '#D62828',
            label: 'Red',
          },
          {
            value: '#7209B7',
            label: 'Purple',
          },
          {
            value: '#06D6A0',
            label: 'Green',
          },
          {
            value: '#0077B6',
            label: 'Blue',
          },
          {
            value: '#212529',
            label: 'Black',
          },
        ],
      },
      {
        value: '2',
        label: 'Other Objects',
        children: [
          {
            value: 'chainsaw',
            label: 'Chainsaw'
          },
          {
            value: 'pallet',
            label: 'Pallet'
          },
          {
            value: 'key',
            label: 'Key'
          },
          {
            value: 'construction_vehicle',
            label: 'Construction Vehicle'
          },
          {
            value: 'hard_hat',
            label: 'Hard Hat'
          },
          {
            value: 'safe_box',
            label: 'Safe Box'
          },
          {
            value: 'bicycle',
            label: 'Bicycle'
          },
          {
            value: 'chair',
            label: 'Chair'
          },
          {
            value: 'fire_extinguisher',
            label: 'Fire Extinguisher'
          },
          {
            value: 'industry_shelving',
            label: 'Industry Shelving'
          },
          {
            value: 'laptop',
            label: 'Laptop'
          },
          {
            value: 'nursing_bed',
            label: 'Nursing Bed'
          },
          {
            value: 'printer',
            label: 'Printer'
          },
          {
            value: 'syringe',
            label: 'Syringe'
          },
          {
            value: 'vacuum_cleaner',
            label: 'Vacuum Cleaner'
          },
          {
            value: 'wheel',
            label: 'Wheel'
          },
          {
            value: 'bulb',
            label: 'Bulb'
          },
          {
            value: 'human',
            label: 'Human'
          },
          {
            value: 'burger',
            label: 'Burger'
          },
          {
            value: 'knife',
            label: 'Knife'
          },
          {
            value: 'cat',
            label: 'Cat'
          }
        ],
      }
    ]

    const tagAssetsDict = (value) => {
      var name = ""
      if (value == "#FFBE0E") {
        name = "Yellow"
      }
      switch(value) {
        case "#FFBE0E":
          name = "Yellow Sphere"
          break;
        case "#F77F00":
          name = "Orange Sphere"
          break;
        case "#D62828":
          name = "Red Sphere"
          break;
        case "#7209B7":
          name = "Purple Sphere"
          break;
        case "#06D6A0":
          name = "Green Sphere"
          break;
        case "#0077B6":
          name = "Blue Sphere"
          break;
        case "#212529":
          name = "Black Sphere"
          break;
        case "chainsaw":
          name = "Chainsaw"
          break;
        case "pallet":
          name = "Pallet"
          break;
        case "key":
          name = "Key"
          break;
        case "construction_vehicle":
          name = "Construction Vehicle"
          break;
        case "hard_hat":
          name = "Hard Hat"
          break;
        case "safe_box":
          name = "Safe Box"
          break;
        case "bicycle":
          name = "Bicycle"
          break;
        case "chair":
          name = "Chair"
          break;
        case "fire_extinguisher":
          name = "Fire Extinguisher"
          break;
        case "industry_shelving":
          name = "Industry Shelving"
          break;
        case "laptop":
          name = "Laptop"
          break;
        case "nursing_bed":
          name = "Nursing Bed"
          break;
        case "printer":
          name = "Printer"
          break;
        case "syringe":
          name = "Syringe"
          break;
        case "vacuum_cleaner":
          name = "Vacuum Cleaner"
          break;
        case "wheel":
          name = "Wheel"
          break;
        case "bulb":
          name = "Bulb"
          break;
        case "human":
          name = "Human"
          break;
        case "burger":
          name = "Burger"
          break;
        case "knife":
          name = "Knife"
          break;
        case "cat":
          name = "Cat"
          break;
        default:
          name = ""
      }
      return name
    }

    const anchorAngleInRadians = computed(() => {
      var pi = Math.PI
      return (anchor.rotationAngle*(Math.PI/180))
    })

    const { 
      network, 
      locators, 
      tags,
      aoaSettings,
      rtlsSettings, 
      generalSettings,
      clientStreamSettings, 
      networkError, 
      loadNetworkDetails 
    } = getNetworkDetails(props.id)
    loadNetworkDetails()

    const locatorsTableRowClassName = ({
      row,
      rowIndex,
    }) => {
      if (rowIndex === locatorIndexInDrawer.value) {
        return 'primary-row'
      }
      return ''
    }
    
    const trackingTableRowClassName = ({
      row,
      rowIndex,
    }) => {
      if (rowIndex === trackingIndexInDrawer.value) {
        return 'primary-row'
      }
      return ''
    }
    const temperatureTableRowClassName = ({
      row,
      rowIndex,
    }) => {
      if (rowIndex === temperatureIndexInDrawer.value) {
        return 'primary-row'
      }
      return ''
    }
    const accelerationTableRowClassName = ({
      row,
      rowIndex,
    }) => {
      if (rowIndex === accelerationIndexInDrawer.value) {
        return 'primary-row'
      }
      return ''
    }

    const getTagLabel = (name) => {
      for (let i = 0; i < tags.value.length; i++) {
        if (tags.value[i].name == name) {
          return tags.value[i].label
        }
      }
    }

    const onClickNetworkDetails = () => {
      networkPage.value = "details"
      loadNetworkDetails()
      onStartMonitoring()
      readyToSetAnchor.value = false
      startWatch.value = false
    }

    const onClickTracking = () => {
      networkPage.value = "tracking"
      onStopMonitoring()
      readyToSetAnchor.value = false
      isFullScreen.value = true
    }

    const toggleDebugging = () => {
      if (networkPage.value == "details") {
        networkPage.value = "debugging"
        isFullScreen.value = true
        onStopMonitoring()
      }
      else {
        networkPage.value = "details"
        isFullScreen.value = false
        loadNetworkDetails()
        onStartMonitoring()
      }
    }

    const toggleSettings = () => {
      if (networkPage.value == "details") {
        let user = projectAuth.currentUser
        projectFirestore.collection('users').doc(user.uid).get()
        .then((doc) => {
            if (doc.exists && doc.data().company_id) {

              projectFirestore.collection("companies").doc(doc.data().company_id).get()
                .then((doc) => {
                  if (doc.data().api_ep && doc.data().key) {
                    displaySettings.value = false
                    var settingsFullAccess = doc.data().settings_full_access || false
                    if (settingsFullAccess) {
                      displaySettings.value = true
                    }

                    var generalSetting = false
                    var aoaSetting = false
                    var rtlsSetting = false
                    var clientStreamSetting = false

                    var settingsItems = doc.data().settings_accessible_items || []
                    console.log(settingsItems)
                    window.localStorage.setItem("settingsAccessibleItems", JSON.stringify(settingsItems))
                    for (var i=0; i<settingsItems.length; i++) {
                        if (settingsItems[i] == "general") {
                          displaySettings.value = true
                          generalSetting = true
                        }
                        if (settingsItems[i] == "aoa") {
                          displaySettings.value = true
                          aoaSetting = true
                        }
                        if (settingsItems[i] == "rtls") {
                          displaySettings.value = true
                          rtlsSetting = true
                        }
                        if (settingsItems[i] == "clientstream") {
                          displaySettings.value = true
                          clientStreamSetting = true
                        }
                    }
                    
                    window.localStorage.setItem('displaySettings', displaySettings.value)
                    window.localStorage.setItem('settingsFullAccess', settingsFullAccess)
                    window.localStorage.setItem('generalSetting', generalSetting)
                    window.localStorage.setItem('aoaSetting', aoaSetting)
                    window.localStorage.setItem('rtlsSetting', rtlsSetting)
                    window.localStorage.setItem('clientStreamSetting', clientStreamSetting)

                    // Go to the settings page and stop monitoring after fetch and saved settings details from Firestore
                    networkPage.value = "settings"
                    onStopMonitoring()
                  }
                  else {
                    onError("Couldn't fetch the settings")
                  }

                })
                .catch((error) => {
                  onError("Couldn't fetch the settings")
                  console.log("Error getting settings:", error);
                });
            } 
            else {
              onError("Couldn't fetch the settings")
            }
        })
        .catch((error) => {
          onError("Couldn't fetch the settings")
          console.log("Error getting settings:", error);
        });
      }
      else {
        networkPage.value = "details"
        loadNetworkDetails()
        onStartMonitoring()
      }
    }

    const onAddLocator = () => {
      if (tempLocator.value.name && tempLocator.value.serialNumber) {
        var locatorName = tempLocator.value.name.replace(/\s/g, "").toLowerCase();
        locatorDialogVisible.value = false

        axios.post(protocol.value + ep.value + port.value + '/locator-mac', { network: network.value.id, locator: locatorName, serialNumber: tempLocator.value.serialNumber }, { headers })
        .then((res) => {
          if (res.status == 200) {
            const index = locators.value.findIndex((locator, index) => {
              if (locator.name === locatorName) {
                  return true
                }
              })
              if (index == -1) {
                onSuccess('Locator added')
                locators.value.push({
                  name: locatorName,
                  serialNumber: tempLocator.value.serialNumber,
                  location:[], 
                  anchor:[], 
                  status: "disconnected",
                  imu: [], 
                  mount: null, 
                  angle: null, 
                  automatic: 0,
                  customfov: 0,
                  rotateslice: 0,
                  thetastartglobal: 0,
                  thetaendglobal: 180,
                  phistartglobal: 0,
                  phiendglobal: 360,
                  radiusglobal: 1,
                  sensitivity: -200
                })
                tempLocator.value = { 
                  name: null,
                  serialNumber: null, 
                  location: [], 
                  anchor: [], 
                  status: "disconnected",
                  imu: [], 
                  mount: null, 
                  angle: null, 
                  automatic: 0,
                  customfov: 0,
                  rotateslice: 0,
                  thetastartglobal: 0,
                  thetaendglobal: 180,
                  phistartglobal: 0,
                  phiendglobal: 360,
                  radiusglobal: 1,
                  sensitivity: -200
                }
                if (locators.value.length > 3) {
                  onWarning('Please contact IOSEA team in order to optimize the performance of multi locators setup')
                }
              }
              else {
                // locator exists
                onError('Locator already added')
              }
          }
        })
        .catch((error) => {
          onError('Failed to add the locator')
          console.log(error)
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
      }
      else {
        onWarning('Please fill the required fields')
      }
    }

    const onRegisterTag = () => {
      if (newTag.value.name) {
        var tagName = newTag.value.name.replace(/\s/g, "").toLowerCase();
        tagDialogVisible.value = false
        axios.post(protocol.value + ep.value + port.value + '/beacon', { network: network.value.id, name: tagName, label: newTag.value.label, asset: newTag.value.asset }, { headers })
        .then((res) => {
          if (res.status == 200) {
            onSuccess('Tag registered')
            // Save tag locally
            const index = tags.value.findIndex((tag, index) => {
              if (tag.name.replace(/\s/g, "").toLowerCase() === tagName.toLowerCase()) {
                  return true
                }
              })
            if (index == -1) {
              // tag doesn't exist in tags
              tags.value.push({name: tagName, label: newTag.value.label, asset: newTag.value.asset})
            }
            else {
              // tag exists
              onError('Tag already registered')
            }
          }
        })
        .catch((error) => {
          onError('Failed to register the tag')
          console.log(error)
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
      }
      else {
        onWarning('Please fill the tag MAC address')
      }
    }

    const onClickEditTag = (index) => {
      editTagDialogVisible.value = true
      tagToEdit.value = {
        name: tags.value[index].name, 
        label: tags.value[index].label, 
        asset: tags.value[index].asset
        }
    }

    const onEditTag = () => {
      editTagDialogVisible.value = false
      axios.post(protocol.value + ep.value + port.value + '/beacon', { network: network.value.id, name: tagToEdit.value.name, label: tagToEdit.value.label, asset: tagToEdit.value.asset }, { headers })
        .then((res) => {
          if (res.status == 200) {
            // Save tag locally
            const index = tags.value.findIndex((tag, index) => {
              if (tag.name === tagToEdit.value.name) {
                  return true
                }
              })
            if (index != -1) {
              onSuccess('Tag updated')
              tags.value[index] = {
                name: tagToEdit.value.name, 
                label: tagToEdit.value.label, 
                asset: tagToEdit.value.asset
                }
            }
          }
        })
        .catch((error) => {
          onError('Failed to update the tag')
          console.log(error)
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
    }

    const onDeleteTag = () => { 
      deleteTagVisible.value = false
      axios.delete(protocol.value + ep.value + port.value + '/beacon/' + network.value.id + '/' + tagToDelete.value.name, { headers })
        .then((res) => {
          if (res.status == 200) {
            onSuccess('Tag deleted')
            const index = tags.value.findIndex((tag, index) => {
              if (tag === tagToDelete.value) {
                  return true
                }
            })
            if (index != -1) {
              tags.value.splice(index, 1)
              tagToDelete.value = null
            }
          }
        })
        .catch((error) => {
          onError('Failed to delete the tag')
          console.error(error)
          tagToDelete.value = null
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
    }

    const onConnectLocator = (locatorName) => {
      axios.post(protocol.value + ep.value + port.value + '/locatorcommand', {locator: locatorName, command: "connect"}, { headers })
        .then((res) => {
          if (res.status == 200) {
            onSuccess('Locator connected')
            const index = locators.value.findIndex((locator, index) => {
              if (locator.name === locatorName) {
                  return true
                }
              })
            if (index != -1) {
              locators.value[index].status = "connected"
            }
          }
        })
        .catch((error) => {
          onError('Failed to connect the locator')
          console.log(error)
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
    }

    const onDisconnectLocator = (locatorName) => {
      axios.post(protocol.value + ep.value + port.value + '/locatorcommand', {locator: locatorName, command: "disconnect"}, { headers })
        .then((res) => {
          if (res.status == 200) {
            onSuccess('Locator disconnected')
            const index = locators.value.findIndex((locator, index) => {
              if (locator.name === locatorName) {
                  return true
                }
              })
            if (index != -1) {
              locators.value[index].status = "disconnected"
            }
          }
        })
        .catch((error) => {
          onError('Failed to disconnect the locator')
          console.log(error)
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
    }

    const onSyncLocator = (locatorName) => {
      loadingStatus.value = true
      axios.get(protocol.value + ep.value + port.value + '/status/' + locatorName, { headers })
        .then((res) => {
          if (res.status == 200) {
            loadingStatus.value = false
            onSuccess('Locator synchronized')
            const index = locators.value.findIndex((locator, index) => {
              if (locator.name === locatorName) {
                  return true
                }
              })
            if (index != -1) {
              locators.value[index].status = res.data.status
            }
          }
          else {
            loadingStatus.value = false
            onError('Failed to synchronize the locator')
          }
        })
        .catch((error) => {
            loadingStatus.value = false
            onError('Failed to synchronize the locator')
            console.log(error)
            if (error.response.status == 444) {
              licenseExpiredVisible.value = true
            }
          })
    }

    const onStartLocator = (locatorName) => {
      loadingStatus.value = true
      axios.post(protocol.value + ep.value + port.value + '/locatorcommand', {locator: locatorName, command: "start"}, { headers })
        .then((res) => {
          if (res.status == 200) {
            loadingStatus.value = false
          }
        })
        .catch((error) => {
          loadingStatus.value = false
          onError('Failed to start the locator')
          console.log(error)
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
    }

    const onStopLocator = (locatorName) => {
      loadingStatus.value = true
      axios.post(protocol.value + ep.value + port.value + '/locatorcommand', {locator: locatorName, command: "stop"}, { headers })
        .then((res) => {
          if (res.status == 200) {
            loadingStatus.value = false
          }
        })
        .catch((error) => {
          loadingStatus.value = false
          onError('Failed to stop the locator')
          console.log(error)
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
    }

    const onRebootLocator = (locatorName) => {
      axios.post(protocol.value + ep.value + port.value + '/locatorcommand', {locator: locatorName, command: "reboot"}, { headers })
        .then((res) => {
          if (res.status == 200) {
            onSuccess('Locator rebooted')
            const index = locators.value.findIndex((locator, index) => {
              if (locator.name === locatorName) {
                  return true
                }
              })
            if (index != -1) {
              locators.value[index].status = "disconnected"
            }
          }
        })
        .catch((error) => {
          onError('Failed to reboot the locator')
          console.log(error)
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
    }

    const onDeleteLocator = () => {
      deleteLocatorVisible.value = false
      axios.delete(protocol.value + ep.value + port.value + '/locator/' + locatorToDelete.value, { headers })
        .then((res) => {
          if (res.status == 200) {
            onSuccess('Locator deleted')
            const index = locators.value.findIndex((locator, index) => {
              if (locator.name === locatorToDelete.value) {
                  return true
                }
              })
            if (index != -1) {
              locators.value.splice(index, 1)
            }

            // Delete From Firestore locators list
            projectFirestore.collection('locators').doc(locatorToDelete.value).delete().then(() => {
                locatorToDelete.value = null
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
              locatorToDelete.value = null
            })
          }
        })
        .catch((error) => {
          onError('Failed to delete the locator')
          console.log(error)
          locatorToDelete.value = null
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
    }

    const onPingLocator = (locatorName) => {
      axios.get(protocol.value + ep.value + port.value + '/locatorping/', { headers })
        .then((res) => {
          if (res.status == 200) {
            onSuccess('Ping was received')
          }
        })
        .catch((error) => {
          onError('Ping was not received')
          console.log(error)
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
    }

    const onSetupLocator = (index) => {
      // Display Grid
      displayGrid.value = true

      selectedLocator.value = index

      if (locators.value[index].anchor.length) {
        anchor.value = [
          Number(locators.value[index].anchor[0].toFixed(2)), 
          Number(locators.value[index].anchor[1].toFixed(2)), 
          Number(locators.value[index].anchor[2].toFixed(2))
        ]
      }
      else {
        anchor.value = []
      }
        
      if (locators.value[index].location.length) {
        const { localX, localY} = calculateLocalPosition(locators.value[index].location[0], locators.value[index].location[1])
        tempLocator.value = { 
          name: locators.value[index].name, 
          location: [localX, localY, locators.value[index].location[2]],
          anchor: (locators.value[index].anchor || []),
          sensitivity: (locators.value[index].sensitivity || null),
          imu: (locators.value[index].imu || []),
          mount: (locators.value[index].mount || null), 
          angle: (locators.value[index].angle || 0),
          automatic: (locators.value[index].automatic || 0),
          customfov: (locators.value[index].customfov || 0),
          rotateslice: (locators.value[index].rotateslice || 0),
          thetastartglobal: (locators.value[index].thetastartglobal || 0),
          thetaendglobal: (locators.value[index].thetaendglobal || 180),
          phistartglobal: (locators.value[index].phistartglobal || 0),
          phiendglobal: (locators.value[index].phiendglobal || 360),
          radiusglobal: (locators.value[index].radiusglobal || 1)
        }
      }
      else {
        tempLocator.value = { 
          name: locators.value[index].name, 
          location: (locators.value[index].location || []),
          anchor: (locators.value[index].anchor || []),
          sensitivity: (locators.value[index].sensitivity || null),
          imu: (locators.value[index].imu || []),
          mount: (locators.value[index].mount || null), 
          angle: (locators.value[index].angle || 0),
          automatic: (locators.value[index].automatic || 0),
          customfov: (locators.value[index].customfov || 0),
          rotateslice: (locators.value[index].rotateslice || 0),
          thetastartglobal: (locators.value[index].thetastartglobal || 0),
          thetaendglobal: (locators.value[index].thetaendglobal || 180),
          phistartglobal: (locators.value[index].phistartglobal || 0),
          phiendglobal: (locators.value[index].phiendglobal || 360),
          radiusglobal: (locators.value[index].radiusglobal || 1)
        }
      }

      imu.value.mount = locators.value[index].mount
      imu.value.angle = (locators.value[index].angle || 0)
      
      networkPage.value = "setup"
      readyToSetAnchor.value = true
    }

    const onStartTracking = () => {
      three.value.onStartTracking()
    }

    const onStopTracking = () => {
      three.value.onStopTracking()
    }

    const calculateLocalPosition = (globalX, globalY) => {
      var pi = Math.PI
      var radians = (anchor.value[2] || 0)*(pi/180)
      var localX = Number((Math.cos(radians)*(globalX-(anchor.value[0] || 0))+Math.sin(radians)*(globalY-(anchor.value[1] || 0))).toFixed(2))
      var localY = Number((-Math.sin(radians)*(globalX-(anchor.value[0] || 0))+Math.cos(radians)*(globalY-(anchor.value[1] || 0))).toFixed(2))

      return { localX, localY}
    }

    const calculateGlobalPosition = () => {
      var pi = Math.PI
      var radians = (anchor.value[2] || 0)*(pi/180)
      var globalX = Number(((anchor.value[0] || 0)+(Math.cos(radians)*(tempLocator.value.location[0] || 0)-Math.sin(radians)*(tempLocator.value.location[1] || 0))).toFixed(2))
      var globalY = Number(((anchor.value[1] || 0)+(Math.sin(radians)*(tempLocator.value.location[0] || 0)+Math.cos(radians)*(tempLocator.value.location[1] || 0))).toFixed(2))
      var z = tempLocator.value.location[2] || 0

      return { globalX, globalY, z }
    } 
    
    const onSaveLocatorPosition = (locatorName) => {
      if ((tempLocator.value.location[0] || tempLocator.value.location[0] === 0) && 
       (tempLocator.value.location[1] || tempLocator.value.location[1] === 0) && 
       (tempLocator.value.location[2] || tempLocator.value.location[2] === 0) && 
       (anchor.value[0] || anchor.value[0] === 0) && 
       (anchor.value[1] || anchor.value[1] === 0) && 
       (anchor.value[2] || anchor.value[2] === 0)) {
        const { globalX, globalY, z } = calculateGlobalPosition()
        const currentStatus = locators.value[selectedLocator.value].status
        
        axios.post(protocol.value + ep.value + port.value + '/locatorlocation', {locator: tempLocator.value.name, location: [globalX, globalY, z], anchor: [anchor.value[0], anchor.value[1], anchor.value[2]]}, { headers })
            .then((res) => {
              if (res.status == 200) {
                onSuccess('Locator position saved')
                const index = locators.value.findIndex((locator, index) => {
                  if (locator.name === locatorName) {
                      return true
                    }
                  })
                if (index != -1) {
                  // locator exists
                  locators.value[index].location = [globalX, globalY, z]
                  locators.value[index].anchor = [anchor.value[0], anchor.value[1], anchor.value[2]]
                }
              }
            })
            .catch((error) => {
              onError('Failed to save the locator position')
              console.log(error)
              if (error.response.status == 444) {
                licenseExpiredVisible.value = true
              }
            })
       }
       else {
         onWarning('Please fill all the fields')
       }
    }

    const onSaveLocatorSensitivity = () => {
      if (tempLocator.value.sensitivity || tempLocator.value.sensitivity == 0) {
        axios.post(protocol.value + ep.value + port.value + '/locatorsensitivity', {locator: tempLocator.value.name, sensitivity: tempLocator.value.sensitivity}, { headers })
        .then((res) => {
          if (res.status == 200) {
            onSuccess('Locator Sensitivity Saved')
            const index = locators.value.findIndex((locator, index) => {
                if (locator.name === tempLocator.value.name) {
                    return true
                  }
                })
              if (index != -1) {
                locators.value[index].sensitivity = tempLocator.value.sensitivity
              }
          }
        })
        .catch((error) => {
          onError('Failed to save the locator Sensitivity')
          console.log(error)
          if (error.response.status == 444) {
            licenseExpiredVisible.value = true
          }
        })
      }
      else {
        onWarning('Please fill the RSSI')
      }
    }

    const onSaveFieldOfView = () => {
      if ((tempLocator.value.thetastartglobal || tempLocator.value.thetastartglobal === 0) && 
      (tempLocator.value.thetaendglobal || tempLocator.value.thetaendglobal === 0) && 
      (tempLocator.value.phistartglobal || tempLocator.value.phistartglobal === 0) && 
      (tempLocator.value.phiendglobal || tempLocator.value.phiendglobal === 0) && 
      (tempLocator.value.radiusglobal || tempLocator.value.radiusglobal === 0) && 
      (tempLocator.value.rotateslice || tempLocator.value.rotateslice === 0)) {

        axios.post(
          protocol.value + ep.value + port.value + '/locatorfov', 
          {
            locator: tempLocator.value.name,
            customfov: tempLocator.value.customfov,
            rotateslice: tempLocator.value.rotateslice,
            thetastartglobal: tempLocator.value.thetastartglobal, 
            thetaendglobal: tempLocator.value.thetaendglobal,
            phistartglobal: tempLocator.value.phistartglobal,
            phiendglobal: tempLocator.value.phiendglobal,
            radiusglobal: tempLocator.value.radiusglobal
          }, 
          { headers } 
        )
            .then((res) => {
              if (res.status == 200) {
                onSuccess('Locator field of view saved')
                const index = locators.value.findIndex((locator, index) => {
                  if (locator.name === tempLocator.value.name) {
                    return true
                  }
                })
                if (index != -1) {
                  // locator exists
                  locators.value[index].customfov = tempLocator.value.customfov
                  locators.value[index].rotateslice = tempLocator.value.rotateslice
                  locators.value[index].thetastartglobal = tempLocator.value.thetastartglobal
                  locators.value[index].thetaendglobal = tempLocator.value.thetaendglobal
                  locators.value[index].phistartglobal = tempLocator.value.phistartglobal
                  locators.value[index].phiendglobal = tempLocator.value.phiendglobal
                  locators.value[index].radiusglobal = tempLocator.value.radiusglobal
                }
              }
            })
            .catch((error) => {
              onError('Failed to save the locator field of view')
              console.log(error)
              if (error.response.status == 444) {
                licenseExpiredVisible.value = true
              }
            })
      }
      else {
        onWarning('Please fill all the fields')
      }
    }

    const onSaveIMU = () => {
      if (imu.value.mount || imu.value.mount === 0) {
        var result = CalculateSemiAutomaticOrientation()
        three.value.setIMU(result[9], result[10], result[11], result[12])
        axios.post(protocol.value + ep.value + port.value + '/locatorimu', {locator: tempLocator.value.name, imu: result, mount: imu.value.mount, angle: Number(imu.value.angle), automatic: (tempLocator.value.automatic || 0)}, { headers })
          .then((res) => {
            if (res.status == 200) {
              onSuccess('IMU Saved')
              const index = locators.value.findIndex((locator, index) => {
                  if (locator.name === tempLocator.value.name) {
                      return true
                    }
                })
              if (index != -1) {
                locators.value[index].imu = result
                locators.value[index].mount = imu.value.mount
                locators.value[index].angle = Number(imu.value.angle)
                locators.value[index].automatic = 2
                // Update tempLocator to fix new added locator missing fov when changing other fields 
                tempLocator.value.imu = result
                tempLocator.value.mount = imu.value.mount
                tempLocator.value.angle = Number(imu.value.angle)
                tempLocator.value.automatic = 2
              }
            }
          })
          .catch((error) => {
            onError('Failed to save the IMU')
            console.log(error)
            if (error.response.status == 444) {
              licenseExpiredVisible.value = true
            }
          })
      }
      else {
        onWarning('Please select a mount option')
      }
    }

    const onAcquireLocatorOrientation = () => {
      loadingIMU.value = true
      axios.post(protocol.value + ep.value + port.value + '/locatorcommand', {locator: tempLocator.value.name, command: "imu"}, { headers })
        .then((res) => {
          if (res.status == 200) {
            // Wait for 5 second and then fetch the locator imu
            var startTimer = setInterval(() => {
              axios.get(protocol.value + ep.value + port.value + '/locatorimu/' + tempLocator.value.name, { headers })
              .then((res) => {
                if (res.status == 200, res.data.imu) {
                  loadingIMU.value = false
                  var result = res.data.imu
                  if (result.length == 16) {
                    three.value.setIMU(result[9], result[10], result[11], result[12])
                  }
                  const index = locators.value.findIndex((locator, index) => {
                      if (locator.name === tempLocator.value.name) {
                          return true
                        }
                    })
                  if (index != -1) {
                    locators.value[index].imu = result
                    locators.value[index].automatic = 1
                  }
                }
                else {
                  loadingIMU.value = false
                  onError('Failed to receive the IMU')
                }
              })
              .catch((error) => {
                loadingIMU.value = false
                onError('Failed to receive the IMU')
                console.log(error)
                if (error.response.status == 444) {
                  licenseExpiredVisible.value = true
                }
              })
              // Removev timer
              clearInterval(startTimer)
            }, 5000)
          }
        })
        .catch((error) => {
          onError('Failed to receive the IMU')
          console.log(error)
        })
    }

    const onClickBack = () => {
      startWatch.value = false
      networkPage.value = "details"
      tempLocator.value = { 
        name: null,
        serialNumber: null,
        location: [], 
        anchor: [], 
        status: "disconnected",
        imu: [], 
        mount: null, 
        angle: null, 
        automatic: 0,
        customfov: 0,
        rotateslice: 0,
        thetastartglobal: 0,
        thetaendglobal: 180,
        phistartglobal: 0,
        phiendglobal: 360,
        radiusglobal: 1,
        sensitivity: -200
      }
    }

    const toggleLocatorOptions = (value) => {
      allowSetPosition.value = value
    }

    const CalculateSemiAutomaticOrientation = () => {
      var beta = 0
      var pi = Math.PI
      if (imu.value.mount == -45) {
        beta = -45*pi/180
      }
      else if (imu.value.mount == -90) {
        beta = -89.999*pi/180
      }
      else if (imu.value.mount == -135) {
        beta = -135*pi/180
      }
      else if (imu.value.mount == -180) {
        beta = -179.999*pi/180
      }

      var alpha = imu.value.angle*pi/180
      var r_y_Beta = [[Math.cos(beta), 0, Math.sin(beta)], [0, 1, 0], [-Math.sin(beta), 0, Math.cos(beta)]]
      var r_z_Alpha = [[Math.cos(alpha), -Math.sin(alpha), 0], [Math.sin(alpha), Math.cos(alpha), 0], [0, 0, 1]]

      var rotationMatrix = matrixMultiply(r_z_Alpha, r_y_Beta)
      

      var Rxx = complex(rotationMatrix[0][0], 0); 
      var Rxy = complex(rotationMatrix[0][1], 0); 
      var Rxz = complex(rotationMatrix[0][2], 0);
      var Ryx = complex(rotationMatrix[1][0], 0); 
      var Ryy = complex(rotationMatrix[1][1], 0); 
      var Ryz = complex(rotationMatrix[1][2], 0);
      var Rzx = complex(rotationMatrix[2][0], 0); 
      var Rzy = complex(rotationMatrix[2][1], 0); 
      var Rzz = complex(rotationMatrix[2][2], 0);
      var w = complex(0,0)
      var x = complex(0,0)
      var y = complex(0,0)
      var z = complex(0,0)
      w = multiply(sqrt(add(Rxx, Ryy, Rzz, 1)), 0.5);
      
      x = multiply(sqrt(add(Rxx, -Ryy, -Rzz, 1)), 0.5);
      y = multiply(sqrt(add(Ryy, -Rxx, -Rzz, 1)), 0.5); 
      z = multiply(sqrt(add(Rzz, -Rxx, -Ryy, 1)), 0.5);
      
      if( w.im > 0 ) {
        w = complex(0, 0)
      }

      var array = [w,x,y,z]

      var max = maxFinder(array)
      var index = max[1]
      if( index == 0 ) {
        x = divide((add(Rzy, -Ryz)), multiply(w, 4)); 
        y = divide((add(Rxz, -Rzx)), multiply(w, 4));
        z = divide((add(Ryx, -Rxy)), multiply(w, 4));
      }
      
      if( index == 1 ) {
        w = divide((add(Rzy, -Ryz)), multiply(x, 4));
        y = divide((add(Rxy, Ryx)), multiply(x, 4));
        z = divide((add(Rzx, Rxz)), multiply(x, 4));
      }
        
      if( index == 2 ) {
        w = divide((add(Rxz, -Rzx)), multiply(y, 4));
        x = divide((add(Rxy, Ryx)), multiply(y, 4));
        z = divide((add(Ryz, Rzy)), multiply(y, 4));
      }
          
      if( index == 3 ) {
        w = divide((add(Ryx, -Rxy)), multiply(z, 4));
        x = divide((add(Rzx, Rxz)), multiply(z, 4));
        y = divide((add(Ryz, Rzy)), multiply(z, 4));
      }
      
      var quatW = w.re
      var quatX = x.re
      var quatY = y.re
      var quatZ = z.re

      var t0 = 2.0 * (quatW * quatX + quatY * quatZ);
      var t1 = 1.0 - 2.0 * (quatX * quatX + quatY * quatY);
      var roll_x = Number((wrapTo2Pi(Math.atan2(t0, t1)) * 180/pi).toFixed(1));
      var t2 = 2.0 * (quatW * quatY - quatZ * quatX);

      if (t2 > 1.0) {
        t2 = 1;
      }

      if (t2 < -1.0) {
        t2 = -1.0;
      } 
      
      var pitch_y = Number((wrapTo2Pi(Math.asin(t2)) * 180/pi).toFixed(1));
      var t3 = 2.0 * (quatW * quatZ + quatX * quatY);
      var t4 = 1.0 - 2.0 * (quatY * quatY + quatZ * quatZ);
      var yaw_z = Number((wrapTo2Pi(Math.atan2(t3, t4)) * 180/pi).toFixed(1));
      
      var finalResult = [ rotationMatrix[0][0], rotationMatrix[0][1], rotationMatrix[0][2], rotationMatrix[1][0], rotationMatrix[1][1], rotationMatrix[1][2], rotationMatrix[2][0], rotationMatrix[2][1], rotationMatrix[2][2], quatX, quatY, quatZ, quatW, roll_x, pitch_y, yaw_z ];
      
      return finalResult
    }

    function wrapTo2Pi(value) {
      var pi = Math.PI
      var result = (value % (2*pi))
      if (result < 0) {
        result = result + 2*pi
      }
      return result
    }

    function matrixMultiply(a, b) {
      var aNumRows = a.length, aNumCols = a[0].length,
          bNumRows = b.length, bNumCols = b[0].length,
          m = new Array(aNumRows);  // initialize array of rows
      for (var r = 0; r < aNumRows; ++r) {
        m[r] = new Array(bNumCols); // initialize the current row
        for (var c = 0; c < bNumCols; ++c) {
          m[r][c] = 0;             // initialize the current cell
          for (var i = 0; i < aNumCols; ++i) {
            m[r][c] += a[r][i] * b[i][c]
          }
        }
      }
      return m
    }

    const maxFinder = (array) => {
      var max = array[0].abs()
      var index = 0 
      for (var i = 0; i < array.length; ++i) {
        if (array[i].abs() > max) {
          max = array[i].abs()
          index = i
        }
      }
      return [max, index]
    }

    const locationComponentToString = (component, m) => {
      if (component) {
        var result = String(component.toFixed(2))
        if (m) {
          result = result + "m"
        }
        return result
      }
      return ""
    }

    const getRoll = (imu) => {
      if (imu) {
        if (imu.length == 16) {
          return String(Number(imu[13].toFixed(1))) + String.fromCharCode(176)
        }
      }
      return ""
    }

    const getPitch = (imu) => {
      if (imu) {
        if (imu.length == 16) {
          return String(Number(imu[14].toFixed(1))) + String.fromCharCode(176)
        }
      }
      
      return ""
    }

    const getYaw = (imu) => {
      if (imu) {
        if (imu.length == 16) {
          return String(Number(imu[15].toFixed(1))) + String.fromCharCode(176)
        }
      }
      return ""
    }

    const OnChangeIMU = () => {
      var result = CalculateSemiAutomaticOrientation()
      three.value.setIMU(result[9], result[10], result[11], result[12])
      three.value.setFOV()
      if (tempLocator.value.customfov == 0) {
        three.value.setFOVQuaternion(result[9], result[10], result[11], result[12])  
      }
    }

    const formatDate = (date) => {
      let formattedDate =
                toString( date.getFullYear(), 4 )
        + '_' + toString( date.getMonth() + 1, 2 )
        + '_' + toString( date.getDate(), 2 )
        + ' ' + toString( date.getHours(), 2 )
        + ':' + toString( date.getMinutes(), 2 )
        + ':' + toString( date.getSeconds(), 2 )
        + '.' + toString( date.getMilliseconds(), 3 )

        return formattedDate
    }

    const toString = (number, padLength) => {
        return number.toString().padStart(padLength, '0');
    }

    const onStartMonitoring = () => {
      userIdCache.value = user.value.uid
      socket = new WebSocket(wsprotocol.value + clientstreamendpoint.value + wsport.value  + "/monitoring?token=" + key.value);
      socket.onmessage = event => {

        if (event.data instanceof Blob) {
          const reader = new FileReader();

          reader.onload = () => {
            // console.log("Status updated: " + reader.result);
            var receivedData = JSON.parse(reader.result);

            const index = locators.value.findIndex((locator, index) => {
            if (locator.name === receivedData.locator) {
                return true
              }
            })
            if (index != -1) {
              locators.value[index].status = receivedData.status
            }
          };

          reader.readAsText(event.data);
        } 
        else {
          if (event.data.includes("expired")) {
            licenseExpiredVisible.value = true
          }
          // console.log("event.data is not an instance of Blob!");
        }
      };

      socket.onopen = function(event) {
        console.log(event);
        console.log("Successfully connected to the websocket server...");
        socket.send("startmonitoring-" + networkId.value + "-" + user.value.uid + "-" + companyId.value);
      };

      socket.onclose = function(event) {
        console.log(event);
        console.log("websocket is closed...");
      };

      socket.onerror = function(event) {
        console.log(event);
        console.log("websocket error");
      };
    }

    const onStopMonitoring = () => {
      socket.send("stopmonitoring-" + networkId.value + "-" + userIdCache.value + "-" + companyId.value);
      socket.close();
      socket = null; // prevent memory leak
    }

    const onDecode = (a, b, c) => {
      if (scannerType.value == "mac") {
        tempLocator.value.name = a.toLowerCase()
      }
      if (scannerType.value == "serial") {
        tempLocator.value.serialNumber = a.toLowerCase()
      } 
      // Close scanner modal view
      scannerVisible.value = false
    };

    const onLoaded = () => {
      // console.log("ready to scan");
    };

    const onLicenseExpired = async () => {
      await logout();
    }

    const onSuccess = (description) => {
      ElNotification({
        title: 'Success',
        message: description,
        type: 'success',
        duration: 5000
      })
    }

    const onWarning = (description) => {
      ElNotification({
        title: 'Warning',
        message: description,
        type: 'warning',
        duration: 5000
      })
    }

    const onError = (description) => {
      ElNotification({
        title: 'Error',
        message: description,
        type: 'error',
        duration: 10000
      })
    }

    watch(user, () => {
      if (!user.value) {
        router.push({ name: "SignIn" });
      }
    });

    watch(imu.value, () => {
      if (startWatch.value) {
        OnChangeIMU()
      }
    });

    watch(networkError, (currentError) => {
      if (currentError == 444) {
        licenseExpiredVisible.value = true
      }
    });

    onMounted(() => {
      onStartMonitoring()

      // Add user to the backend for websocket channels
      axios.post(protocol.value + ep.value + port.value + '/user', { id: user.value.uid }, { headers })
        .then((res) => {
        if (res.status == 200) {
            // onSuccess('User added')
        }
        })
        .catch((error) => {
            onError('Failed to add user. Please logout and login again')
            console.log(error)
            if (error.response.status == 444) {
              licenseExpiredVisible.value = true
            }
        })
    })

    onUnmounted(() => {
      if (networkPage.value == "details") {
        onStopMonitoring()
      }
    });

    return {
      showNavbarLinks, 
      height,
      width,
      onStartTracking, 
      onStopTracking,
      onSaveLocatorPosition,
      isStarted, 
      onConnectLocator,
      onDisconnectLocator,
      onSyncLocator,
      onStartLocator, 
      onStopLocator, 
      onRebootLocator, 
      onDeleteLocator,
      onPingLocator, 
      onSetupLocator, 
      selectedLocator, 
      onAddLocator,
      anchor,
      imu, 
      locators, 
      tags,
      network, 
      onClickNetworkDetails,
      toggleSettings,
      onClickTracking,
      toggleDebugging,
      networkPage,
      anchorAngleInRadians, 
      tempLocator, 
      onRegisterTag, 
      onDeleteTag,
      onEditTag,
      onClickEditTag, 
      newTag,
      aoaSettings,
      rtlsSettings,
      generalSettings,
      clientStreamSettings,
      three,
      readyToSetAnchor,
      onSaveIMU,
      onAcquireLocatorOrientation,
      toggleLocatorOptions,
      allowSetPosition,
      onClickBack,
      getRoll,
      getPitch,
      getYaw,
      locationComponentToString,
      // onSaveLocatorIP,
      onSaveLocatorSensitivity,
      OnChangeIMU,
      startWatch,
      loadingIMU,
      loadingStatus,
      tempIndex,
      isFullScreen,
      editWSCancelBtn,
      addLocatorCancelBtn,
      registerTagCancelBtn,
      deleteLocatorVisible,
      locatorToDelete,
      deleteTagVisible,
      tagToDelete,
      tagToEdit,
      displaySettings,
      debuggingAccess,
      isRayActivated,
      showFOV,
      showUncertaintyRadius,
      locatorsDrawer,
      tagsDrawer,
      displayOptionsDrawer,
      locatorsTableRowClassName,
      trackingTableRowClassName,
      temperatureTableRowClassName,
      accelerationTableRowClassName,
      locatorIndexInDrawer,
      trackingIndexInDrawer,
      temperatureIndexInDrawer,
      accelerationIndexInDrawer,
      liveTagsTracking,
      liveTagsThermometer,
      liveTagsAccelerometer,
      formatDate,
      displayGrid,
      tagLabel,
      lastKnown,
      consoleLogs,
      apiVersion,
      locatorDialogVisible,
      tagDialogVisible,
      editTagDialogVisible,
      tagRadio,
      onSaveFieldOfView,
      ArrowLeftBold,
      Tools,
      Location,
      DataLine,
      Camera,
      tagAssets,
      tagAssetsDict,
      getTagLabel,
      scannerVisible,
      scannerType,
      onDecode,
      onLoaded,
      licenseExpiredVisible,
      onLicenseExpired
    }
 }
}
</script>

<style>

.el-table .primary-row {
  --el-table-tr-bg-color: var(--el-color-primary-light-9);
}

</style>