import { ref } from 'vue';
import axios from "axios";
import { ElNotification } from 'element-plus';

const getNetworkDetails = (networkId) => {
    const network = ref({id: null, dimensions: [], image: null})
    const locators = ref([])
    const tags = ref([])
    const aoaSettings = ref({
        network: networkId,
        deltahorizon: null, 
        enablehorizonfilter: null, 
        corecount: null,
        enableaggregator: null,
        fovmode: null,
        panormalize: null,
        miniallowablefomnorm: null,
        miniallowablefomnonnorm: null,
        activatefomnorm: null,
        debugmode: null
    })
    const rtlsSettings = ref({
        network: networkId, 
        normalizeweightinstance: null, 
        floordetection: null, 
        height: null, 
        consolidate: null, 
        fomexponent: null, 
        crosssection: null,
        rssiexponent: null,
        qualityweightlow: null,
        qualityweightmid: null,
        qualityweighthigh: null,
        stationarydetection: null,
        angularthreshold: null,
        gearzerothreshold: null,
        gearonethreshold: null,
        gearzerocount: null,
        gearonecount: null,
        geartwocount: null,
        noiseaxm: null,
        noiseaym: null,
        noiseazm: null,
        noiseaxzerogear: null,
        noiseayzerogear: null,
        noiseazzerogear: null,
        noiseaxonegear: null,
        noiseayonegear: null,
        noiseazonegear: null,
        noiseaxtwogear: null,
        noiseaytwogear: null,
        noiseaztwogear: null,
        modertls: null,
        activateholdon: null,
        maximumallowabledistance3d: null,
        maximumallowableradius3d: null,
        maximumallowableradius2d: null,
        continues3dmode: null,
        zmin: null,
        zmax: null,
        zstep: null,
        minimumallowablefom2d: null,
        minimumallowablefom3d: null,
        weightedaveradius: null,
        maxcoverage: null,
        minimumallowablefommodezero: null,
        processnoise: null,
        initialpvelocity: null,
        initialpposition: null,
        modekalman: null,
        initialacceleration: null,
        isradiusactivated: null,
        maxradius: null,
        nelement: null,
        minimumpointshighxyzmode0ratio: null,
        epsilonhighxyzmode0: null,
        minimumpointshighxyzfinalratio: null,
        epsilonhighxyzfinal: null,
        locationqueuemaxsize: null,
        maxtimeinterval: null,
        maxallowablemode2meter: null,
        maxsteepanglelocal: null,
        maxsteepangleglobal: null,
        timewindow: null,
        minimumminimumpointshighxyzfinalratio: null,
        enableweight2d: null,
        analytics: null,
        timehistorywindow: null,
        displayray: null,
        debugmode: null,
        velocitythreshold: null,
        counterholdonlockingthreshold: null,
        counterholdonunlockingthreshold: null
    })
    const generalSettings = ref({
        network: networkId,
        fir2count: null
    })
    const clientStreamSettings = ref({
        network: networkId,
        streaminterval: null,
        streamintervalall: null
    })
    const networkError = ref(null)
    const protocol = ref(window.localStorage.getItem("protocol"));
    const ep = ref(window.localStorage.getItem("endpoint"));
    const port = ref(window.localStorage.getItem("port"));
    const key = ref(window.localStorage.getItem("key"));
    const headers = { 'Authorization': 'Bearer ' + key.value };

    if (process.env.VUE_APP_API_PORT) {
        port.value = process.env.VUE_APP_API_PORT
    }

    const loadNetworkDetails = async () => {
        try {
            const res = await axios.get(protocol.value + ep.value + port.value + "/network/" + networkId, { headers })
            
            var data = res.data
            network.value = data.setup
            locators.value = data.locators
            tags.value = data.beacons
            aoaSettings.value = data.aoasettings
            rtlsSettings.value = data.rtlssettings
            generalSettings.value = data.generalsettings
            clientStreamSettings.value = data.clientstreamsettings
            console.log(data)
        }
        catch (err) {
            ElNotification({
                title: 'Error',
                message: 'Failed to load the network details',
                type: 'error',
                duration: 10000
            })
            networkError.value = err.response.status
            console.log(networkError.value)
        }
    }

    return { 
        network, 
        locators, 
        tags,
        aoaSettings,
        rtlsSettings, 
        generalSettings,
        clientStreamSettings, 
        networkError, 
        loadNetworkDetails 
    }
}

export default getNetworkDetails