<template>
  <Navbar v-model:showNavbarLinks="showNavbarLinks"/>
  <div class="container">
      <!-- <button class="button" @click="startRedisPubsub()">Start Tracking</button>
    <span v-text="location"></span>
    <button class="button" @click="settimer">Change Position</button> -->

    <div ref="mapDiv" style="width:100%; height:76vh" />

    <!-- <h2>{{ location.beacon }}</h2> -->
  </div>
  <div class="container" style="container-fluid; min-height: 78vh;">
    <button class="button" @click="fetchDataFromBigQuery()">Fetch Data</button>
    <el-table :data="locations" >
      <el-table-column label="Name">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ scope.row.beacon }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="X">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ Number(scope.row.x.toFixed(2)) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Y">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ Number(scope.row.y.toFixed(2)) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Z">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ Number(scope.row.z.toFixed(2)) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Timestamp">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <span>{{ scope.row.timestamp }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <div v-if="loading">loading...</div>
    <div v-else>
      <p>Status: {{ status }}</p>
      <div v-for="location in locations" :key="location.timestamp">
          <h4>{{ location.beacon }} - {{ location.timestamp }} - {{ location.x }} - {{ location.y }} - {{ location.z }}</h4>
      </div>
    </div> -->
  </div>
  <footer class="footer mt-auto py-4">
    <div style="font-size: 14px;">Copyright © 2024 IOSEA Inc. All rights reserved.</div>
    <div>
      <p style="font-size: 13px;">Version 2.4.3 {{ apiVersion }}</p>
    </div>
  </footer>
</template>

<script>
/* eslint-disable no-undef */
import { Loader } from "@googlemaps/js-api-loader";
import { computed, ref, onMounted, watch, inject } from "vue";
import Navbar from "../components/Navbar.vue";
import getUser from "../composables/getUser";
import { useRouter } from "vue-router";
import { useAxios } from "@vue-composable/axios";

const GOOGLE_MAPS_API_KEY = "AIzaSyAxoMzVqmEQr-ZLNO1j-IXX8yKIxYES_PQ";
var markers = [];

export default {
  components: { Navbar },
  setup() {
    const showNavbarLinks = ref(true)
    const { user } = getUser();
    const router = useRouter();
    const { data, loading, exec, error, status } = useAxios();
    const locations = ref([]);
    const location = ref({
      beacon: "arash",
      timestamp: 123456,
      x: 1.1,
      y: 1.2,
      z: 1.3
    });
    const showResults = ref(false);
    const fetchIsDone = ref(true);
    // Map
    const loader = new Loader({ apiKey: GOOGLE_MAPS_API_KEY })
    const mapDiv = ref(null)
    let map = ref(null)
    let imageMapType = ref(null)
    const lat = ref(33.636803)//ref(37.783)
    const lng = ref(-117.929328)//ref(-122.404)
    const apiVersion = ref(window.localStorage.getItem("apiversion"))

    watch(user, () => {
      if (!user.value) {
        router.push({ name: "SignIn" });
      }
    });

    const startRedisPubsub = () => {
      showResults.value = true;

      console.log(connection);
      connection.send("tracking");
    };

    const fetchDataFromBigQuery = () => {
      console.log("Fetch data from Biq Query!!")
      exec({
        method: "GET",
        url: "http://127.0.0.1:8081/bigquery"
      }).then(res => (locations.value = res["data"]));
    //   exec({
    //     method: "POST",
    //     url: "http://localhost:8081/locatorsPosition",
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     data: {data:[
    //      {beacon: "locator1", x: 3, y: 2, z: 0, checked: true}, 
    //      {beacon: "locator2", x: 4.5, y: 7, z: 0, checked: true}, 
    //      {beacon: "locator3", x: 8.2, y: 1, z: 0, checked: false}
    // ]}
    //   })
    };

    const changeMarkerLocation = () => {
      console.log(lat.value)
      lat.value = lat.value + 0.0001
      var latlng = new google.maps.LatLng(lat.value, lng.value)
      // markers[0].marker.setPosition(latlng)
      markers['tag1'].setPosition(latlng)
    }

    const settimer = () => {
      console.log("started!!")
      setInterval(changeMarkerLocation, 100)
    }

    onMounted(async () => {
      await loader.load()
      map.value = new google.maps.Map(mapDiv.value, {
        center: { lat: lat.value, lng: lng.value },
        zoom: 19,
        mapTypeControl: false,
        streetViewControl: false
      })

      imageMapType.value = new google.maps.ImageMapType({
        getTileUrl: function(coord, zoom) {
            var mapBounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(33.636803, -117.929328),
                new google.maps.LatLng(33.636976, -117.929209)
            );
            var mapMinZoom = 17;
            var mapMaxZoom = 20; 
            var proj = map.value.getProjection();
            var z2 = Math.pow(2, zoom);
            var tileXSize = 256 / z2;
            var tileYSize = 256 / z2;
            var tileBounds = new google.maps.LatLngBounds(
                proj.fromPointToLatLng(new google.maps.Point(coord.x * tileXSize, (coord.y + 1) * tileYSize)),
                proj.fromPointToLatLng(new google.maps.Point((coord.x + 1) * tileXSize, coord.y * tileYSize))
            );
            var x = coord.x >= 0 ? coord.x : z2 + coord.x
            var y = coord.y;
            if (mapBounds.intersects(tileBounds) && (mapMinZoom <= zoom) && (zoom <= mapMaxZoom))
                return zoom + "/" + x + "/" + y + ".png";
                
            else
                return "https://www.maptiler.com/img/none.png";
        },
        tileSize: new google.maps.Size(256, 256),
        isPng: true,
        name: "Rendered with MapTiler Desktop <https://www.maptiler.com/desktop/>",
        alt: "Rendered with MapTiler Desktop",
        opacity: 1.0
    });
      map.value.overlayMapTypes.push(imageMapType.value);
      
    
    // Ground Overlay
        // const imageBounds = {
        //     north: 40.773941,
        //     south: 40.712216,
        //     east: -74.12544,
        //     west: -74.22655,
        // };
        // let groundOverlay;
        // const imageBounds = new google.maps.LatLngBounds(
        //     new google.maps.LatLng(33.63679848057446, -117.92931820486334), 
        //     new google.maps.LatLng(33.63697098853932, -117.9292068931919)
        // );
        
        // groundOverlay = new google.maps.GroundOverlay(
        //     // "https://storage.googleapis.com/geo-devrel-public-buckets/newark_nj_1922-661x516.jpeg",
        //     "/shop.png",
        //     imageBounds
        // );
        // groundOverlay.setMap(map.value);

      // const marker = new google.maps.Marker({
      //   position: { lat: lat.value, lng: lng.value },
      //   map: map.value,
      //   title: "Moose Lagha!",
      //   // This is useful in cases where a large number of markers is required:
      //   optimized: true 
      // });
      // marker.setIcon('http://maps.google.com/mapfiles/ms/icons/green-dot.png') // green-dot.png - pink-dot.png - yellow-dot.png - purple-dot.png
      // markers['tag1'] = marker

      // markers.push( {name: "test", marker: marker} )
    })

    return {
      showNavbarLinks,
      location,
      locations,
      startRedisPubsub,
      fetchDataFromBigQuery,
      data,
      loading,
      status,
      mapDiv,
      settimer, 
      changeMarkerLocation,
      apiVersion
    };
  }
};
</script>

<style>
</style>
